import FilterBlock from '../../components/FilterBlock'
import { useParams } from 'react-router'
import { Table } from 'antd'
import './styles.scss'
import { userApi } from '../../services'
import { IUserClicks, IUserClicksRedux } from '../../models/IUsers'
import { TableConfig, useTableSearchSortPagination } from '../../hooks/useTableSearchSortPagination'
import { MODALS, TableOrder } from '../../utils/constants'
import { useContext,  useState } from 'react'
import { ModalContext } from '../../components/Modal/modalProvider'
import useEmitterFilterSubmit from '../../hooks/useEmitterFilterSubmit'
import useEmitterClearForFilter from '../../hooks/useEmitterClearForFilter'
import { columns } from './columns'

const UserClicks: React.FC = () => {
  const { id } = useParams()
  const [tableConfig, setTableConfig] = useState<TableConfig>({
    page: 1,
    limit: 10,
    order: TableOrder.descend,       
  })
  
  const {handleSearchChange,onChangeTable}=useTableSearchSortPagination<IUserClicks>({setTableConfig,nameSearchQuery:'searchClick'})  
  const { data, isLoading } = userApi.useGetUserClicksQuery({id,tableConfig})

  useEmitterFilterSubmit(setTableConfig)
  useEmitterClearForFilter(setTableConfig)

  const { onOpen } = useContext(ModalContext)
  const onOpenModal = () =>onOpen(MODALS.userFilterClicks)

  const onShowSizeChange=(current:number, size:number)=>{    
    setTableConfig((pre)=>({...pre,
    page:current,
    limit:size
    }))
  }

  return (
    <main className="user-clicks">
      <FilterBlock onChange={handleSearchChange} onOpenModal={onOpenModal} placeholder='Search clicks'/>
      <Table
        className="user-clicks-table"
        loading={isLoading}
        columns={columns}
        dataSource={(data as IUserClicksRedux)?.items}        
        onChange={onChangeTable}
        pagination={{ 
          position: ['bottomCenter'],
          total: data?.count,
          current:tableConfig.page,
          onShowSizeChange,
       }}
      />
    </main>
  )
}

export default UserClicks
