import { IStoreCashback } from '../../models';
import { IUserCashBack } from '../../models/IUsers'
import getValidateObj from '../../utils/getValidateObj'
import CashBackCard from './CashBackCard'
import './styles.scss'

interface IProps<T>{
  cashBack:T
  firstElement:null|React.ReactNode  
}

type ICashBackCard = IStoreCashback|IUserCashBack

const CashBackCardContainer =<T extends ICashBackCard > ({ cashBack, ...props }:IProps<T>) => {
  const validCashBack = getValidateObj(cashBack)

  const propsCashBackCard = {
    cashBack: validCashBack,
    ...props,
  }
  return <CashBackCard {...propsCashBackCard} />
}

export default CashBackCardContainer
