import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { Reducer } from 'redux'

import {
  AUTH_API_REDUCER_KEY,
  authApi,
  BLOG_API_REDUCER_KEY,
  blogApi,
  CARRIER_API_REDUCER_KEY,
  carrierApi,
  carrierFixedValuesApi,
  CARRIERS_API_REDUCER_KEY,
  carriersApi,
  CASHBACK_API_REDUCER_KEY,
  cashbackApi,
  CLICKS_API_REDUCER_KEY,
  clicksApi,
  CONTENT_PAGE_API,
  contentPageApi,
  FILTER_API_REDUCER_KEY,
  filterApi,
  STORE_API_REDUCER_KEY,
  storeApi,
  STORES_API_REDUCER_KEY,
  storesApi,
  ticketApi,
  TICKETS_API_REDUCER_KEY,
  TOP_UPS_API_REDUCER_KEY,
  USER_API_REDUCER_KEY,
  userApi,
  USERS_API_REDUCER_KEY,
  usersApi,
  WITHDRAWAL_API_REDUCER_KEY,
  withdrawalApi,
} from '../services'
import { authReducer, authSlice, IAuthState } from './reducers'
import { rtkQueryErrorLogger } from './Middleware/rtkQueryErrorLogger'
import { RESET_STATE_ACTION_TYPE } from './actions/resetState'
import { throttle } from 'lodash'
import carriersSlice from './reducers/carriersSlice'

const reducers = {
  [authSlice.name]: authReducer,
  carriers: carriersSlice,
  [AUTH_API_REDUCER_KEY]: authApi.reducer,
  [STORES_API_REDUCER_KEY]: storesApi.reducer,
  [STORE_API_REDUCER_KEY]: storeApi.reducer,
  [USERS_API_REDUCER_KEY]: usersApi.reducer,
  [USER_API_REDUCER_KEY]: userApi.reducer,
  [FILTER_API_REDUCER_KEY]: filterApi.reducer,
  [CASHBACK_API_REDUCER_KEY]: cashbackApi.reducer,
  [WITHDRAWAL_API_REDUCER_KEY]: withdrawalApi.reducer,
  [CLICKS_API_REDUCER_KEY]: clicksApi.reducer,
  [TICKETS_API_REDUCER_KEY]: ticketApi.reducer,
  [BLOG_API_REDUCER_KEY]: blogApi.reducer,
  [CONTENT_PAGE_API]: contentPageApi.reducer,
  [CARRIERS_API_REDUCER_KEY]: carriersApi.reducer,
  [CARRIER_API_REDUCER_KEY]: carrierApi.reducer,
  [TOP_UPS_API_REDUCER_KEY]: carrierFixedValuesApi.reducer,
}

const combinedReducer = combineReducers<typeof reducers>(reducers)

export const rootReducer: Reducer<RootState> = (state, action) => {
  if (action.type === RESET_STATE_ACTION_TYPE) {
    localStorage.removeItem('auth')
    state = {} as RootState
  }

  return combinedReducer(state, action)
}

export const loadState = () => {
  try {
    const savedState = localStorage.getItem('auth')

    if (savedState === null) {
      return undefined
    }

    return JSON.parse(savedState)
  } catch (error) {
    return undefined
  }
}

export const saveState = (state: { auth: IAuthState }) => {
  try {
    const stateToBeSaved = JSON.stringify(state)

    localStorage.setItem('auth', stateToBeSaved)
  } catch (error) {
    console.error(error)
  }
}

export const setupStore = () => {
  const persistedState = loadState()

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(
        storesApi.middleware,
        storeApi.middleware,
        authApi.middleware,
        cashbackApi.middleware,
        withdrawalApi.middleware,
        clicksApi.middleware,
        ticketApi.middleware,
        userApi.middleware,
        usersApi.middleware,
        blogApi.middleware,
        contentPageApi.middleware,
        carriersApi.middleware,
        carrierApi.middleware,
        carrierFixedValuesApi.middleware,
        rtkQueryErrorLogger
      ),
    preloadedState: persistedState,
  })

  store.subscribe(
    throttle(() => {
      saveState({
        auth: store.getState().auth,
      })
    }, 1000)
  )
  return store
}
/* eslint-disable-next-line */
// @ts-ignore
export type AppDispatch = typeof setupStore.dispatch
export type RootState = ReturnType<typeof combinedReducer>

export default setupStore
