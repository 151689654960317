import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import ModalContextProvider from './components/Modal/modalProvider'
import Modal from './components/Modal'

import App from './containers/App/App'
import setupStore from './redux'

import 'antd/dist/antd.min.css'

const Application: React.FC = () => {
  return (
    <Provider store={setupStore()}>
      <div id="app" className="main-theme">
        <BrowserRouter>
          <ModalContextProvider>
            <App />
            <Modal />
          </ModalContextProvider>
        </BrowserRouter>
      </div>
    </Provider>
  )
}

export default Application
