/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import { Button, Form, FormInstance, Tabs } from 'antd'
import { isEmpty, omit } from 'lodash'
import React, { createRef, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { CreatCarrierError, IForm } from '../../../models/ICarrier'
import { carrierApi, carrierFixedValuesApi, carriersApi } from '../../../services'
import { ShowMessage } from '../../../utils'
import { LANGUAGES } from '../../../utils/constants'
import Emitter, { EmitterEvents } from '../../../utils/eventEmitter'
import CarrierForm from '../CarrierForm'
import CarrierUpload from '../CarrierUpload'
import SVG from '../../../assets/images/SVG'
import type { RcFile, UploadProps } from 'antd/es/upload'
import { UploadChangeParam } from 'antd/lib/upload'
import type { UploadFile } from 'antd/es/upload/interface'
import getBase64 from '../../../utils/getBase64'
import { ELayoutVariants } from '../../../utils/enums'
import { useTypedDispatch, useTypedSelector } from '../../../hooks/hooks'
import { addListTopUpPins, resetCarrier } from '../../../redux/reducers'
import CarrierFixedValueListContainer from '../CarrierFixedValueList'

const { TabPane } = Tabs

const CarrierFormContainer: React.FC = () => {
  const [form] = Form.useForm()
  const specialFormRef = createRef<FormInstance>()
  const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useTypedDispatch()
  const {
    data,
    isLoading: fetchCarrierIsLoading,
    isError,
    refetch: refetchPublishedTopUpsList,
  } = carriersApi.useFetchCarrierQuery(id, { skip: !id })
  const { carrier, topUpsList: fixedValues } = useTypedSelector((state) => state.carriers)
  const {
    data: topUpsList,
    isLoading: isLoadingTopUpList,
    refetch: refetchTopUpsList,
  } = carrierFixedValuesApi.useFetchAllTopUpsByCarrierTitleQuery(carrier.title || data?.title, {
    skip: id ? !data?.title : !(carrier.title || data?.title),
  })

  const [
    updateCarrier,
    { isSuccess: isCU, error: updateError, isLoading: updateCarrierIsLoading },
  ] = carriersApi.useUpdateCarrierMutation()
  const [
    createCarrier,
    { isSuccess: isCC, error: createdError, isLoading: createCarrierIsLoading },
  ] = carriersApi.useCreateCarrierMutation()
  const { data: bookmarks, refetch: refetchBookmarks } = carrierApi.useGetBookmarksQuery()
  const [deleteCarrier, { isSuccess: isSuccessDeleteCarrier }] =
    carriersApi.useDeleteCarrierMutation()

  const [activeType, setActiveType] = useState<'en' | 'fr'>('en')

  // @ts-ignore
  const logoPreview =
    activeType === 'en' ? data?.tr?.en?.logoImage?.url : data?.tr?.fr?.logoImage?.url
  const bgPreview =
    // @ts-ignore
    activeType === 'en' ? data?.tr?.en?.backgroundImage?.url : data?.tr?.fr?.backgroundImage?.url
  const bannerPreview =
    // @ts-ignore
    activeType === 'en' ? data?.tr?.en?.searchLogoImage?.url : data?.tr?.fr?.searchLogoImage?.url

  const [preview, setPreview] = useState({ en: '', fr: '' })
  const [images, setImages] = useState({
    en: {
      logoImage: {},
      bannerImage: {},
      backgroundImage: {},
    },
    fr: {
      logoImage: {},
      bannerImage: {},
      backgroundImage: {},
    },
  })
  useEffect(() => {
    refetchBookmarks()
  }, [])

  useEffect(() => {
    if (!topUpsList && !(carrier.title || data?.title)) {
      refetchTopUpsList()
    }
    if (data?.fixedValues) {
      dispatch(addListTopUpPins(data.fixedValues))
    }
  }, [data, carrier])

  useEffect(() => {
    dispatch(resetCarrier())
  }, [location])

  useEffect(() => {
    setImages({
      en: {
        logoImage: { url: logoPreview },
        bannerImage: { url: bannerPreview },
        backgroundImage: { url: bgPreview },
      },
      fr: {
        logoImage: { url: logoPreview },
        bannerImage: { url: bannerPreview },
        backgroundImage: { url: bgPreview },
      },
    })
  }, [data])

  useEffect(() => {
    isError && navigate(`/${ELayoutVariants.TOP_UP_PIN}`)
  }, [isError])

  useEffect(() => {
    Emitter.on(EmitterEvents.CARRIER_REMOTE_SUBMIT, () => {
      form.submit()
    })

    return () => {
      Emitter.off(EmitterEvents.CARRIER_REMOTE_SUBMIT)
    }
  }, [form])

  const publishedObjFromBooleanToString = {
    true: 'Published',
    false: 'Unpublished',
  }

  useEffect(() => {
    if (isEmpty(data)) return
    const changedData = {
      ...data,
      /* eslint-disable-next-line */
      // @ts-ignore
      isPublished: publishedObjFromBooleanToString[String(data?.isPublished)],
    }
    form.setFieldsValue(changedData)
  }, [data, form])

  useEffect(() => {
    if (!isCC && !isCU) return
    setPreview({ en: '', fr: '' })
    ShowMessage('success', 'Success')
    navigate(`/${ELayoutVariants.TOP_UP_PIN}`)
  }, [isCC, isCU, navigate])

  useEffect(() => {
    if (!isSuccessDeleteCarrier) return
    ShowMessage('success', 'Carrier deleted')
    navigate(`/${ELayoutVariants.TOP_UP_PIN}`)
  }, [isSuccessDeleteCarrier])

  //
  /* eslint-disable-next-line */
  // @ts-ignore
  const errorMessage: string = createdError?.data?.message || updateError?.data?.message
  const titleEnError =
    errorMessage && ['en) already exists'].every((str) => errorMessage.includes(str))
  const titleFrError =
    errorMessage && ['fr) already exists'].every((str) => errorMessage.includes(str))

  const error: CreatCarrierError = {
    titleEnError: titleEnError && 'This title english is exists',
    titleFrError: titleFrError && 'This title french is exists',
  }

  // >>>>>>>>>>>>>>>  Title en  <<<<<<<<<<<<<<<<<<
  useEffect(() => {
    if (!error.titleEnError) return

    ShowMessage('error', error.titleEnError as string)

    error.titleEnError &&
      form.setFields([
        {
          name: ['tr', 'en', 'title'],
          errors: [error.titleEnError as string],
        },
      ])
  }, [error.titleEnError])

  // >>>>>>>>>>>>>>>  Title en <<<<<<<<<<<<<<<<<<

  // >>>>>>>>>>>>>>>  Title fr <<<<<<<<<<<<<<<<<<
  useEffect(() => {
    if (!error.titleFrError) return
    ShowMessage('error', error.titleFrError as string)
    error.titleFrError &&
      form.setFields([
        {
          name: ['tr', 'fr', 'title'],
          errors: [error.titleFrError as string],
        },
      ])
  }, [error.titleFrError])

  const handleChange: UploadProps['onChange'] = async (info: UploadChangeParam<UploadFile>) => {
    const image = await getBase64(info.file.originFileObj as RcFile)
    setPreview(activeType === 'en' ? { ...preview, en: image } : { ...preview, fr: image })
  }

  // >>>>>>>>>>>>>>>  Title fr <<<<<<<<<<<<<<<<<<

  const onFinish = (values: IForm) => {
    /* eslint-disable-next-line */
    // @ts-ignore

    const { tr } = values
    const { logoImage: lI, backgroundImage: bI, bannerImage: banI } = images.en
    const { logoImage: lIFr, backgroundImage: bIFr, bannerImage: banIFr } = images.fr
    const deleteValuesArr = ['tr', 'logoImage', 'backgroundImage', 'isPublished', 'bannerImage']
    !values.favoritesPosition && deleteValuesArr.push('favoritesPosition')

    const body = {
      ...omit(values, deleteValuesArr),
      isPublished: values.isPublished != 'false',
      translations: Object.keys(tr).map((key) => ({
        ...tr[key],
        language: { code: key },
      })),
      fixedValues,
    }

    if (!id && ![lI, bI, banI, lIFr, bIFr, banIFr].every((el) => el.file)) {
      ShowMessage(
        'error',
        'You should to fill all fields for each language, especially check images'
      )
    }

    const fData = new FormData()
    fData.append('carrier', JSON.stringify(body))

    !lI.url && fData.append('logoImageEn', lI.file.originFileObj!)
    !bI.url && fData.append('backgroundImageEn', bI.file.originFileObj!)
    !banI.url && fData.append('searchLogoEn', banI.file.originFileObj!)
    !lIFr.url && fData.append('logoImageFr', lIFr.file.originFileObj!)
    !bIFr.url && fData.append('backgroundImageFr', bIFr.file.originFileObj!)
    !banIFr.url && fData.append('searchLogoFr', banIFr.file.originFileObj!)
    id ? updateCarrier({ id, fData }) : createCarrier(fData)
  }
  const { en, fr } = LANGUAGES

  const carrierFormProps = {
    fetchCarrierIsLoading,
    createCarrierIsLoading,
    updateCarrierIsLoading,
    bannerPreview,
    data,
    form,
    bookmarks,
    handleChangeProps: handleChange,
    previewProps: activeType === 'en' ? preview.en : preview.fr,
    error,
  }

  const onDelete = () => {
    id ? deleteCarrier(Number(id)) : navigate(`/${ELayoutVariants.TOP_UP_PIN}`)
  }
  return (
    <div className='store-form'>
      <Form
        form={form}
        ref={specialFormRef}
        onFinish={onFinish}
        autoComplete='off'
        className='store-f'
      >
        <CarrierUpload
          id={id}
          type='backgroundImage'
          previewImg={bgPreview}
          activeType={activeType}
          setImages={setImages}
          images={images}
          shouldUseOnchange
        />
        <div className='store-f_container store-details-form'>
          <CarrierUpload
            id={id}
            type='logoImage'
            previewImg={logoPreview}
            activeType={activeType}
            setImages={setImages}
            images={images}
            shouldUseOnchange
          />
          <Tabs
            activeKey={activeType}
            onChange={(activeKey) => setActiveType(activeKey as 'en' | 'fr')}
            type='card'
          >
            <TabPane tab={en.title} key={en.code}>
              <CarrierForm
                {...carrierFormProps}
                type={en.code}
                setImages={setImages}
                images={images}
                activeType={'bannerImage'}
                previewProps={preview.en || bannerPreview}
              />
            </TabPane>
            <TabPane forceRender tab={fr.title} key={fr.code}>
              <CarrierForm
                {...carrierFormProps}
                type={fr.code}
                setImages={setImages}
                images={images}
                activeType={'bannerImage'}
                previewProps={preview.fr || bannerPreview}
              />
            </TabPane>
          </Tabs>
        </div>
      </Form>

      {!isLoadingTopUpList && topUpsList && !location.pathname.includes('/new') && (
        <CarrierFixedValueListContainer
          carrierFixedValuesAllList={topUpsList}
          carrierFixedValuesPublished={data?.fixedValues}
          refetchPublishedTopUpsList={refetchPublishedTopUpsList}
        />
      )}

      {id && (
        <div className='delete-btn-container'>
          <Button icon={<SVG.BucketIcon />} danger onClick={onDelete}>
            Delete carrier
          </Button>
        </div>
      )}
    </div>
  )
}

export default CarrierFormContainer
