import SpecialOfferForm from '../SpecialOfferForm';
import { ISpecialOfferListProps } from '../../../models/ISpecialOffers';
import { SpecialOfferListHeader } from './SpecialOfferListHeader';

export const SpecialOfferList: React.FC<ISpecialOfferListProps> = (p) => {
  return( 
    <div className='store-details-form-container'>
      <SpecialOfferListHeader onAddNewOffer={p.onAddNewOffer}/>
      {p.newSpecialOffers.map((offer) => {
        return (
          <SpecialOfferForm
            isCreateOffer={true}
            key={offer.id}
            offer={offer}
            onDeleteOffer={p.onDeleteNewOffer}
            onFormSubmit={p.onCreateOffer}
            {...p.propsSpecialForm} />);
      })}

      {p.specialOffers.map((offer) => {
        return <SpecialOfferForm
          isCreateOffer={false}
          key={offer.id}
          offer={offer}
          onDeleteOffer={p.onDeleteExistOffer}
          onFormSubmit={p.onUpdateOffer}
          {...p.propsSpecialForm} />;
      })}
    </div>
    )
};

