import { Form, FormInstance, Input } from 'antd'
import React, { createRef } from 'react'

import './styles.scss'

const FilterCashbackPage: React.FC = () => {
  const form = Form.useFormInstance()
  const formRef = createRef<FormInstance>()

  return (
    <div className="filter-container">
      <Form
        form={form}
        ref={formRef}
        autoComplete="off"
        className="store-f"
        initialValues={{
          searchPhoneNumber: '',
          searchStore: '',
          minSaleAmount: '',
          maxSaleAmount: '',
          minCashback: '',
          maxCashback: '',
        }}
      >
        <div className="form-row filter-row">
          <Form.Item
            name="searchPhoneNumber"
            label="User"
            className="item-half"
          >
            <Input type="number" className="form-filter-input" />
          </Form.Item>
          <Form.Item name="searchStore" label="Store" className="item-half">
            <Input className="form-filter-input" />
          </Form.Item>
        </div>

        <div className="form-row filter-row">
          <Form.Item
            name="minSaleAmount"
            label="Sale amount"
            className="item-half"
          >
            <Input
              type="number"
              placeholder="No min"
              className="form-filter-input"
            />
          </Form.Item>
          <Form.Item name="maxSaleAmount" className="item-half">
            <Input
              type="number"
              placeholder="No max"
              className="form-filter-input"
            />
          </Form.Item>
        </div>

        <div className="form-row filter-row">
          <Form.Item name="minCashback" label="Cash-back" className="item-half">
            <Input
              type="number"
              placeholder="No min"
              className="form-filter-input"
            />
          </Form.Item>
          <Form.Item name="maxCashback" className="item-half">
            <Input
              type="number"
              placeholder="No max"
              className="form-filter-input"
            />
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default FilterCashbackPage
