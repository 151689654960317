import React from 'react'
import { CarrierFixedValueListHeader } from './CarrierFixedValueListHeader'
import {
  ICarrierFixedValueListContainer,
  ICarrierFixedValues,
} from '../../../models/ICarrierFixedValues'
import { Form, Input, Select } from 'antd'
import SVG from '../../../assets/images/SVG'
import { useTypedDispatch, useTypedSelector } from '../../../hooks/hooks'
import { carrierFixedValuesApi } from '../../../services'
import { addTopUpPin, filterTopUpPins } from '../../../redux/reducers'

export const CarrierFixedValueList: React.FC<ICarrierFixedValueListContainer> = (p) => {
  const [removeTopUpPin] = carrierFixedValuesApi.useDeleteTopUpPinMutation()
  const { topUpsList } = useTypedSelector((state) => state.carriers)
  const dispatch = useTypedDispatch()
  const isPublished = (item: ICarrierFixedValues) =>
    topUpsList?.some(
      (fixedValue) => fixedValue.value === item.value && fixedValue.productCode === item.productCode
    )

  return (
    <div className='carrier-details-form-container'>
      <CarrierFixedValueListHeader />

      <div>
        {p.carrierFixedValuesAllList.map((item) => (
          <Form key={`${item.productCode}-${item.value}`} autoComplete='off'>
            <div className='carrier-top-ups'>
              <div
                className='carrier-top-ups-content'
                style={{
                  backgroundColor: isPublished(item) ? 'white' : 'rgb(234, 234, 234)',
                }}
              >
                <div className='form-row row-dbl'>
                  <Form.Item
                    label='Top-Up Pin value'
                    className='item-half carrier-fixed-value'
                    rules={[{ required: true, message: 'Missing Top-Up Pin value' }]}
                  >
                    <Input value={item.value} readOnly />
                  </Form.Item>
                </div>

                <div className='top-up-state-publication'>
                  <Form.Item
                    name='isPublished'
                    initialValue={isPublished(item)}
                    rules={[{ required: true, message: 'Missing status' }]}
                  >
                    <Select
                      onChange={async (value) => {
                        if (!value) {
                          const info = p.carrierFixedValuesPublished.find(
                            (fixedValue) =>
                              fixedValue.value === item.value &&
                              fixedValue.productCode === item.productCode
                          )
                          if (info?.id) {
                            await removeTopUpPin(info.id)
                            p.refetchPublishedTopUpsList()
                          }
                          dispatch(filterTopUpPins(item))
                        } else {
                          dispatch(addTopUpPin(item))
                        }
                      }}
                      suffixIcon={<SVG.ArrowIcon className='carrier-details-arrowIcon' />}
                    >
                      <Select.Option value={true}>Published</Select.Option>
                      <Select.Option value={false}>Unpublished</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        ))}
      </div>
    </div>
  )
}
