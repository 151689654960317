import { ColumnsType } from 'antd/lib/table'
import { IClicks } from '../../models/IClicks'
import moment from 'moment'
import { Link } from 'react-router-dom'
export const columns: ColumnsType<IClicks> = [
  {
    title: 'Click ID',
    dataIndex: 'id',
    defaultSortOrder: 'descend',
    sortDirections: ['descend', 'ascend'],
    sorter: {},
    fixed: 'left',
  },
  {
    title: 'User',
    dataIndex: 'phoneNumber',
    defaultSortOrder: 'descend',
    sortDirections: ['descend', 'ascend'],
    sorter: {},
    render: (text: string, { userId }) => (
      <div className="wrapper-link">
        <Link
          className="table-green-color link-unset-style"
          to={`/user/${userId}`}
        >
          {text}
        </Link>
      </div>
    ),
  },
  {
    title: 'Store',
    dataIndex: 'storeTitle',
    defaultSortOrder: 'descend',
    sortDirections: ['descend', 'ascend'],
    sorter: {},
    render: (text: string, { storeId }) => (
      <div className="wrapper-link">
        <Link
          className="table-green-color link-unset-style"
          to={`/stores/${storeId}`}
        >
          {text}
        </Link>
      </div>
    ),
  },
  {
    title: 'Date & Time',
    dataIndex: 'createdAt',
    defaultSortOrder: 'descend',
    sortDirections: ['descend', 'ascend'],
    sorter: {},
    render: (text) => <>{moment(text).format('YYYY-MM-DD h:mm')}</>,
    fixed: 'right',
  },
]
