import React, { ReactElement } from 'react'
import SVG from '../../assets/images/SVG'
import { IMenuItem } from '../Header'

export interface IHeaderItem {
  startUrl: string
  endUrl: string
  label: string
  key: string
  secondUrl?: string
  Icon: ReactElement
}

export const dataStoreHeader: IMenuItem[] = [
  {
    startUrl: '/stores/',
    endUrl: '',
    secondUrl: '/stores/new',
    label: 'Store Details',
    icon: <SVG.StoreIcon />,
    key: '/stores/',
  },
  {
    startUrl: '/stores/',
    endUrl: '/cash-back',
    label: 'Cash-back',
    icon: <SVG.CashBackIcon />,
    key: '/stores/cash-back',
  },
  {
    startUrl: '/stores/',
    endUrl: '/clicks',
    label: 'Clicks',
    icon: <SVG.ClicksIcon />,
    key: '/stores/clicks',
  },
]

export const dataNewStoreHeader: IMenuItem[] = [
  {
    startUrl: '/stores/',
    endUrl: '',
    secondUrl: '/stores/new',
    label: 'Store Details',
    icon: <SVG.StoreIcon />,
    key: '/stores/',
  },
]

export const dataUserHeader: IMenuItem[] = [
  {
    startUrl: '/user/',
    endUrl: '',
    label: 'User Details',
    icon: <SVG.StoreIcon />,
    key: '/user/',
  },
  {
    startUrl: '/user/',
    endUrl: '/cash-back',
    label: 'Cash-back',
    icon: <SVG.CashBackIcon />,
    key: '/user/cash-back',
  },
  {
    startUrl: '/user/',
    endUrl: '/withdrawals',
    label: 'Withdrawals',
    icon: <SVG.WithdrawalsIcon />,
    key: '/user/withdrawals',
  },
  {
    startUrl: '/user/',
    endUrl: '/clicks',
    label: 'Clicks',
    icon: <SVG.ClicksIcon />,
    key: '/user/clicks',
  },
]

export const dataTopUpPinHeader: IMenuItem[] = [
  {
    startUrl: '/top-up-pin/',
    endUrl: '',
    secondUrl: '/top-up-pin/new',
    label: 'Top Up Pin Details',
    icon: <SVG.TopUpPinIcon />,
    key: '/top-up-pin/',
  },
  {
    startUrl: '/top-up-pin/',
    endUrl: '/cash-back',
    label: 'Cash-back',
    icon: <SVG.CashBackIcon />,
    key: '/top-up-pin/cash-back',
  },
  {
    startUrl: '/top-up-pin/',
    endUrl: '/clicks',
    label: 'Clicks',
    icon: <SVG.ClicksIcon />,
    key: '/top-up-pin/clicks',
  },
]
