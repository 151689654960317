import './styles.scss'
import { ContentPageTabs } from './Components/ContentPageTabs'

export const ContentPage = () => {
  return (
    <div className='detail-l content-page'>
      <ContentPageTabs />
    </div>
  )
}
