import { Skeleton, Tabs } from 'antd'
import { useEffect, useState } from 'react'
import Emitter, { EmitterEvents } from '../../../utils/eventEmitter'
import { contentPageApi } from '../../../services'
import { ErrorNode } from '../../../utils/ShowMessage'
import { NOTIFICATION_TYPES, useNotification } from '../../../hooks/useNotification'
import { namePageByType, TContentPage } from '../../../models/ContentPage'
import { TextEditor } from './TextEditor'

const { TabPane } = Tabs

export const textEditorPlaginList = [
  // 'a11ychecker',
  'advlist',
  'advcode',
  'advtable',
  'autolink',
  'checklist',
  'export',
  'lists',
  'link',
  'image',
  'charmap',
  'preview',
  'anchor',
  'searchreplace',
  'visualblocks',
  'powerpaste',
  'fullscreen',
  'formatpainter',
  'insertdatetime',
  'media',
  'table',
  'help',
  'wordcount',
]

export interface IPageWithEditor {
  activeTab?: string
  tab?: string
  type: TContentPage
  language: string
}

export const PageWithEditor = ({ activeTab, tab, type, language }: IPageWithEditor) => {
  const [editorValue, setEditorValue] = useState([
    { language: 'en', id: 0, content: '' },
    { language: 'fr', id: 1, content: '' },
  ])

  const [uploadedImgId, setUploadedImgId] = useState<number[]>([])
  const [currentActiveTabCount, setCurrentActiveTabCount] = useState(0)

  const [trigger, { data, isFetching, isLoading }] = contentPageApi.useLazyGetContentPageByTypeQuery()
  const [editPage, editPageRes] = contentPageApi.useEditContentPageMutation()

  useEffect(() => {
    trigger(type);
  }, [])

  useEffect(() => {
    if (isFetching || !data?.translations) return

    /* eslint-disable-next-line */
    // @ts-ignore
    setEditorValue(data?.translations)
  }, [isFetching])

  useNotification(NOTIFICATION_TYPES.success, editPageRes.isSuccess)
  useNotification(NOTIFICATION_TYPES.error, editPageRes.isError, editPageRes.error as ErrorNode)

  useEffect(() => {
    activeTab === tab && setCurrentActiveTabCount((pre) => pre + 1)
  }, [activeTab])

  useEffect(() => {
    Emitter.on(EmitterEvents.SAVE_CONTENT_PAGE_DATA, () => {
      editPage({
        id: data?.id as number,
        body: {
          translations: editorValue,
          infoPageImages: uploadedImgId,
        },
      })
    })

    return () => {
      Emitter.off(EmitterEvents.SAVE_CONTENT_PAGE_DATA)
    }
  }, [data, currentActiveTabCount, uploadedImgId, editorValue])

  return (
    <>
      <div className='current-page'>{namePageByType[type]}</div>
      <Tabs activeKey={language} type='card' className='language-tab'>
        <TabPane tab={'EN'} key={'EN'}>
          {isLoading ? (
            <Skeleton.Image active={true} className='skeleton-text-editor' />
          ) : (
            <TextEditor
              editorLanguage='en'
              setEditorValue={setEditorValue}
              initialValue={
                data?.translations?.find((it) => it.language === 'en')?.content as string
              }
              setUploadedImgId={setUploadedImgId}
            />
          )}
        </TabPane>
        <TabPane forceRender tab={'FR'} key={'FR'}>
          {isLoading ? (
            <Skeleton.Image active={true} className='skeleton-text-editor' />
          ) : (
            <TextEditor
              editorLanguage='fr'
              setEditorValue={setEditorValue}
              initialValue={
                data?.translations?.find((it) => it.language === 'fr')?.content as string
              }
              setUploadedImgId={setUploadedImgId}
            />
          )}
        </TabPane>
      </Tabs>
    </>
  )
}
