/* eslint-disable */
export const emailValidator = (_: any, value: string) => {
  if (!value) {
    return Promise.reject(new Error('Please enter valid email address!'))
  }
  const pattern =
    /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i // eslint-disable-line no-control-regex, no-useless-escape, max-len
  const isValue = typeof value === 'string' ? value.trim() : value

  return pattern.test(isValue)
    ? Promise.resolve()
    : Promise.reject(new Error('Please enter valid email address!'))
}

export const passwordValidator = (_: any, value: string) => {
  if (!value) {
    return Promise.reject(new Error('Please enter valid password!'))
  }
  const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/

  const isValue = typeof value === 'string' ? value.trim() : value
  return pattern.test(isValue)
    ? Promise.resolve()
    : Promise.reject(new Error('Please enter valid password!'))
}

export const urlValidator = (_: any, value: string) => {
  if (!value) {
    return Promise.reject(new Error('Please enter valid link!'))
  }
  const pattern =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/gim
  const isValue = typeof value === 'string' ? value.trim() : value
  return pattern.test(isValue)
    ? Promise.resolve()
    : Promise.reject(new Error('Please enter valid link!'))
}

export const getIsValidUrl = (value: string) => {
  const pattern =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/gim
  const isValue = typeof value === 'string' ? value.trim() : value
  return pattern.test(isValue)
}

export const maxLengthValidator100 = (_: any, value: string) => {
  if (!value) {
    return Promise.reject(new Error('Please enter valid max 100!'))
  }
  return Number(value) <= 100
    ? Promise.resolve()
    : Promise.reject(new Error('Please enter valid max 100!'))
}

export const oneDecimalAllowed = (_: any, value: string) => {
  if (!/^(\d+(\.\d{0,1})?)?$/.test(value)) {
    return Promise.reject(new Error('There can be no more than one decimal'))
  }
  return Promise.resolve()
}
