import { useEffect } from 'react'
import { storesApi } from '../services'
import { ShowMessage } from '../utils'

// images
import SVG from '../assets/images/SVG'

interface Props {
  id: number;
  onChangeCurrentPage: () => void;
}

const BucketAction: React.FC<Props> = ({ id, onChangeCurrentPage }: Props) => {
  const [deleteStore, { isSuccess }] = storesApi.useDeleteStoreMutation()

  useEffect(() => {
    if (!isSuccess) return
    ShowMessage('success', 'Success')
    onChangeCurrentPage()
  }, [isSuccess])

  const handleClick = () => {
    deleteStore(id)
  }
  return (
    <div className="action-bucket" onClick={handleClick}>
      <SVG.BucketIcon />
    </div>
  )
}

export default BucketAction
