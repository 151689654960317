import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { Button } from 'antd'
import { dataStoreHeader, dataTopUpPinHeader, dataUserHeader } from './data'
import './styles.scss'
import Emitter, { EmitterEvents } from '../../utils/eventEmitter'
import { userApi } from '../../services'
import { Menu } from '../Menu/Menu'
import { IRenderItem } from '../Header'
import { ShowMessage } from '../../utils'
import { ELayoutVariants } from '../../utils/enums'

interface IProps {
  variant: ELayoutVariants
}

const SubHeader: React.FC<IProps> = ({ variant }) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { pathname } = useLocation()
  const [currentUrl, setCurrentUrl] = useState<string>(pathname)
  const [deleteUser] = userApi.useDeleteUserMutation()

  let user
  let userFullName = null
  if (variant === ELayoutVariants.USER) {
    user = userApi.useGetUserDetailsQuery(id, {
      selectFromResult: (result) => ({
        firstName: result.data?.firstName,
        lastName: result.data?.lastName,
      }),
    })
    userFullName =
      user?.firstName && user?.lastName ? user?.firstName + ' ' + user.lastName : '----'
  }

  useEffect(() => {
    setCurrentUrl(pathname)
  }, [pathname])

  const handleSubmitStore = () => {
    Emitter.emit(EmitterEvents.STORE_REMOTE_SUBMIT)
  }
  const handleSubmitCarrier = () => {
    Emitter.emit(EmitterEvents.CARRIER_REMOTE_SUBMIT)
  }

  const handleBack = () => {
    navigate(`/${variant}`)
  }
  const getDataByVariant = () => {
    if (variant === ELayoutVariants.USER) {
      return dataUserHeader
    } else if (variant === ELayoutVariants.TOP_UP_PIN) {
      return id ? dataTopUpPinHeader : [dataTopUpPinHeader[0]]
    }
    return dataStoreHeader
  }

  const isNewInPath = pathname.includes('new')

  const data = getDataByVariant()
  const isStoredetaisPage =
    ['clicks', 'cash-back'].every((page) => !pathname.includes(page)) &&
    pathname.includes(ELayoutVariants.STORE)
  const isCarrierDetailsPage =
    ['clicks', 'cash-back'].every((page) => !pathname.includes(page)) &&
    pathname.includes(ELayoutVariants.TOP_UP_PIN)

  const onRedirectToUsersPage = () => navigate('/users')

  const deleteSelectedUser = () => {
    deleteUser(Number(id))
    navigate('/users')
    ShowMessage('success', 'User deleted')
  }

  const resolveOnActionCallback = () => {
    if (isStoredetaisPage) {
      return handleSubmitStore()
    } else if (isCarrierDetailsPage) {
      return handleSubmitCarrier()
    }
    return handleBack()
  }

  const userContent = (
    <>
      <Button onClick={onRedirectToUsersPage}>Done</Button>
    </>
  )

  const storeContent = (
    <>
      <Button onClick={handleBack}>{id ? 'Cancel' : 'Back'}</Button>
      <Button onClick={resolveOnActionCallback}>{id ? 'Save' : 'Done'}</Button>
    </>
  )

  const deleteContent = (
    <>
      <Button onClick={deleteSelectedUser}>Delete User</Button>
    </>
  )

  const onClick = (key: string) => {
    navigate(key)
    setCurrentUrl(key)
  }

  const renderItem = (
    { startUrl, endUrl, icon, label }: IRenderItem,
    getIsActive: (key: string) => string,
    onClickItem: (key: string) => void
  ) => (
    <div
      key={`${startUrl! + (isNewInPath ? 'new' : id) + endUrl}`}
      className={getIsActive(`${startUrl! + (isNewInPath ? 'new' : id) + endUrl}`)}
      onClick={() => onClickItem(`${startUrl! + (isNewInPath ? 'new' : id) + endUrl}`)}
    >
      <div className='menu-item-icon'>{icon}</div>
      <div> {label}</div>
    </div>
  )

  return (
    <header className='sub-header'>
      <div className='sub-header_ct'>
        <Menu onClick={onClick} items={data} renderItem={renderItem} selectedKey={currentUrl} />
        <div className='sub-header-user-fullname user-details-info-name'>{userFullName}</div>
        <div className='sub-header_buttons'>
          {variant === ELayoutVariants.USER && deleteContent}
          {variant === ELayoutVariants.USER ? userContent : storeContent}
        </div>
      </div>
    </header>
  )
}

export default SubHeader
