import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ColumnsType } from 'antd/lib/table'
import { Table } from 'antd'
import './styles.scss'
import FilterBlock from '../../components/FilterBlock'
import {IUsers, IUsersRedux} from '../../models/IUsers'

import '../styles.scss'
import { TableConfig, useTableSearchSortPagination } from '../../hooks/useTableSearchSortPagination'
import { MODALS, TableOrder } from '../../utils/constants'
import { usersApi } from '../../services/Users'
import { ModalContext } from '../../components/Modal/modalProvider'
import useEmitterFilterSubmit from '../../hooks/useEmitterFilterSubmit'
import useEmitterClearForFilter from '../../hooks/useEmitterClearForFilter'

const columns: ColumnsType<IUsers> = [
  {
    title: 'Name',
    dataIndex: 'fullName',
    defaultSortOrder: 'descend',
    render: (name: string, { id }: IUsers) =><div className='wrapper-user-link'><Link className='table-green-color link-unset-style' to={`/user/${id}`}>{name}</Link></div> ,
    sorter: {},
    fixed: 'left',
    sortDirections:['descend','ascend']
  },
  {
    title: 'Email',
    dataIndex: 'email',
    defaultSortOrder: 'descend',
    sorter: {},
    sortDirections:['descend','ascend']
  },
  {
    title: 'Phone number',
    dataIndex: 'phoneNumber',
    defaultSortOrder: 'descend',
    sorter: {},
    sortDirections:['descend','ascend']
  },
  {
    title: 'Carrier',
    dataIndex: 'carrier',
    defaultSortOrder: 'descend',
    sorter: {},       
    sortDirections:['descend','ascend']
  },
  {
    title: 'Phone plan',
    dataIndex: 'phonePlan',
    defaultSortOrder: 'descend',
    sorter: {}, 
    render: (text) =><div className='users-table-phone-plan'>{text}</div> ,  
    sortDirections:['descend','ascend']
  },
  {
    title: 'Billing number',
    dataIndex: 'billingNumber',
    defaultSortOrder: 'descend',
    sorter: {},
    // fixed: 'right',
    sortDirections:['descend','ascend']
  },
  {
    title: 'User id',
    dataIndex: 'id',
    defaultSortOrder: 'descend',
    sorter: {},
    fixed: 'right',
    sortDirections:['descend','ascend']
  },
]

const UsersPage = () => {
  const [tableConfig, setTableConfig] = useState<TableConfig>({
    page: 1,
    limit: 10,
    order: TableOrder.descend,  
  })

  const {handleSearchChange,onChangeTable}=useTableSearchSortPagination<IUsers>({setTableConfig,nameSearchQuery:'searchPhoneNumber'})

  const {data = {items:[], count:0}, isLoading, refetch} = usersApi.useFetchAllUsersQuery(tableConfig)
  
  useEmitterFilterSubmit(setTableConfig)
  
  useEmitterClearForFilter(setTableConfig)

  const { onOpen } = useContext(ModalContext)
  const onOpenModal = () =>onOpen(MODALS.usersFilter)

  const onShowSizeChange=(current:number, size:number)=>{    
    setTableConfig((pre)=>({...pre,
    page:current,
    limit:size
    }))
  }

  useEffect(() => {
    refetch()
  }, [])

  return (
    <main className="stores-p users-page">
      <FilterBlock onChange={handleSearchChange} onOpenModal={onOpenModal} placeholder="Search users by phone number"/>
      <Table
        className='users-table'
        loading={isLoading}
        columns={columns}
        dataSource={(data as IUsersRedux).items}
        onChange={onChangeTable}
        pagination={{ 
          position: ['bottomCenter'],
          total: data?.count,
          current:tableConfig.page,
          onShowSizeChange,
      }}
      />
    </main>
  )
}

export default UsersPage
