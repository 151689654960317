import { useState } from 'react'
import { Form, Upload } from 'antd'
import type { RcFile, UploadProps } from 'antd/es/upload'
import { UploadChangeParam } from 'antd/lib/upload'
import type { UploadFile } from 'antd/es/upload/interface'
import getBase64 from '../../../utils/getBase64'
import { CarrierImage } from '../../../models/ICarrier'

type Type = 'backgroundImage' | 'logoImage' | 'bannerImage' | 'image'

interface Props {
  type: Type
  previewImg: string | undefined | CarrierImage
  handleChangeProps?: (info: UploadChangeParam<UploadFile>) => void | Promise<void> | undefined
  previewProps?: string | undefined
  id?: string
  activeType?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  images?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  imageFromOffer?: any
  shouldUseOnchange?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setImages?: (key: any) => void
}

const data = {
  backgroundImage: {
    className: 'store-background',
    title: 'background image',
  },
  logoImage: {
    className: 'store-logo',
    title: 'logo',
  },
  bannerImage: {
    className: 'store-banner',
    title: 'image for search results',
  },
  image: {
    className: 'store-banner',
    title: 'image for special offer',
  },
}

const CarrierUpload: React.FC<Props> = ({
  type,
  previewImg,
  handleChangeProps,
  previewProps,
  id,
  activeType,
  images,
  shouldUseOnchange,
  imageFromOffer,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setImages = () => {},
}) => {
  const [preview, setPreview] = useState<string | undefined>('')
  const [photo, setPhoto] = useState({ en: '', fr: '' })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = async (info: any) => {
    const image = await getBase64(info.file.originFileObj as RcFile)
    setPhoto(activeType === 'en' ? { ...photo, en: image } : { ...photo, fr: image })
    setImages({
      ...images,
      [`${activeType}`]: { ...images[`${activeType}`], [type]: info },
    })
  }
  const handleChange: UploadProps['onChange'] = async (info: UploadChangeParam<UploadFile>) => {
    const image = await getBase64(info.file.originFileObj as RcFile)
    setPreview(image)
    setPhoto(activeType === 'en' ? { ...photo, en: image } : { ...photo, fr: image })
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const pr = photo[`${activeType}`] ||
    preview ||
    previewImg ||
    previewProps ||
    imageFromOffer?.[`${activeType}`]?.image?.url

  return (
    <div className={data[type].className}>
      <Form.Item
        name={type}
        initialValue={pr}
        rules={[{ required: !id && true, message: `Missing ${data[type].title}` }]}
      >
        <Upload
          id={id}
          maxCount={1}
          listType='picture-card'
          accept='.png,.jpg,.jpeg'
          onChange={shouldUseOnchange ? onChange : handleChangeProps || handleChange}
          className='upload-container'
        >
          <div className='container' style={{ backgroundImage: `url(${pr})` }} />
        </Upload>
      </Form.Item>
    </div>
  )
}

export default CarrierUpload
