import { createApi } from '@reduxjs/toolkit/query/react'
import { TContentPage, TContentPagePut, TContentPageRedux } from '../models/ContentPage'
import { baseQueryWithReauth } from '../utils/baseQueryWithReauth'

export const CONTENT_PAGE_API = 'contentPageApi'
export const contentPageApi = createApi({
  reducerPath: CONTENT_PAGE_API,
  baseQuery: baseQueryWithReauth,
  tagTypes: ['ContentPage'],
  endpoints: (builder) => ({
    getContentPageByType: builder.query<TContentPageRedux, TContentPage>({
      query: (type) => ({
        url: `/info-pages/${type}`,
      }),
      providesTags: () => ['ContentPage'],
    }),

    editContentPage: builder.mutation<TContentPageRedux, {id:string | number,body:TContentPagePut}>({
      query: ({id, body}) => ({
        url: `admin/info-pages/${id}`,
        method: 'PUT',
        body,
      }),
    }),

  }),
})
