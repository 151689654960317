import { createApi } from '@reduxjs/toolkit/query/react'
import { IClicksRedux } from '../models/IClicks'
import { TableConfig } from '../models/ITableConfig'
import { baseQueryWithReauth } from '../utils/baseQueryWithReauth'

export const CLICKS_API_REDUCER_KEY = 'clicksApi'
export const clicksApi = createApi({
  reducerPath: CLICKS_API_REDUCER_KEY,
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Clicks'],
  endpoints: (builder) => ({
    getClicksList: builder.query<IClicksRedux, TableConfig>({
      query: (tableConfig) => ({
        url: '/admin/clicks',
        params: {
          ...tableConfig,
        },
      }),
      providesTags: () => ['Clicks'],
    }),
  }),
})
