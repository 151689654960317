/* eslint-disable */
export const getValidateData = (data: any): string => data ?? '----'

const getValidateObj = (obj: any) => {
  const newValidateObj: any = {}

  for (const key in obj) {
    newValidateObj[key] = getValidateData(obj[key])
  }

  return newValidateObj
}
export default getValidateObj
