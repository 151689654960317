import { useEffect } from 'react'
import { carriersApi } from '../services'
import { ShowMessage } from '../utils'

// images
import SVG from '../assets/images/SVG'

interface Props {
  id: number
  onChangeCurrentPage: () => void
}

const BucketActionCarrier: React.FC<Props> = ({ id, onChangeCurrentPage }: Props) => {
  const [deleteCarrier, { isSuccess }] = carriersApi.useDeleteCarrierMutation()

  useEffect(() => {
    if (!isSuccess) return
    ShowMessage('success', 'Success')
    onChangeCurrentPage()
  }, [isSuccess])

  const handleClick = () => {
    deleteCarrier(id)
  }
  return (
    <div className='action-bucket' onClick={handleClick}>
      <SVG.BucketIcon />
    </div>
  )
}

export default BucketActionCarrier
