import SVG from '../../../assets/images/SVG'
import { Button, Form, Input } from 'antd'
import { ISpecialOfferFormProps } from '../../../models/ISpecialOffers'
import StoreUpload from '../StoreUpload'

import { urlValidator } from '../../../utils/validation'

export const SpecialOfferForm: React.FC<ISpecialOfferFormProps> = (p) => {
  const title = p.type === 'en' ? p.offer.id + 'title' : p.offer.id + (p.type !== 'fr' ? 'fr' : 'en') + 'title'
  const description = p.type === 'en' ? p.offer.id + 'description' : p.offer.id + (p.type !== 'fr' ? 'fr' : 'en') + 'description'
  return (
    <Form
      form={p.form}
      ref={p.specialFormRef}
      onFinish={p.onFinish}
      autoComplete='off'
      className='store-f'
    >
      <div key={p.offer.id} className='store-special-offers'>
        <div className='special-offers-content'>
          <div className='special-offers-content-header'>
            <div className='special-offers-number-offer'>
              Special offer {!p.isCreateOffer ? p.offer.id : 'new'}
            </div>
            <div className='special-offers-edit-delete-icon'>
              <Form.Item
                name={p.formValue[p.type === 'en' ? 'fr' : 'en'].title ?  description : title}
                initialValue={p.formValue[p.type === 'en' ? 'fr' : 'en'].title && p.formValue[p.type === 'en' ? 'fr' : 'en'].description }
                rules={[{ required: true, message: `Missing ${p.type === 'en' ? 'French' : 'English'} version` }]}
              >
                <Button htmlType='submit'>{p.isCreateOffer ? 'Create' : 'Update'}</Button>
              </Form.Item>
              <div
                className='special-offers-edit-delete-icon-delete'
                onClick={() => p.onDeleteOffer({ id: p.id, offerId: p.offer.id })}
              >
                <SVG.DeleteIcon />
              </div>
            </div>
          </div>

          <div style={{ display: p.type == 'en' ? 'flex' : 'none' }} className='form-row row-dbl'>
            <Form.Item
              label='Title'
              className='item-half'
              name={p.offer.id + p.type + 'title'}
              initialValue={p.formValue['en'].title}
              rules={[{ required: true, message: 'Missing title' }]}
            >
              <Input onChange={p.onSetTitle} />
            </Form.Item>
            <Form.Item
              label='Description'
              className='item-half'
              initialValue={p.formValue['en'].description}
              name={p.offer.id + p.type + 'description'}
              rules={[{ required: true, message: 'Missing description' }]}
            >
              <Input onChange={p.onSetDescription} />
            </Form.Item>
          </div>

          <div style={{ display: p.type == 'fr' ? 'flex' : 'none' }} className='form-row row-dbl'>
            <Form.Item
              label='Title'
              className='item-half'
              name={p.offer.id + 'title'}
              initialValue={p.formValue['fr'].title}
              rules={[{ required: true, message: 'Missing title' }]}
            >
              <Input onChange={p.onSetTitle} />
            </Form.Item>
            <Form.Item
              label='Description'
              className='item-half'
              name={p.offer.id + 'description'}
              initialValue={p.formValue['fr'].description}
              rules={[{ required: true, message: 'Missing description' }]}
            >
              <Input onChange={p.onSetDescription} />
            </Form.Item>
          </div>

          <div className='form-row row-dbl'>
            {/* <Form.Item
              label="Reward amount"
              name={'reward'}
              initialValue={p.offer.reward}
              className="item-half"
              rules={[
                { required: true, message: 'Missing reward' },
                { validator: maxLengthValidator100, message: 'Max reward 100' },
              ]}
            >
              <Input
                type="number"
                onChange={(e) =>
                  p.setFormValue((pre) => ({
                    ...pre,
                    reward: e.target.value,
                  }))
                }
              />
            </Form.Item> */}
            <Form.Item
              label='Link'
              initialValue={p.offer.link}
              name={'link'}
              className='item-half'
              rules={[
                {
                  required: true,
                  message: 'Missing link',
                },
                {
                  validator: urlValidator,
                },
              ]}
            >
              <Input
                onChange={(e) =>
                  p.setFormValue((pre) => ({
                    ...pre,
                    link: e.target.value,
                  }))
                }
              />
            </Form.Item>
            <div className='banner-image-wrapper'>
              <StoreUpload
                type='image'
                previewImg={p.formValue.image}
                setImages={p.setImage}
                images={p.image}
                shouldUseOnchange
                activeType={p.type}
                imageFromOffer={p.offer.translations}
              />
              <div className='banner-image-title-special-offer'>Upload Photo</div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  )
}
