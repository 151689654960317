import React, { useContext, useState } from 'react'
import { Table } from 'antd'
import './styles.scss'
import FilterBlock from '../../components/FilterBlock'
import '../styles.scss'
import { TableConfig, useTableSearchSortPagination } from '../../hooks/useTableSearchSortPagination'
import { MODALS, TableOrder } from '../../utils/constants'
import { ModalContext } from '../../components/Modal/modalProvider'
import useEmitterFilterSubmit from '../../hooks/useEmitterFilterSubmit'
import {  withdrawalApi } from '../../services'
import {  IWithdrawal, IWithdrawalsRedux } from '../../models/IWithdrawals'
import useEmitterClearForFilter from '../../hooks/useEmitterClearForFilter'
import columns from './columns'

const WithdrawalsPage = () => {
  const [tableConfig, setTableConfig] = useState<TableConfig>({
    page: 1,
    limit: 10,
    order: TableOrder.descend,    
  })

  const {handleSearchChange,onChangeTable}=useTableSearchSortPagination<IWithdrawal>({setTableConfig,nameSearchQuery:'searchWithdrawal'})
  const {data = {items:[],count:0}, isLoading} = withdrawalApi.useGetWithdrawalListQuery(tableConfig)
  
  useEmitterFilterSubmit(setTableConfig)
  useEmitterClearForFilter(setTableConfig)
  
  const { onOpen } = useContext(ModalContext)
  const onOpenModal = () =>onOpen(MODALS.withdrawalsFilter)

  const onShowSizeChange=(current:number, size:number)=>{    
    setTableConfig((pre)=>({...pre,
    page:current,
    limit:size
    }))
  }
  
  return (
    <main className="stores-p">
    <FilterBlock onChange={handleSearchChange} onOpenModal={onOpenModal} placeholder="Search wallets by ID"/>
    <Table
    className='users-table withdrawals-table'
      loading={isLoading}
      columns={columns}
      dataSource={(data as IWithdrawalsRedux).items.map((el) => ({ ...el, key: el.id }))}
      onChange={onChangeTable}
      pagination={{ 
        position: ['bottomCenter'],
        total: data?.count,
        current:tableConfig.page,
        onShowSizeChange,
     }}
    />
  </main>
  )
}

export default WithdrawalsPage
