import { Form, FormInstance, Input, DatePicker } from 'antd'
import React, { createRef } from 'react'
import SVG from '../../../../assets/images/SVG'

import './styles.scss'

const AddNewCashback: React.FC = () => {
  const form = Form.useFormInstance()
  const formRef = createRef<FormInstance>()

  return (
    <div className="filter-container">
      <Form
        form={form}
        ref={formRef}
        autoComplete="off"
        className="store-f"
        initialValues={{
          searchPhoneNumber: '',
          searchStore: '',
          minSaleAmount: '',
          maxSaleAmount: '',
          minCashback: '',
          maxCashback: '',
        }}
      >
        <div className="form-row filter-row">
          <Form.Item
            name="userId"
            label="User ID"
            className="item-half"
            rules={[{ required: true, message: 'Missing User Id' }]}
          >
            <Input type="number" className="form-filter-input" />
          </Form.Item>
          <Form.Item
            name="storeId"
            label="Store ID"
            className="item-half"
            rules={[{ required: true, message: 'Missing Store Id' }]}
          >
            <Input type="number" className="form-filter-input" />
          </Form.Item>
        </div>

        <div className="form-row filter-row">
          <Form.Item
            name="orderId"
            label="Order ID"
            className="item-half"
            rules={[{ required: true, message: 'Missing Order Id' }]}
          >
            <Input type="string" className="form-filter-input" />
          </Form.Item>
          <Form.Item
            name="saleAmount"
            className="item-half"
            label="Sale Amount"
            rules={[{ required: true, message: 'Missing Sale Amount' }]}
          >
            <Input type="number" className="form-filter-input" />
          </Form.Item>
        </div>

        <div className="form-row filter-row">
          <Form.Item
            name="reward"
            label="Reward"
            className="item-half"
            rules={[{ required: true, message: 'Missing Reward' }]}
          >
            <Input type="number" className="form-filter-input" />
          </Form.Item>
          <Form.Item
            name="rewardRatio"
            label="Reward Ratio"
            className="item-half"
            rules={[{ required: true, message: 'Missing Reward Ratio' }]}
          >
            <Input type="number" className="form-filter-input" />
          </Form.Item>
        </div>

        <div className="form-row filter-row">
          <Form.Item
            name="withdrawalAt"
            label="Cashback Date"
            className="item-half"
            rules={[{ required: true, message: 'Missing Cashback Date' }]}
          >
            <DatePicker
              placeholder="From"
              suffixIcon={<SVG.CalendarIcon />}
              className="form-filter-datapicker"
              format="YYYY-MM-DD"
            />
          </Form.Item>
          <Form.Item
            name="transactionAt"
            label="Transaction Date"
            className="item-half"
            rules={[{ required: true, message: 'Missing Transaction Date"' }]}
          >
            <DatePicker
              placeholder="From"
              suffixIcon={<SVG.CalendarIcon />}
              className="form-filter-datapicker"
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default AddNewCashback
