import React from 'react'
import './styles.scss'
interface IProps{
    body:string|string
    footer:string
    className?:string
}

export const InfoCard:React.FC<IProps> = ({ body='', footer='',className }) => {
  return (
    <div className={`info-card ${className}`}>
      <div className="info-card-body">{body}</div>
      <div className="info-card-footer">{footer}</div>
    </div>
  )
}
