import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ITokens } from '../../models'

export interface IAuthState {
  access_token: string | null;
  refresh_token: string | null;
}

const initialState: IAuthState = {
  access_token: null,
  refresh_token: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateTokens(state, action: PayloadAction<ITokens>) {
      state.access_token = action.payload.access_token
      state.refresh_token = action.payload.refresh_token
    },
  },
})

export const authReducer = authSlice.reducer
