/* eslint-disable */

import React, { useContext } from 'react'
import { Modal as AntModal } from 'antd'

import './styles.scss'
import { ModalContext } from '../../modalProvider'

interface IProps {
  onDelete: () => void
  onClose: () => void
}

const BannerDeleteModal = ({ onDelete, onClose }: IProps) => {
  // @ts-ignore

  return (
    <AntModal
      open={true}
      closable={false}
      className='banner-delete-modal'
      footer={[
        <div key={1} className='button-container'>
          <div className='clear-button' onClick={onClose}>
            Cancel
          </div>

          <div className='submit-button delete-btn' onClick={onDelete}>
            Delete
          </div>
        </div>,
      ]}
    >
      <div>Do you really want to delete this Banner?</div>
    </AntModal>
  )
}

export default BannerDeleteModal

// export const BannerDeleteModal = () => {
//   return <div className='banner-delete-modal'></div>
// }
