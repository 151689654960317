import React, { useMemo } from 'react'
import { Form, Skeleton, Tabs } from 'antd'
import SVG from '../../assets/images/SVG'
import { FormPreviewArticle } from './FormPreviewArticle'
import { SkeletonArticle } from './SkeletonArticle'
import { IFormArticleProps } from '../../models/IBlog'
import { TextEditor } from "../ContentPage/Components/TextEditor";

const { TabPane } = Tabs
const fetchURL = process.env.NODE_ENV === 'development' ? 'https://api.staging.telcorewards.ca/api/admin/info-page-images': 'https://api.telcorewards.ca/api/admin/info-page-images'

export const FormArticle: React.FC<IFormArticleProps> = (p) => {
  const isEnLocale = useMemo(() => {
    return p.language === 'en';
  }, [p.language])

  const getContent = (locale: 'en' | 'fr') => {
    return p.articleRes?.data?.translations?.find(({ language }) => language === locale)?.content || '' as string;
  }

  return (
    <main className='article'>
      <Form form={p.form} onFinish={p.onFinish} autoComplete='off'>
        <div>
          <Tabs
            className='article-tabs'
            tabBarExtraContent={<p.Actions />}
            activeKey={p.activeType}
            onChange={(activeKey) => p.setActiveType(activeKey as '1' | '2')}
            type='card'
          >
            <TabPane tab={ViewOnBlogPageTab} key='1'>
              {p.articleRes.isLoading ? (
                <SkeletonArticle />
              ) : (
                <>
                  <div className={`${!isEnLocale && 'hidden'}`}>
                    <FormPreviewArticle
                      previewImg={p.articleRes.data?.logo}
                      language='en'
                    />
                  </div>
                  <div className={`${isEnLocale && 'hidden'}`}>
                    <FormPreviewArticle
                      previewImg={p.articleRes.data?.logo}
                      language='fr'
                    />
                  </div>
                </>
              )}
            </TabPane>
            <TabPane forceRender tab={ViewOnArticlePageTab} key='2'>
              {p.articleRes.isLoading ? (
                <Skeleton.Image active={true} className='skeleton-text-editor' />
              ) : (
                <>
                  <div className={`${!isEnLocale && 'hidden'}`}>
                    <TextEditor
                      setEditorValue={p.setEditorValue}
                      editorLanguage='en'
                      setUploadedImgId={p.setUploadedImgId}
                      fetchURL={fetchURL}
                      initialValue={getContent('en') ? getContent('en') : `<p>Test</p>`}
                      fieldName='blogImage'
                    />
                  </div>
                  <div className={`${isEnLocale && 'hidden'}`}>
                    <TextEditor
                      setEditorValue={p.setEditorValue}
                      editorLanguage='fr'
                      setUploadedImgId={p.setUploadedImgId}
                      fetchURL={fetchURL}
                      initialValue={getContent('fr') ? getContent('fr') : `<p>Test</p>`}
                      fieldName='blogImage'
                    />
                  </div>
                </>
                // <Editor
                //   // apiKey='p5pwi0spwy0xiask9dtrit1dqxkn2jp2hql74aioyh4m1sw2'
                //   /* eslint-disable-next-line */
                //   // @ts-ignore
                //   onInit={(evt, editor) => (p.editorRef.current = editor)}
                //   initialValue={
                //     p.articleRes.data?.content ? p.articleRes.data.content : `<p>Test</p>`
                //   }
                //   init={{
                //     images_upload_handler: imageUploadHandler,
                //     file_picker_callback: function (callback, value, meta) {
                //       if (meta.filetype == 'image') {
                //         callback('myimage.jpg', { alt: 'My alt text', src: 'q12321' })
                //       }
                //     },
                //     height: 500,
                //     menubar: false,
                //     plugins: textEditorPlaginList,
                //     toolbar:
                //       'undo redo | casechange blocks | bold italic backcolor | ' +
                //       'alignleft aligncenter alignright alignjustify | ' +
                //       'bullist numlist checklist outdent indent | removeformat | a11ycheck code insert/edit link insert/edit image insert/edit media embed iframe table',
                //     // toolbar: 'removeformat | a11ycheck code table help',
                //     content_style:
                //       'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                //   }}
                // />
              )}
            </TabPane>
          </Tabs>
        </div>
      </Form>
    </main>
  )
}

const ViewOnBlogPageTab = (
  <div className='tab-item-article'>
    <SVG.ArticlePage1Icon />
    <div>View On Blog Page</div>
  </div>
)

const ViewOnArticlePageTab = (
  <div className='tab-item-article'>
    <SVG.ArticlePage2Icon />
    <div>View On Article Page</div>
  </div>
)

// onBeforeSetContent={(ee, qq) => {
//   console.log('ee.paste', ee.paste)
//   console.log('ee', ee.content)
//   console.log('ee', qq)
// }}
// onSaveContent={(ee) => {
//   console.log('ee', ee)
// }}

// value='2'
// onEditorChange={(newValue, editor) => {
// console.log('editor', editor)
// console.log('newValue___', newValue)
// setValue(newValue);
// console.log('____', editor.getContent({ format: 'text' }))
// setText(editor.getContent({format: 'text'}));
// console.log(editor.)
// }}
