import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from '../utils/baseQueryWithReauth'
import { ICarrierFixedValues } from '../models/ICarrierFixedValues'

export const TOP_UPS_API_REDUCER_KEY = 'carrierFixedValuesApi'
export const carrierFixedValuesApi = createApi({
  reducerPath: TOP_UPS_API_REDUCER_KEY,
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    fetchAllTopUpsByCarrierTitle: builder.query<ICarrierFixedValues[], string>({
      query: (productTitle) => ({
        url: `/admin/carriers/fixedValues/products/${productTitle}`,
      }),
    }),
    deleteTopUpPin: builder.mutation<void, number>({
      query: (fixedValueId) => ({
        url: `/admin/carriers/fixedValues/${fixedValueId}`,
        method: 'DELETE',
      }),
    }),
  }),
})
