import FilterBlock from '../../components/FilterBlock'
import { useParams } from 'react-router'
import { Table } from 'antd'
import './styles.scss'
import { storeApi } from '../../services'
import {
  TableConfig,
  useTableSearchSortPagination,
} from '../../hooks/useTableSearchSortPagination'
import { MODALS, TableOrder } from '../../utils/constants'
import { useContext, useState } from 'react'
import { ModalContext } from '../../components/Modal/modalProvider'
import useEmitterFilterSubmit from '../../hooks/useEmitterFilterSubmit'
import { IStoreClicks } from '../../models'
import useEmitterClearForFilter from '../../hooks/useEmitterClearForFilter'
import { isEmpty } from 'lodash'
import columns from './columns'

const StoreClicks: React.FC = () => {
  const { id } = useParams()
  const [tableConfig, setTableConfig] = useState<TableConfig>({
    page: 1,
    limit: 10,
    order: TableOrder.descend,
  })

  const { handleSearchChange, onChangeTable } =
    useTableSearchSortPagination<IStoreClicks>({
      setTableConfig,
      nameSearchQuery: 'searchClick',
    })

  const { data = { items: [], count: 0 }, isLoading } =
    storeApi.useGetStoreClicksQuery({
      id,
      tableConfig,
    })

  const getNewDataPhoneNumberFromUser = () => {
    return isEmpty(data?.items)
      ? []
      : data?.items.map((it) => ({
          ...it,
          phoneNumber: it.user.phoneNumber,
        }))
  }
  const changedData = getNewDataPhoneNumberFromUser()

  useEmitterFilterSubmit(setTableConfig)
  useEmitterClearForFilter(setTableConfig)

  const { onOpen } = useContext(ModalContext)
  const onOpenModal = () => onOpen(MODALS.storeFilterClicks)

  const onShowSizeChange = (current: number, size: number) => {
    setTableConfig((pre) => ({ ...pre, page: current, limit: size }))
  }

  return (
    <main className="user-clicks">
      <FilterBlock
        onChange={handleSearchChange}
        onOpenModal={onOpenModal}
        placeholder="Search clicks"
      />
      <Table
        className="store-clicks-table"
        loading={isLoading}
        columns={columns}
        dataSource={changedData}
        onChange={onChangeTable}
        pagination={{
          position: ['bottomCenter'],
          total: data?.count,
          current: tableConfig.page,
          onShowSizeChange,
        }}
      />
    </main>
  )
}
export default StoreClicks
