/* eslint-disable */
import { useEffect } from 'react'
import { TableConfig } from '../models/ITableConfig'
import { IPageConf } from '../utils/constants'
import Emitter, { EmitterEvents } from '../utils/eventEmitter'

type Props<T> = React.Dispatch<
  React.SetStateAction<T>
>

type Conf = TableConfig | IPageConf

const useEmitterFilterSubmit = <T extends Conf>(setQueryParametrConfig: Props<T>) => {
  useEffect(() => {
    Emitter.on(EmitterEvents.MODAL_FILTER_SUBMIT, (value) => {
      setQueryParametrConfig((pre: any) => ({
        page: 1,
        limit: pre.limit,
        order: pre.order,
        ...value,
      }))
    })
    return () => {
      Emitter.off(EmitterEvents.MODAL_FILTER_SUBMIT)
    }
  }, [])
}
export default useEmitterFilterSubmit
