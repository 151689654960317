import { isRejectedWithValue } from '@reduxjs/toolkit'

import { ShowMessage } from '../../utils'

/**
 * Log a warning and show a toast!
 */

export const rtkQueryErrorLogger = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    ShowMessage('error', action.payload.data?.message)
  }
  return next(action)
}
