import React, { useContext, useState } from 'react'
import { Empty, Pagination, Spin } from 'antd'
import './styles.scss'
import FilterBlock from '../../components/FilterBlock'
// import '../styles.scss'
import { TableConfig } from '../../hooks/useTableSearchSortPagination'
import { MODALS, TableOrder } from '../../utils/constants'
import { ModalContext } from '../../components/Modal/modalProvider'
import { blogApi } from '../../services'
import { useNavigate } from 'react-router-dom'
import { ArticleCard } from './ArticleCard'
import useSearch from '../../hooks/useSearch'

const Blog = () => {
  const navigate = useNavigate()

  const [pageConf, setPageConf] = useState<TableConfig>({
    page: 1,
    limit: 10,
    order: TableOrder.descend,
    language: 'en',
  })
  const { handleSearchChange } = useSearch(setPageConf, 'search')

  const { data: articleList = { items: [], totalCount: 0 }, isLoading } =
    blogApi.useGetArticleListQuery(pageConf)

  const { onOpen } = useContext(ModalContext)
  const onOpenModal = () => onOpen(MODALS.cashbackFilter)
  const redirectToNewArticlePage = () => navigate('new-article1')

  const onPaginationChange = (page: number) => {
    setPageConf((pre) => ({ ...pre, page }))
  }

  const onShowSizeChange = (current: number, size: number) => {
    setPageConf((pre) => ({ ...pre, page: current, limit: size }))
  }

  const renderContent = () => (
    <>
      {articleList.items.map((article) => (
        <ArticleCard key={article.id} article={article} />
      ))}

      <div className='blog-content'>
        {articleList?.totalCount === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

        {articleList?.totalCount !== 0 && !isLoading && (
          <div className='wrapper-pagination'>
            <Pagination
              current={pageConf.page}
              onChange={onPaginationChange}
              pageSize={pageConf.limit}
              total={articleList?.totalCount}
              onShowSizeChange={onShowSizeChange}
            />
          </div>
        )}
      </div>
    </>
  )

  return (
    <main className='stores-p'>
      <div className='header-block-container'>
        <div className='filter-block-wrapper'>
          <FilterBlock
            onChange={handleSearchChange}
            onOpenModal={onOpenModal}
            placeholder='Search articles'
            isShowFilter={false}
          />
        </div>
        <div className='add-new-cashback' onClick={redirectToNewArticlePage}>
          + Add New Article
        </div>
      </div>

      {isLoading ? <Spin>{renderContent()}</Spin> : renderContent()}
    </main>
  )
}

export default Blog
