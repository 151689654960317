import { Form, Input, Select, Spin } from 'antd'
import React, { useEffect } from 'react'
import Emoji from '../../../assets/images/Emoji'
import Chip from '../Сhip'
import { isEmpty } from 'lodash'
import { IStoreFormProps } from '../../../models'
import { maxLengthValidator100, oneDecimalAllowed, urlValidator } from '../../../utils/validation'
import StoreUpload from '../StoreUpload'
import SVG from '../../../assets/images/SVG'

const { TextArea } = Input
const { Option } = Select

type EmojiCategory = keyof typeof Emoji

const featuredStore: Array<number> = Array.from({ length: 100 }, (_, index) => index + 1)

const StoreForm: React.FC<IStoreFormProps> = ({
  type,
  categoryList,
  form,
  affiliateList,
  bookmarks,
  selectedCategoryList,
  setSelectedCategoryList,
  attributeList,
  bannerPreview,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleChangeProps = () => {},
  previewProps,
  fetchStoreIsLoading,
  createStoreIsLoading,
  updateStoreIsLoading,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setImages = () => {},
  images,
  activeType = '',
}) => {
  const onSelectCategory = (id: string) => {
    const currCategory = categoryList.find((category) => category.id == Number(id))!
    setSelectedCategoryList((pre) =>
      pre.map((cat) => cat.title).includes(currCategory.title) ? [...pre] : [...pre, currCategory]
    )
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = async (info: any) => {
    handleChangeProps(info)
    setImages({
      ...images,
      [`${type}`]: { ...images[`${type}`], [activeType]: info },
    })
  }
  useEffect(() => {
    if (isEmpty(selectedCategoryList)) {
      form.setFieldValue('categoryIds', null)
    }
    form.setFieldValue('categoryIds', selectedCategoryList.at(-1)?.title)
  }, [selectedCategoryList])

  const renderContent = () => (
    <>
      <Form.Item
        name='isPublished'
        className='item-half select-publish-status'
        rules={[{ required: true, message: 'Missing status' }]}
      >
        <Select suffixIcon={<SVG.ArrowIcon className='store-details-arrowIcon' />}>
          <Option value='true'>Published</Option>
          <Option value='false'>Unpublished</Option>
        </Select>
      </Form.Item>

      <div className='description-container'>
        <div className='description-wrapper'>
          <Form.Item
            name={['tr', type, 'title']}
            className='input-big item-half'
            rules={[{ required: true, message: 'Missing title' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['tr', type, 'description']}
            rules={[{ required: true, message: 'Missing description' }]}
          >
            <TextArea />
          </Form.Item>
        </div>
        <div className='banner-image-wrapper'>
          <StoreUpload
            type='bannerImage'
            previewImg={previewProps || bannerPreview}
            handleChangeProps={onChange}
          />
          <div className='banner-image-title'>Image for search results</div>
        </div>
      </div>
      <div className='form-row row-dbl'>
        <Form.Item
          label='Store link'
          name='link'
          className='item-half'
          rules={[
            {
              required: true,
              message: 'Missing link',
            },
            {
              validator: urlValidator,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Base reward'
          name='baseReward'
          className='item-half'
          rules={[
            { required: true, message: 'Missing base reward' },
            { validator: maxLengthValidator100, message: 'Max reward 100' },
            { validator: oneDecimalAllowed, message: 'Only one decimal is allowed' },
          ]}
        >
          <Input type='number' />
        </Form.Item>
      </div>

      <div className='form-row row-dbl'>
        <Form.Item label='Attributes' name='attributes' className='item-half'>
          <Select mode='multiple' showArrow labelInValue showSearch={false}>
            {attributeList?.map(({ id, title }) => (
              <Option key={id} value={id}>
                {title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ setFields }) => (
            <Form.Item
              label='Affiliate'
              name='affiliateNetworkId'
              className='item-half'
              rules={[{ required: true, message: 'Missing affiliate' }]}
            >
              <Select
                onChange={(value) => {
                  if (String(value) === '4') {
                    // 4 is status of EBAY
                    setFields([{ name: 'advertiserId', value: '', errors: [] }])
                  }
                }}
              >
                {affiliateList.map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Form.Item>
      </div>
      <div className='form-row row-dbl'>
        <Form.Item
          label='Featured store'
          name='favoritesPosition'
          className='item-half'
          initialValue={''}
        >
          <Select>
            {featuredStore.map((option) => (
              <Option
                disabled={bookmarks?.existingBookmarks.some((it) => it == option)}
                key={option}
                value={option}
              >
                {option}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label='Category' name='categoryIds' className='item-half'>
          <Select onSelect={onSelectCategory}>
            {categoryList.map(({ title, id }) => (
              <Option key={id} value={id}>
                {title}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div className='form-row row-dbl'>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const affiliate = getFieldValue('affiliateNetworkId')

            return (
              <Form.Item
                label='Affiliate ID'
                name='advertiserId'
                className='item-half'
                rules={[
                  String(affiliate) === '4'
                    ? {} // 4 is status of EBAY
                    : {
                        required: true,
                        message: 'Missing affiliate id',
                      },
                ]}
              >
                <Input type='number' disabled={String(affiliate) === '4'} />
              </Form.Item>
            )
          }}
        </Form.Item>
        <Form.Item
          label='Special reward minimum'
          name='specialRewardStart'
          className='item-half'
          rules={[{ required: true, message: 'Missing special reward minimum' }]}
        >
          <Input type='number' />
        </Form.Item>
      </div>

      <div className='form-row row-dbl'>
        {/* <Form.Item
          label="Special reward title"
          name={['tr', type, 'specialRewardTitle']}
          className="item-half"
          rules={[{ required: true, message: 'Missing special reward title' }]}
        >
          <Input />
        </Form.Item> */}
        <Form.Item
          label='Special reward amount'
          name='specialReward'
          className='item-half'
          rules={[
            { required: true, message: 'Missing special reward amount' },
            { validator: maxLengthValidator100, message: 'Max reward 100' },
            { validator: oneDecimalAllowed, message: 'Only one decimal is allowed' },
          ]}
        >
          <Input type='number' />
        </Form.Item>
        <Form.Item
          label='Store Name'
          name='slag'
          className='item-half'
          rules={[
            {
              required: true,
              message: 'Missing Store Name',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </div>

      <div className='form-row'>
        <Form.Item label='SEO Title' name={['tr', type, 'seoTitle']}>
          <Input />
        </Form.Item>
      </div>

      <div className='form-row'>
        <Form.Item label='SEO Description' name={['tr', type, 'seoDescription']}>
          <TextArea />
        </Form.Item>
      </div>

      <div className='form-row'>
        <Form.Item label='SEO Keywords' name={['tr', type, 'seoKeywords']}>
          <Input />
        </Form.Item>
      </div>

      <div className='selected-category-list'>
        {selectedCategoryList.map((category) => {
          return (
            <Chip
              key={category.id}
              classname='store-chip-category'
              logo={<img src={Emoji[category.title as EmojiCategory]} alt={'emoji'} />}
              content={category.title}
              onDelete={() => {
                setSelectedCategoryList((pre) =>
                  pre.filter((currCategory) => currCategory != category)
                )
              }}
            />
          )
        })}
      </div>
    </>
  )

  return (
    <>
      {fetchStoreIsLoading || createStoreIsLoading || updateStoreIsLoading ? (
        <Spin>{renderContent()}</Spin>
      ) : (
        renderContent()
      )}
    </>
  )
}

export default StoreForm
