import React from 'react'

export type TContentPage =
  | 'contact_us'
  | 'faq'
  | 'how_it_works'
  | 'privacy_policy'
  | 'terms_and_conditions'
  | 'telecoms'

export enum ContentPageTabsEnum {
  CONTACT_US = 'CONTACT_US',
  FAQ = 'FAQ',
  HOW_IT_WORKS = 'HOW_IT_WORKS',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  TELECOMS = 'TELECOMS',
}

export interface ITranslations {
  id: number
  content: string
  language: string
}

export interface TContentPageRedux {
  id: number
  type: string
  translations: ITranslations[]
}

export interface TContentPagePut {
  translations: ITranslations[]
  infoPageImages?: number[]
}

export interface ITextEditor {
  setEditorValue?: React.Dispatch<React.SetStateAction<ITranslations[]>>
  editorLanguage: 'en' | 'fr'
  setUploadedImgId: React.Dispatch<React.SetStateAction<number[]>>
  initialValue: string
  fetchURL?: string
  fieldName?: string
  onChange?: (a: string) => void
}

export const namePageByType = {
  contact_us: 'CONTACT US PAGE',
  faq: 'FAQ PAGE',
  how_it_works: 'HOW IT WORKS PAGE',
  terms_and_conditions: 'TERMS AND CONDITIONS PAGE',
  privacy_policy: 'PRIVACY POLICY PAGE',
  telecoms: 'TELECOMS PAGE',
}
