import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Typography } from 'antd'

import './styles.scss'

import { useTypedDispatch } from '../../../hooks/hooks'
import { emailValidator } from '../../../utils/validation'
import { ShowMessage } from '../../../utils'
import { authApi } from '../../../services'
import { IAuthBody, ITokens } from '../../../models'
import { authSlice } from '../../../redux/reducers'
import SVG from '../../../assets/images/SVG'

const { Title } = Typography

const SignInForm: React.FC = () => {
  const dispatch = useTypedDispatch()
  const [login, { data, isSuccess, }] = authApi.useLoginMutation()
  const [isDisabled, setDisabled] = useState(false)
  const [isShowPassword,setIsShowPassword]=useState(false)
  useEffect(() => {
    if (!isSuccess) return
    dispatch(authSlice.actions.updateTokens(data as ITokens));
    // localStorage.setItem('persist:auth',JSON.stringify(data))
    ShowMessage('success', 'Success')
    // eslint-disable-next-line
  }, [isSuccess])

  // useEffect(() => {
  //   if (!isError) return
  //   handleError(error as ErrorNode)
  // }, [isError, error])

  const onFinish = async (values: IAuthBody) => {
    setDisabled(prev => !prev)
    await login(values)
    setDisabled(prev => !prev)
  }

  useEffect(()=>{
    document.title='TR - Login'
  },[])

  return (
    <div className="sign-in-form-c">
      <Title className="title" level={3}>
        Log in to Admin Panel
      </Title>
      <Form
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Login"
          name="email"
          rules={[{ validator: emailValidator }]}          
        >          
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{required:true,message:'Please enter valid password' }]}
          
        >         
        {/* <Input.Password className='login-password'/>  */}
        <div className='inp-password'>
        <Input type={isShowPassword?'text':'password'} />
        <SVG.EyeIcon onClick={()=>setIsShowPassword((pre)=>!pre)} isShow={isShowPassword}/>
        </div>
          

        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={isDisabled}>
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default SignInForm
