import { Button, Spin } from 'antd'
import React, {useEffect, useMemo, useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { NOTIFICATION_TYPES, useNotification } from '../../hooks/useNotification'
import { blogApi } from '../../services'
import { ErrorNode } from '../../utils/ShowMessage'
import './index.scss'

export const ArticleView = () => {
  const { id } = useParams()
  const [getArticleById, articleRes] = blogApi.useLazyGetArticleByIdQuery()
  const [deleteArticle, deleteArticleRes] = blogApi.useDeleteArticleByIdMutation()

  const navigate = useNavigate()

  const [language, setLanguage] = useState('en')
  const onChangeLanguage = () => {
    const currentLanguage = 'en' === language ? 'fr' : 'en'
    setLanguage(currentLanguage)
  }

  const content = useMemo(() => {
    return articleRes?.data?.translations?.find((item) => item?.language === language)?.content;
  }, [articleRes.data, language])

  useNotification(NOTIFICATION_TYPES.error, articleRes.isError, articleRes.error as ErrorNode)

  useNotification(NOTIFICATION_TYPES.success, deleteArticleRes.isSuccess)
  useNotification(
    NOTIFICATION_TYPES.error,
    deleteArticleRes.isError,
    deleteArticleRes.error as ErrorNode
  )

  useEffect(() => {
    if (!deleteArticleRes.isSuccess) return
    navigate('/blog')
  }, [deleteArticleRes.isSuccess])

  useEffect(() => {
    if (id) getArticleById(id)
  }, [id])

  const handleBack = () => navigate(-1)
  const deleteArticleById = (id: number) => () => deleteArticle(id)
  const goToEditeMode = () => navigate('edit')

  const renderContent = () => (
    <div
      className='detail-l article-content'
      dangerouslySetInnerHTML={{ __html: content as string }}
    ></div>
  )

  return (
    <>
      <header className='sub-header'>
        <div className='sub-header_ct'>
          <div className='sub-header_buttons article-view-buttons'>
            <Button onClick={handleBack}>{id ? 'Cancel' : 'Back'}</Button>
            <div>
              <Button className="language-button" onClick={onChangeLanguage}>{language.toUpperCase()}</Button>
              <Button onClick={deleteArticleById(Number(id))}>Delete</Button>
              <Button onClick={goToEditeMode}>Edite</Button>
            </div>
          </div>
        </div>
      </header>
      {articleRes.isLoading ? <Spin>{renderContent()}</Spin> : renderContent()}
    </>
  )
}
