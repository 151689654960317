import StoreFormContainer from './StoreFormContainer'

import './styles.scss'

const StorePage: React.FC = () => {
  return (
    <main className="store-p">
      <StoreFormContainer />
    </main>
  )
}

export default StorePage
