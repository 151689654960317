import './styles.scss'
import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router'
import { Spin } from 'antd'
import { CountCard } from './CountCard'
import { InfoCard } from './InfoCard'
import { userApi } from '../../services'
import { getValidateData } from '../../utils/getValidateObj'


const UserDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate();
  const { data: user, isLoading, isError } = userApi.useGetUserDetailsQuery(id)
  const userFullName = `${getValidateData(user?.firstName)} ${getValidateData(
    user?.lastName
  )}`

  useEffect(() => {
    isError && navigate('/users')
  }, [isError])

  const userEmail = getValidateData(user?.email)
  const userPhoneNumber = getValidateData(user?.phoneNumber)
  const userBalance = getValidateData(user?.balance)
  const userPhonePlan = getValidateData(user?.phonePlan)
  const userCarrier = getValidateData(user?.carrier)
  const userBillingNumber = getValidateData(user?.billingNumber)
  const userLanguage = getValidateData(user?.language)

  const otherUserData = {
    'Clicks count': getValidateData(user?.clicksCount),
    'Transactions count': getValidateData(user?.transactionsCount),
    'Total transactions': getValidateData(user?.totalTransactionsAmount),
    'Total cash-back': getValidateData(user?.totalCashbackAmount),
  }
  type KeyOfOtherUserData = keyof typeof otherUserData
  const renderContent = (
    <main className="user-details">
      <div className="wrapper-user-details-info">
        <div className="user-details-info">
          <div className="user-details-info-header">
            <div className="user-details-info-name">{userFullName}</div>
            <div className="user-details-info-email">{userEmail}</div>
          </div>

          <InfoCard
            className="user-details-phone-number"
            body={userPhoneNumber}
            footer="Phone number"
          />

          <div className="wrapper-carrier-and-phonePlan">
            <InfoCard
              className="user-details-carrier"
              body={userCarrier}
              footer="Carrier"
            />
            <InfoCard
              className="user-details-phone-plan"
              body={userPhonePlan}
              footer="Phone plan"
            />
          </div>

          <div className="wrapper-current-balance">
            <div className="user-details-info-current-balance-title">
              Current balance
            </div>
            <div className="user-details-info-current-balance">
              $ {userBalance}
            </div>
          </div>

          <InfoCard
            className="user-details-billing-number"
            body={userBillingNumber}
            footer="Billing number"
          />
          <div className="current-balance-container">
            <div className="current-balance-label">
              Offers receive
            </div>
            <div className="chip1 active-border">
              <div
                className={
                  user?.acceptEmails
                    ? "chip-radio-active"
                    : "chip-radio"
                }
              />
              <span className="receive-email">
                Yes, please sign me up to recieve offers by email
              </span>
            </div>
          </div>
          <div className="user-details-info-language-container">
            <div className={`user-details-info-language-container-chip${userLanguage === 'en' ? '-active' : ''}`}>English</div>
            <div className={`user-details-info-language-container-chip${userLanguage === 'fr' ? '-active' : ''}`}>French</div>
          </div>
        </div>
      </div>

      <div className="user-cards">
        {Object.keys(otherUserData).map((key, idx) => {
          return <CountCard key={idx} body={otherUserData[key as KeyOfOtherUserData]} footer={key} />
        })}
      </div>
    </main>
  )

  return <>{isLoading ? <Spin>{renderContent}</Spin> : renderContent}</>
}

export default UserDetails
