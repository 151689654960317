/* eslint-disable */
import EventEmitter from 'eventemitter3'

export enum EmitterEvents {
  STORE_REMOTE_SUBMIT = 'STORE_REMOTE_SUBMIT',
  MODAL_FILTER_SUBMIT = 'MODAL_FILTER_SUBMI',
  MODAL_FILTER_CLEAR = 'MODAL_FILTER_CLEAR',
  MODAL_ADD_NEW_CASHBACK_SUBMIT = 'MODAL_ADD_NEW_CASHBACK_SUBMIT',

  SAVE_PREVIEW_ARTICLE_DATA = 'SAVE_PREVIEW_ARTICLE_DATA',
  SAVE_ARTICLE_DATA_CONTENT = 'SAVE_ARTICLE_DATA_CONTENT',

  SAVE_CONTENT_PAGE_DATA = 'SAVE_CONTENT_PAGE_DATA' ,
  CONTANT_PAGE_CHANGE_LANGUAGE = 'CONTANT_PAGE_CHANGE_LANGUAGE',
  CARRIER_REMOTE_SUBMIT = 'CARRIER_REMOTE_SUBMIT',

}

const eventEmitter = new EventEmitter()

const Emitter = {
  on: (event: string, fn: (value: any) => void) => eventEmitter.on(event, fn),
  once: (event: string, fn: () => void) => eventEmitter.once(event, fn),
  off: (event: string, fn?: () => void) => eventEmitter.off(event, fn),
  emit: (event: string, fn?: (value: any) => void) => eventEmitter.emit(event, fn),
}

Object.freeze(Emitter)

export default Emitter
