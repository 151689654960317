import { createApi } from '@reduxjs/toolkit/query/react'
import { IArticle, IBlogRedux } from '../models/IBlog'
import { TicketsPageConfig } from '../models/ITickets'
import { baseQueryWithReauth } from '../utils/baseQueryWithReauth'

export const BLOG_API_REDUCER_KEY = 'blogApi'
export const blogApi = createApi({
  reducerPath: BLOG_API_REDUCER_KEY,
  baseQuery: baseQueryWithReauth,
  tagTypes: ['ArticleList','Article'],
  endpoints: (builder) => ({
    getArticleList: builder.query<IBlogRedux, TicketsPageConfig>({
      query: (tableConfig) => ({
        url: '/admin/blog-posts',
        params: {
          ...tableConfig,
        },
      }),
      providesTags: () => ['ArticleList'],
    }),
    createArticle: builder.mutation<IBlogRedux, FormData>({
      query: (body: FormData) => ({
        url: '/admin/blog-posts',
        method: 'POST',
        body,
      }),
    }),

    saveArticlePhoto: builder.mutation<IBlogRedux, FormData>({
      query: (body: FormData) => ({
        url: '/admin/blog-images',
        method: 'POST',
        body,
      }),
    }),

    editArticle: builder.mutation<IBlogRedux, {id:string,body:FormData}>({
      query: ({id,body}) => ({
        url: `/admin/blog-posts/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['ArticleList']
    }),

    getArticleById: builder.query<IArticle, string>({
      query: (id) => `/admin/blog-posts/${id}`
    }),

    deleteArticleById: builder.mutation<IArticle, number>({
      query: (id) => ({
        url: `/admin/blog-posts/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['ArticleList', 'Article'],
    }),
  }),
})
