import {
  Form,
  FormInstance,
  Input,
  DatePicker,
  Select,
  AutoComplete,
} from 'antd'
import React, { createRef, useState } from 'react'
import { storesApi } from '../../../../services'
import useSearch from '../../../../hooks/useSearch'
import SVG from '../../../../assets/images/SVG'
import { IStore } from '../../../../models'
const { Option } = Select
const { Option: AutoOption } = AutoComplete
import './styles.scss'

export interface ModalConfig {
  page?: number | undefined;
  limit?: number;
  searchStore: string;
}

const FilterMissingTransactionsPage: React.FC = () => {
  const form = Form.useFormInstance()

  const [tableConfig, setTableConfig] = useState<ModalConfig>({
    page: 1,
    limit: 1000,
    searchStore: '',
  })
  const formRef = createRef<FormInstance>()
  const { data } = storesApi.useFetchAllStoresQuery(tableConfig)

  const { handleSearchChange } = useSearch(setTableConfig)

  /* eslint-disable-next-line */
  // @ts-ignore
  const onValuesChange = (e) => {
    if (e.storeId) {
      form.setFieldsValue({
        /* eslint-disable-next-line */
        // @ts-ignore
        storeId: e.storeId,
      })
    }
  }

  return (
    <div className="filter-container">
      <Form
        form={form}
        ref={formRef}
        autoComplete="off"
        className="store-f"
        initialValues={{
          storeId: '',
          minPurchaseDate: '',
          maxPurchaseDate: '',
          purchaseType: '',
          paymentMethod: '',
          minAmount: '',
          maxAmount: '',
        }}
        onValuesChange={onValuesChange}
      >
        <div className="form-row">
          <Form.Item className="item-half" name="storeId" label="Store Id">
            <AutoComplete
              placeholder="Search by store name"
              className="form-filter-input"
              // value={select}
              onChange={
                // console.log(e)

                handleSearchChange
              }
              /* eslint-disable-next-line */
              // @ts-ignore

              // value={select}
            >
              {data?.items.map((store: IStore) => (
                <AutoOption key={store.storeTitle} value={store.id}>
                  {store.storeTitle}
                </AutoOption>
              ))}
            </AutoComplete>
          </Form.Item>
        </div>
        <div className="form-row filter-row">
          <Form.Item name="minPurchaseDate" label="Date" className="item-half">
            <DatePicker
              placeholder="From"
              suffixIcon={<SVG.CalendarIcon />}
              className="form-filter-datapicker"
              format="YYYY-MM-DD"
            />
          </Form.Item>
          <Form.Item name="maxPurchaseDate" className="item-half">
            <DatePicker
              placeholder="To"
              suffixIcon={<SVG.CalendarIcon />}
              className="form-filter-datapicker"
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </div>

        <div className="form-row filter-row">
          <Form.Item
            name="purchaseType"
            label="Purchase type"
            className="input-big item-half"
          >
            <Select>
              <Option value="mobile">Mobile</Option>
              <Option value="desktop">Desktop</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="paymentMethod"
            label="Payment method"
            className="input-big item-half"
          >
            <Select>
              <Option value="credit card">Credit Card</Option>
              <Option value="debit card">Debit Card</Option>
              <Option value="installment payments">Installment Payments</Option>
            </Select>
          </Form.Item>
        </div>

        <div className="form-row filter-row">
          <Form.Item
            name="minAmount"
            label="Order amount"
            className="item-half"
          >
            <Input
              type="number"
              placeholder="From"
              className="form-filter-input"
            />
          </Form.Item>
          <Form.Item name="maxAmount" className="item-half">
            <Input
              type="number"
              className="form-filter-input"
              placeholder="To"
            />
          </Form.Item>
        </div>

        {/* <div className="form-row filter-row">
          <Form.Item name="minSaleAmount" label="Sale" className="item-half">
            <Input
              type="number"
              placeholder="To"
              className="form-filter-input"
            />
          </Form.Item>
          <Form.Item name="maxSaleAmount" className="item-half">
            <Input
              type="number"
              placeholder="No max"
              className="form-filter-input"
            />
          </Form.Item>
        </div>

        <div className="form-row filter-row">
          <Form.Item
            name="minWithdrawalDate"
            label="Cash-back date & time"
            className="item-half"
          >
            <DatePicker
              placeholder="From"
              suffixIcon={<SVG.CalendarIcon />}
              className="form-filter-datapicker"
              format="YYYY-MM-DD"
            />
          </Form.Item>
          <Form.Item name="maxWithdrawalDate" className="item-half">
            <DatePicker
              placeholder="To"
              suffixIcon={<SVG.CalendarIcon />}
              className="form-filter-datapicker"
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </div> */}
      </Form>
    </div>
  )
}

export default FilterMissingTransactionsPage
