import { createApi } from '@reduxjs/toolkit/query/react'
import {
  IBookmarks,
  ICarrier,
  ICarrierById,
  ICarrierCashbackRedux,
  ICarrierClicksRedux,
  ICarrierItemFromApi,
  ICarrierRedux,
  ICarrierTitles,
} from '../models/ICarrier'
import { IPageConf } from '../utils/constants'
import { TableConfig } from '../pages/Stores/StoresPage'
import { baseQueryWithReauth } from '../utils/baseQueryWithReauth'

export const CARRIERS_API_REDUCER_KEY = 'carriersApi'
export const carriersApi = createApi({
  reducerPath: CARRIERS_API_REDUCER_KEY,
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Carriers', 'Carrier'],
  endpoints: (builder) => ({
    fetchAllCarriers: builder.query<ICarrierRedux, TableConfig>({
      query: (config) => ({
        url: '/admin/carriers',
        params: {
          ...config,
        },
      }),
      providesTags: () => ['Carriers'],
    }),
    fetchCarrier: builder.query<ICarrierById, string | undefined>({
      query: (id) => ({
        url: `/admin/carriers/${id}`,
      }),
      transformResponse: (response: ICarrierById): ICarrierById => {
        const tr = response.translations.reduce((acc, res) => {
          const { code } = res.language
          return {
            ...acc,
            [code]: res,
          }
        }, {})
        return { ...response, tr }
      },
      providesTags: () => ['Carrier'],
    }),
    createCarrier: builder.mutation<ICarrier, FormData>({
      query: (body: FormData) => ({
        url: '/admin/carriers',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['Carriers'],
    }),
    updateCarrier: builder.mutation<ICarrier, { id: number | string | undefined; fData: FormData }>(
      {
        query: ({ id, fData }) => {
          return {
            url: `/admin/carriers/${id}`,
            method: 'PATCH',
            body: fData,
          }
        },
        invalidatesTags: () => ['Carriers', 'Carrier'],
      }
    ),
    deleteCarrier: builder.mutation<ICarrier, number>({
      query: (id: number) => ({
        url: `/admin/carriers/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Carriers'],
    }),
    getAllCarriersFromApi: builder.query<ICarrierItemFromApi[], void>({
      query: () => ({
        url: '/admin/carriers/psi-comm',
      }),
    }),
  }),
})

export const CARRIER_API_REDUCER_KEY = 'carrierApi'
export const carrierApi = createApi({
  reducerPath: CARRIER_API_REDUCER_KEY,
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Carrier'],
  endpoints: (builder) => ({
    getCarrierCashBack: builder.query<
      ICarrierCashbackRedux,
      {
        id: string | undefined
        pageConf: IPageConf
      }
    >({
      query: ({ id, pageConf }) => ({
        url: `/admin/carriers/${id}/cashback`,
        params: {
          ...pageConf,
        },
      }),
      providesTags: () => ['Carrier'],
    }),
    getCarrierClicks: builder.query<
      ICarrierClicksRedux,
      { tableConfig: TableConfig; id: string | undefined }
    >({
      query: ({ id, tableConfig }) => ({
        url: `/admin/carriers/${id}/clicks`,
        params: {
          ...tableConfig,
        },
      }),
      providesTags: () => ['Carrier'],
    }),
    getBookmarks: builder.query<IBookmarks, void>({
      query: () => ({
        url: '/admin/carriers/bookmarks',
      }),
    }),
    getExistingCarrierTitles: builder.query<ICarrierTitles, void>({
      query: () => ({
        url: '/admin/carriers/titles',
      }),
    }),
  }),
})
