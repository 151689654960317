// images
import SVG from '../../../assets/images/SVG'

import './styles.scss'

const SignInHeader = () => (
  <header className="sign-in-header">
    <div className="sign-in-header_ct">
      <SVG.LogoIcon />
    </div>
  </header>
)

export default SignInHeader
