import SVG from '../../assets/images/SVG'
import { Button, Input } from 'antd'

import './styles.scss'

interface Props {
  onChange: React.ChangeEventHandler<HTMLInputElement>
  children?: React.ReactNode
  onOpenModal?: React.MouseEventHandler<HTMLElement>
  placeholder?: string
  isShowFilter?: boolean
}

const FilterBlock: React.FC<Props> = ({
  onChange,
  children,
  onOpenModal,
  placeholder = '',
  isShowFilter = true,
}) => {
  return (
    <div className='filter-block'>
      <div className='wrapper-search-and-filter-btn'>
        <Input onChange={onChange} placeholder={placeholder} prefix={<SVG.LoupeIcon />} />
        {isShowFilter && (
          <Button onClick={onOpenModal} icon={<SVG.FilterIcon />}>
            Filter
          </Button>
        )}
      </div>
      {children}
    </div>
  )
}

export default FilterBlock
