import { useEffect, useState } from 'react'
import { storesApi } from '../../../services'
import { ShowMessage } from '../../../utils'
import * as I from '../../../models/ISpecialOffers'
import { SpecialOfferList } from './SpecialOfferList'

const SpecialOfferListContainer: React.FC<I.ISpecialOfferListContainer> = ({
  specialOffers = [],
  type,
}) => {
  const [newSpecialOffers, setNewSpecialOffers] = useState<I.ISpecialOffers[] | []>([])

  const [createSpecialOffer, { isSuccess: isSCSpecialOfferCreated }] =
    storesApi.useCreateSpecialOfferMutation()
  const [updateSpecialOffer, { isSuccess: isSCSpecialOfferUpdate }] =
    storesApi.useUpdateSpecialOfferMutation()
  const [deleteSpecialOffer, { isSuccess: isSCSpecialOfferDelete }] =
    storesApi.useDeleteSpecialOfferMutation()

  useEffect(() => {
    if (!isSCSpecialOfferDelete) return
    ShowMessage('success', 'Special Offer deleted')
  }, [isSCSpecialOfferDelete])

  useEffect(() => {
    if (!isSCSpecialOfferUpdate) return
    ShowMessage('success', 'Special Offer updated')
  }, [isSCSpecialOfferUpdate])

  useEffect(() => {
    if (!isSCSpecialOfferCreated) return
    ShowMessage('success', 'Special Offer created')
    setNewSpecialOffers([])
  }, [isSCSpecialOfferCreated])

  const onAddNewOffer = () => {
    const id = newSpecialOffers.length + 1
    const newOffer: I.ISpecialOffers = {
      id,
      link: '',
      image: '',
      translations: {
        fr: {
          title: '',
          description: '',
        },
        en: {
          title: '',
          description: '',
        },
      },
    }
    setNewSpecialOffers((pre) => [...pre, newOffer])
  }

  const onUpdateOffer = ({ id, offer, offerId }: I.IOnFormSubmit): void => {
    const { imageEn, imageFr, ...rest } = offer

    const fData = new FormData()
    fData.append('specialOffer', JSON.stringify(rest))
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    imageEn && fData.append('imageEn', imageEn.file.originFileObj!)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    imageFr && fData.append('imageFr', imageFr.file.originFileObj!)

    void updateSpecialOffer({ id, offerId, offer: fData })
  }
  const onCreateOffer = ({ id, offer }: I.IOnFormSubmit): void => {
    const { imageEn, imageFr, ...rest } = offer
    const fData = new FormData()
    fData.append('specialOffer', JSON.stringify(rest))
    /* eslint-disable-next-line */
    // @ts-ignore
    fData.append('imageEn', imageEn.file.originFileObj!)
    /* eslint-disable-next-line */
    // @ts-ignore
    fData.append('imageFr', imageFr.file.originFileObj!)

    createSpecialOffer({ id, offer: fData })
  }
  const onDeleteNewOffer = ({ offerId }: I.IDeleteSpecialOfferProps): void =>
    void setNewSpecialOffers((pre) => pre.filter((offer) => offer.id != offerId))
  const onDeleteExistOffer = ({ id, offerId }: I.IDeleteSpecialOfferProps): void =>
    void deleteSpecialOffer({ id, offerId })

  const propsSpecialForm = {
    type,
    setNewSpecialOffers,
  }

  const propsSpecialOfferList: I.ISpecialOfferListProps = {
    propsSpecialForm,
    newSpecialOffers,
    onUpdateOffer,
    onCreateOffer,
    onDeleteNewOffer,
    onDeleteExistOffer,
    specialOffers,
    onAddNewOffer,
  }

  return <SpecialOfferList {...propsSpecialOfferList} />
}

export default SpecialOfferListContainer
