import { Form, Input } from 'antd'
import StoreUpload from '../Store/StoreUpload'
import TextArea from 'antd/lib/input/TextArea'

interface IProps {
  previewImg?: string
  language: 'en' | 'fr'
}

export const FormPreviewArticle: React.FC<IProps> = ({ previewImg, language }) => {
  return (
    <div className='wrapper-form-preview-article'>
      <div>
        <StoreUpload type='logoImage' previewImg={previewImg} />
      </div>
      <div className='content'>
        <Form.Item
          name={[language, 'title']}
          className='input-big item-half'
          rules={[{ required: true, message: 'Missing title' }]}
        >
          <Input placeholder='Title' />
        </Form.Item>

        <Form.Item
          name='readTime'
          className='input-big item-half'
          rules={[{ required: true, message: 'Missing readTime' }]}
        >
          <Input placeholder='Read time' />
        </Form.Item>

        <Form.Item className='input-big item-half' name={[language, 'seoTitle']}>
          <Input placeholder='SEO Title' />
        </Form.Item>

        <Form.Item className='input-big item-half' name={[language, 'seoDescription']}>
          <TextArea placeholder='SEO Description' />
        </Form.Item>

        <Form.Item className='input-big item-half' name={[language, 'seoKeywords']}>
          <Input placeholder='SEO Keywords' />
        </Form.Item>
      </div>
      <div className='wrapper-description'>
        <Form.Item
          name={[language, 'description']}
          className='input-big item-half'
          rules={[{ required: true, message: 'Missing description' }]}
        >
          <TextArea placeholder='Description' />
        </Form.Item>
        <Form.Item className='input-big item-half' name={'slag'}>
          <Input placeholder='Slug' />
        </Form.Item>
      </div>
    </div>
  )
}
