import { Dispatch, SetStateAction } from 'react'
import { TableOrder, TableOrderType } from '../utils/constants'
import { TableProps } from 'antd/lib/table'
import { SorterResult } from 'antd/lib/table/interface'
import useSearch from './useSearch'

export interface TableConfig {
  page?: number | undefined
  limit?: number
  order?: TableOrderType
  search?: string
  isClear?: boolean
  language?: string
}

interface IProps {
  setTableConfig: Dispatch<SetStateAction<TableConfig>>
  nameSearchQuery?: string
}

export const useTableSearchSortPagination = <T>({ setTableConfig, nameSearchQuery }: IProps) => {
  const onChangeTable: TableProps<T>['onChange'] = (pagination, filters, sorter, extra) => {
    const { action } = extra
    if (action === 'paginate') {
      setTableConfig((prev) => ({ ...prev, page: pagination.current }))
    }
    if (action === 'sort') {
      const { order, field } = sorter as SorterResult<T>
      setTableConfig((prev) => ({
        ...prev,
        ...(order && { order: TableOrder[order] }),
        sortingKey: field,
      }))
    }
  }

  const { handleSearchChange } = useSearch(setTableConfig, nameSearchQuery)

  return { handleSearchChange, onChangeTable }
}
