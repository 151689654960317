import { createApi } from '@reduxjs/toolkit/query/react'
import { TableConfig } from '../hooks/useTableSearchSortPagination'
import { ICashbackRedux, IAddNewCashback } from '../models/ICashback'
import { baseQueryWithReauth } from '../utils/baseQueryWithReauth'

export const CASHBACK_API_REDUCER_KEY = 'cashbackApi'
export const cashbackApi = createApi({
  reducerPath: CASHBACK_API_REDUCER_KEY,
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Cashback'],
  endpoints: (builder) => ({
    getCashbackList: builder.query<ICashbackRedux, TableConfig>({
      query: (tableConfig) => ({
        url: '/admin/cashback',
        params: {
          ...tableConfig,
        },
      }),
      providesTags: () => ['Cashback'],
    }),

    createCashback: builder.mutation<IAddNewCashback, FormData>({
      query: (body: FormData) => ({
        url: '/admin/cashback',
        method: 'POST',
        body,
      }),
    }),
  }),
})
