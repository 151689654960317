import * as I from '../../../models/ICarrierFixedValues'
import { CarrierFixedValueList } from './CarrierFixedValueList'

const CarrierFixedValueListContainer: React.FC<I.ICarrierFixedValueListContainer> = ({
  carrierFixedValuesAllList,
  carrierFixedValuesPublished,
  refetchPublishedTopUpsList,
}) => {
  return (
    <CarrierFixedValueList
      carrierFixedValuesAllList={carrierFixedValuesAllList}
      carrierFixedValuesPublished={carrierFixedValuesPublished}
      refetchPublishedTopUpsList={refetchPublishedTopUpsList}
    />
  )
}

export default CarrierFixedValueListContainer
