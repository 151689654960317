import { Form, FormInstance, Input, DatePicker } from 'antd'
import React, { createRef } from 'react'
import SVG from '../../../../assets/images/SVG'

import './styles.scss'

const FilterWithdrawalsPage: React.FC = () => {
  const form = Form.useFormInstance()
  const formRef = createRef<FormInstance>()

  return (
    <div className="filter-container">
      <Form
        form={form}
        ref={formRef}
        autoComplete="off"
        className="store-f"
        initialValues={{
          minTotalAmount: '',
          maxTotalAmount: '',
          minAmount: '',
          maxAmount: '',
          minWithdrawalDate: '',
          maxWithdrawalDate: '',
        }}
      >
        <div className="form-row filter-row">
          <Form.Item
            name="minAmount"
            label="Last withdrawal"
            className="item-half"
          >
            <Input
              type="number"
              placeholder="No min"
              className="form-filter-input"
            />
          </Form.Item>
          <Form.Item name="maxAmount" className="item-half">
            <Input
              type="number"
              placeholder="No max"
              className="form-filter-input"
            />
          </Form.Item>
        </div>

        <div className="form-row filter-row">
          <Form.Item
            name="minWithdrawalDate"
            label="Date & time"
            className="item-half"
          >
            <DatePicker
              placeholder="From"
              suffixIcon={<SVG.CalendarIcon />}
              className="form-filter-datapicker"
              format="YYYY-MM-DD"
            />
          </Form.Item>
          <Form.Item name="maxWithdrawalDate" className="item-half">
            <DatePicker
              placeholder="To"
              suffixIcon={<SVG.CalendarIcon />}
              className="form-filter-datapicker"
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </div>

        <div className="form-row filter-row">
          <Form.Item
            name="minTotalAmount"
            label="Total withdrawals"
            className="item-half"
          >
            <Input
              type="number"
              placeholder="No min"
              className="form-filter-input"
            />
          </Form.Item>
          <Form.Item name="maxTotalAmount" className="item-half">
            <Input
              type="number"
              placeholder="No max"
              className="form-filter-input"
            />
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default FilterWithdrawalsPage
