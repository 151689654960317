import { Form } from 'antd'
import * as I from '../../../models/ISpecialOffers'
import { createRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FormInstance } from 'antd/es/form/Form'
import { SpecialOfferForm } from './SpecialOfferForm'
import { ISpecialFormValue } from '../../../models/ISpecialOffers'

const SpecialOfferFormContainer: React.FC<I.ISpecialOfferFormContainerProps> = ({
  offer,
  type,
  isCreateOffer = false,
  onDeleteOffer,
  onFormSubmit,
}) => {
  const [form] = Form.useForm()
  const specialFormRef = createRef<FormInstance>()
  const { id: idStr } = useParams()
  const id = Number(idStr)
  const [image, setImage] = useState({ en: { image: '' }, fr: { image: '' } })
  const [formValue, setFormValue] = useState<I.ISpecialFormValue>({
    offerId: offer?.id,
    link: offer?.link,
    image: offer?.image,
    fr: {
      title: offer?.translations['fr']?.title,
      description: offer?.translations['fr']?.description,
      language: {
        code: 'fr',
      },
    },
    en: {
      title: offer?.translations['en']?.title,
      description: offer?.translations['en']?.description,
      language: {
        code: 'en',
      },
    },
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onFinish = (value: ISpecialFormValue) => {
    const { offerId } = formValue
    const offer: I.ICreateSpecialOffer = {
      link: formValue.link,
      imageEn: image.en.image,
      imageFr: image.fr.image,
      translations: [formValue.fr, formValue.en],
    }
    onFormSubmit({ id, offerId, offer })
  }

  const onSetTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValue((pre) => ({
      ...pre,
      [type]: { ...pre[type], title: e.target.value },
    }))
  }
  const onSetDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValue((pre) => ({
      ...pre,
      [type]: { ...pre[type], description: e.target.value },
    }))
  }
  const specialOfferProps: I.ISpecialOfferFormProps = {
    offer,
    type,
    isCreateOffer,
    onDeleteOffer,
    onSetTitle,
    onSetDescription,
    formValue,
    setFormValue,
    form,
    specialFormRef,
    id,
    onFinish,
    onFormSubmit,
    setImage,
    image,
  }

  return <SpecialOfferForm {...specialOfferProps} />
}
export default SpecialOfferFormContainer
