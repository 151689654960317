import React from 'react'
import { Outlet } from 'react-router'

import SubHeader from '../../../components/SubHeader'

import '../styles.scss'
import { ELayoutVariants } from '../../../utils/enums'

interface IProps {
  variant: ELayoutVariants
}

const DetailLayout: React.FC<IProps> = ({ variant }) => (
  <React.Fragment>
    <SubHeader variant={variant} />
    <div className='detail-l'>
      <Outlet />
    </div>
  </React.Fragment>
)

export default DetailLayout
