interface ILanguages {
  [key: string]: {
    code: 'en'|'fr',
    title: string,
  };
}

export interface IPageConf{
  page: number,
  limit: number,  
  searchOrder: string,
}

export const LANGUAGES: ILanguages = {
  en: {
    code: 'en',
    title: 'English',
  },
  fr: {
    code: 'fr',
    title: 'French',
  },
}


export type TableOrderType = 'ASC' | 'DESC'
export type SetState<T> = React.Dispatch<React.SetStateAction<T>>

export enum TableOrder {
  ascend = 'ASC',
  descend = 'DESC'
}

export enum MODALS {
  storeFilter = 'storesFilter',
  usersFilter = 'usersFilter',
  userFilterWithdrawals = 'userFilterWithdrawals',
  userFilterClicks = 'userFilterClicks',
  userFilterCashback = 'userFilterCashback',
  storeFilterCashback='storeFilterCashback',
  storeFilterClicks = 'storeFilterClicks',
  cashbackFilter='cashbackFilter',
  withdrawalsFilter='withdrawalsFilter',
  clicksFilter='clicksFilter',
  missingTransaction = 'missingTransactionFilter',
  addNewCashback='addNewCashback',
  carrierFilter = 'carriersFilter',
  carrierFilterCashback='carrierFilterCashback',
  carrierFilterClicks = 'carrierFilterClicks',
}

export const dataPickerKeyArr = [
  'minWithdrawalDate',
  'maxWithdrawalDate',
  'maxClickDate',
  'minClickDate',
  'minTransactionDate',
  'maxTransactionDate',
]
