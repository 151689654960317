import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ColumnsType } from 'antd/lib/table'
import { IStore } from '../../models/IStore'
import BucketAction from '../../components/BucketAction'
import { Status } from './StoresPage'
import SVG from '../../assets/images/SVG'
import { storesApi } from '../../services'
import { ShowMessage } from '../../utils'

interface IProps {
  onChangeCurrentPage: () => void;
}

const useColumn = ({ onChangeCurrentPage }: IProps) => {
  const [updateStore, { isSuccess }] = storesApi.useUpdateStoreMutation()

  useEffect(() => {
    if (!isSuccess) return
    ShowMessage('success', 'Store deleted from favorite position')
  }, [isSuccess])

  const deleteFavoritePositionStor = (id: number) => {
    const fData = new FormData()
    fData.append('store', JSON.stringify({ favoritesPosition: null }))
    updateStore({ id, fData })
  }

  const columns: ColumnsType<IStore> = [
    {
      title: 'Store',
      dataIndex: 'storeTitle',
      defaultSortOrder: 'descend',
      sorter: {},
      fixed: 'left',
      width: 50,
      sortDirections: ['descend', 'ascend'],
      render: (name: string, { id, favoritesPosition }: IStore) => (
        <>
          <Link to={`/stores/${id}`}>{name}</Link>
          {favoritesPosition && (
            <div
              className="store-wrapper-star"
              onClick={() => deleteFavoritePositionStor(id)}
            >
              <SVG.StarIcon />
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Affiliate link',
      dataIndex: 'link',
      defaultSortOrder: 'descend',
      sorter: {},
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Affiliate network',
      dataIndex: 'affiliateTitle',
      defaultSortOrder: 'descend',
      sorter: {},
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Paid cash-back',
      dataIndex: 'paidCashback',
      defaultSortOrder: 'descend',
      sorter: {},
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Status',
      dataIndex: 'isPublished',
      defaultSortOrder: 'descend',
      sorter: {},
      fixed: 'right',
      sortDirections: ['descend', 'ascend'],
      render: (status: IStore) => (
        <>
          {status}
          <div>{status ? Status.published : Status.unpublished}</div>
          {/* <BucketAction id={id} onChangeCurrentPage={onChangeCurrentPage} /> */}
        </>
      ),
    },
    {
      title: 'Store id',
      dataIndex: 'id',
      defaultSortOrder: 'descend',
      sorter: {},
      sortDirections: ['descend', 'ascend'],
      fixed: 'right',
      render: (status, { id }: IStore) => (
        <React.Fragment>
          <div>{+id}</div>
          <BucketAction id={id} onChangeCurrentPage={onChangeCurrentPage} />
        </React.Fragment>
      ),
    },
  ]
  return { columns }
}
export default useColumn
