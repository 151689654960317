import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { IAuthBody, ITokens } from '../models/IAuth'
import { apiHost } from '../utils/configEnv'

export const AUTH_API_REDUCER_KEY = 'authApi'
export const authApi = createApi({
  reducerPath: AUTH_API_REDUCER_KEY,
  baseQuery: fetchBaseQuery({
    baseUrl: apiHost
  }),
  tagTypes: ['Auth'],
  endpoints: (builder) => ({
    login: builder.mutation<ITokens, IAuthBody>({
      query: (body: IAuthBody) => ({
        url: '/admin/auth/signIn',
        method: 'POST',
        body,
      }),
    }),
  }),
})
