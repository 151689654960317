import { Select, Form, FormInstance, Input } from 'antd'
import React, { createRef } from 'react'

import './styles.scss'

const { Option } = Select

const StoreFilter: React.FC = () => {
  const form = Form.useFormInstance()
  const formRef = createRef<FormInstance>()

  return (
    <div className="filter-container">
      <Form
        form={form}
        ref={formRef}
        // onFinish={onFinish}
        autoComplete="off"
        className="store-f"
        initialValues={{
          affiliateTitle: '',
          minCashback: '',
          maxCashback: '',
          isPublished: '',
        }}
      >
        <div className="form-row">
          <Form.Item
            name="affiliateTitle"
            label="Affiliate Network"
            className="input-big item-half"
          >
            <Select>
              <Option value="CJ">CJ</Option>
              <Option value="Rakuten">Rakuten</Option>
              <Option value="Impact">Impact</Option>
            </Select>
          </Form.Item>
        </div>
        <div className="form-row filter-row">
          <Form.Item
            name="minCashback"
            label="Paid cash-back"
            className="item-half"
          >
            <Input
              type="number"
              placeholder="No min"
              className="form-filter-input"
            />
          </Form.Item>
          <Form.Item className="item-half" name="maxCashback">
            <Input
              type="number"
              placeholder="No max"
              className="form-filter-input"
            />
          </Form.Item>
        </div>
        <div className="form-row">
          <Form.Item
            name="isPublished"
            label="Status"
            className="input-big item-half"
          >
            <Select value="">
              <Option value="true">Published</Option>
              <Option value="false">Unpublished</Option>
            </Select>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default StoreFilter
