import { Button, Tabs } from 'antd'
import { useState } from 'react'
import { PageWithEditor } from './PageWithEditor'
import { ContentPageTabsEnum } from '../../../models/ContentPage'
import Emitter, { EmitterEvents } from '../../../utils/eventEmitter'

interface IOperations {
  onChangeLanguage: () => void
  language: string
}

export const Operations = ({ onChangeLanguage, language }: IOperations) => {
  const onSave = () => {
    Emitter.emit(EmitterEvents.SAVE_CONTENT_PAGE_DATA)
  }

  return (
    <div style={{ display: 'flex', gridGap: 10 }}>
      <Button className='btn-save' onClick={onSave}>
        Save
      </Button>

      <Button onClick={onChangeLanguage}>{language}</Button>
    </div>
  )
}

export const ContentPageTabs = () => {
  const [activeTab, setActiveTab] = useState(ContentPageTabsEnum.CONTACT_US)
  const [language, setLanguage] = useState('EN')

  const onChangeLanguage = () => {
    const currentLanguage = 'EN' === language ? 'FR' : 'EN'
    setLanguage(currentLanguage)
  }

  const items = [
    {
      label: 'Contact us',
      key: ContentPageTabsEnum.CONTACT_US,
      children: (
        <PageWithEditor
          activeTab={activeTab}
          tab={ContentPageTabsEnum.CONTACT_US}
          type={'contact_us'}
          language={language}
        />
      ),
    },
    {
      label: 'FAQ',
      key: ContentPageTabsEnum.FAQ,
      children: (
        <PageWithEditor
          activeTab={activeTab}
          tab={ContentPageTabsEnum.FAQ}
          type={'faq'}
          language={language}
        />
      ),
    },
    {
      label: 'How it works',
      key: ContentPageTabsEnum.HOW_IT_WORKS,
      children: (
        <PageWithEditor
          activeTab={activeTab}
          tab={ContentPageTabsEnum.HOW_IT_WORKS}
          type={'how_it_works'}
          language={language}
        />
      ),
    },
    {
      label: 'Terms and Conditions',
      key: ContentPageTabsEnum.TERMS_AND_CONDITIONS,
      children: (
        <PageWithEditor
          activeTab={activeTab}
          tab={ContentPageTabsEnum.TERMS_AND_CONDITIONS}
          type={'terms_and_conditions'}
          language={language}
        />
      ),
    },
    {
      label: 'Privacy Policy',
      key: ContentPageTabsEnum.PRIVACY_POLICY,
      children: (
        <PageWithEditor
          activeTab={activeTab}
          tab={ContentPageTabsEnum.PRIVACY_POLICY}
          type={'privacy_policy'}
          language={language}
        />
      ),
    },
    {
      label: 'Telecoms',
      key: ContentPageTabsEnum.TELECOMS,
      children: (
        <PageWithEditor
          activeTab={activeTab}
          tab={ContentPageTabsEnum.TELECOMS}
          type={'telecoms'}
          language={language}
        />
      ),
    },
  ]

  return (
    <Tabs
      tabBarExtraContent={<Operations onChangeLanguage={onChangeLanguage} language={language} />}
      items={items}
      activeKey={activeTab}
      onChange={(activeTab) => setActiveTab(activeTab as ContentPageTabsEnum)}
    />
  )
}
