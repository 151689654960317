import React, { useContext, useState } from 'react'
import { Table } from 'antd'
import FilterBlock from '../../components/FilterBlock'
import './styles.scss'
import '../styles.scss'
import { TableConfig, useTableSearchSortPagination } from '../../hooks/useTableSearchSortPagination'
import { MODALS, TableOrder } from '../../utils/constants'
import { ModalContext } from '../../components/Modal/modalProvider'
import useEmitterFilterSubmit from '../../hooks/useEmitterFilterSubmit'
import {  clicksApi } from '../../services'
import useEmitterClearForFilter from '../../hooks/useEmitterClearForFilter'
import { IClicks,IClicksRedux } from '../../models/IClicks'
import { columns } from './columns'

const ClicksPage = () => {
  const [tableConfig, setTableConfig] = useState<TableConfig>({
    page: 1,
    limit: 10,
    order: TableOrder.descend,    
  })

  const {handleSearchChange,onChangeTable}=useTableSearchSortPagination<IClicks>({setTableConfig,nameSearchQuery:'searchClick'})
  const {data = {count:0}, isLoading} = clicksApi.useGetClicksListQuery(tableConfig)
  
  useEmitterFilterSubmit(setTableConfig)
  useEmitterClearForFilter(setTableConfig)
  
  const { onOpen } = useContext(ModalContext)
  const onOpenModal = () => onOpen(MODALS.clicksFilter)

  const onShowSizeChange=(current:number, size:number)=>{    
    setTableConfig((pre)=>({...pre,
    page:current,
    limit:size
    }))
  }

  return (
    <main className="stores-p">
    <FilterBlock onChange={handleSearchChange} onOpenModal={onOpenModal} placeholder="Search clicks"/>
    <Table
    className='clicks-table'
      loading={isLoading}
      columns={columns}
      dataSource={(data as IClicksRedux).items}
      onChange={onChangeTable}
      pagination={{ 
        position: ['bottomCenter'],
        total: data?.count,
        current:tableConfig.page,        
        onShowSizeChange,        
     }}
    />
  </main>
  )
}

export default ClicksPage
