import React,{ useContext, useState,useEffect } from 'react'
import { Table } from 'antd'
import './styles.scss'
import FilterBlock from '../../components/FilterBlock'
import '../styles.scss'
import {
  TableConfig,
  useTableSearchSortPagination,
} from '../../hooks/useTableSearchSortPagination'
import { ShowMessage } from '../../utils'
import { MODALS, TableOrder } from '../../utils/constants'
import { ModalContext } from '../../components/Modal/modalProvider'
import useEmitterFilterSubmit from '../../hooks/useEmitterFilterSubmit'
import Emitter,{EmitterEvents} from '../../utils/eventEmitter'
import { cashbackApi } from '../../services'
import { ICashback, ICashbackRedux } from '../../models/ICashback'
import useEmitterClearForFilter from '../../hooks/useEmitterClearForFilter'
import { columns } from './columns'

const CashbackPage = () => {
  const [tableConfig, setTableConfig] = useState<TableConfig>({
    page: 1,
    limit: 10,
    order: TableOrder.descend,
  })

  const { handleSearchChange, onChangeTable } =
    useTableSearchSortPagination<ICashback>({
      setTableConfig,
      nameSearchQuery: 'searchCashback',
    })

  const { data={count:0}, isLoading } = cashbackApi.useGetCashbackListQuery(    
    tableConfig,
  )
  const [createCashback] = cashbackApi.useCreateCashbackMutation()


  useEmitterFilterSubmit(setTableConfig)
  useEmitterClearForFilter(setTableConfig)

  useEffect( () => {
    Emitter.on(EmitterEvents.MODAL_ADD_NEW_CASHBACK_SUBMIT, async (value) => {
     const result = await createCashback(value)
  
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
      if(result.error.status===400&&result.error.data.message){
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
        result.error.data.message.forEach((element:string) => {
              ShowMessage('error',element)
            })
      }
      else  ShowMessage('success','New cash-back created')
      
    })
    return () => {
      Emitter.off(EmitterEvents.MODAL_ADD_NEW_CASHBACK_SUBMIT)
    }
  }, [])



  const { onOpen } = useContext(ModalContext)
  const onOpenModal = () => onOpen(MODALS.cashbackFilter)
  const openAddNewCashbackModal = () => onOpen(MODALS.addNewCashback)  
  const onShowSizeChange = (current: number, size: number) => {
    setTableConfig((pre) => ({ ...pre, page: current, limit: size }))
  }

  return (
    <main className="stores-p">
     <div className='header-block-container'> 
      <div className='filter-block-wrapper'>  
       <FilterBlock
        onChange={handleSearchChange}
        onOpenModal={onOpenModal}
        placeholder="Search transactions"
        /> 
      </div>
        <div className="add-new-cashback" onClick={openAddNewCashbackModal}>+ Add New Cash-back</div>
      </div>
      <Table      
        className="cashback-table"
        loading={isLoading}        
        columns={columns}
        dataSource={(data as ICashbackRedux).items}
        onChange={onChangeTable}
        pagination={{
          position: ['bottomCenter'],
          total: data?.count,
          current: tableConfig.page,
          onShowSizeChange,
        }}
      />
    </main>
  )
}

export default CashbackPage
