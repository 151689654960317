import React from 'react'
import { Outlet } from 'react-router'

import Header from '../../../components/Header'

import '../styles.scss'

const DashboardLayout: React.FC = () => (
  <React.Fragment>
    <Header />
    <div className="dashboard-l">
      <Outlet />
    </div>
  </React.Fragment>
)

export default DashboardLayout
