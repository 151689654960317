import { createApi } from '@reduxjs/toolkit/query/react'
import { TableConfig } from '../models/ITableConfig'
import { IWithdrawalsRedux } from '../models/IWithdrawals'
import { baseQueryWithReauth } from '../utils/baseQueryWithReauth'

export const WITHDRAWAL_API_REDUCER_KEY = 'withdrawalApi'
export const withdrawalApi = createApi({
  reducerPath: WITHDRAWAL_API_REDUCER_KEY,
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Withdrawals'],
  endpoints: (builder) => ({
    getWithdrawalList: builder.query<IWithdrawalsRedux, TableConfig>({
      query: (tableConfig) => ({
        url: '/admin/withdrawals',
        params: {
          ...tableConfig,
        },
      }),
      providesTags: () => ['Withdrawals'],
    }),
  }),
})
