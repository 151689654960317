import { Form, FormInstance, Input, DatePicker } from 'antd'
import React, { createRef } from 'react'
import SVG from '../../../../assets/images/SVG'

import './styles.scss'

interface IProps {
  labelFirstInput: string;
  nameFirstINput: string;
  typeFirstInput: string;
}

const FilterClicks: React.FC<IProps> = ({
  labelFirstInput = '',
  nameFirstINput = '',
  typeFirstInput = 'text',
}) => {
  const form = Form.useFormInstance()
  const formRef = createRef<FormInstance>()

  return (
    <div className="filter-container">
      <Form
        form={form}
        ref={formRef}
        autoComplete="off"
        className="store-f"
        initialValues={{
          searchStore: '',
          minClickDate: '',
          maxClickDate: '',
        }}
      >
        <div className="form-row filter-row">
          <Form.Item
            name={nameFirstINput}
            label={labelFirstInput}
            className="item-half"
          >
            <Input type={typeFirstInput} className="form-filter-input" />
          </Form.Item>
        </div>

        <div className="form-row filter-row">
          <Form.Item
            name="minClickDate"
            label="Date & time"
            className="item-half"
          >
            <DatePicker
              placeholder="From"
              suffixIcon={<SVG.CalendarIcon />}
              className="form-filter-datapicker"
              format="YYYY-MM-DD"
            />
          </Form.Item>
          <Form.Item name="maxClickDate" className="item-half">
            <DatePicker
              placeholder="To"
              suffixIcon={<SVG.CalendarIcon />}
              className="form-filter-datapicker"
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default FilterClicks
