import React from 'react'

import '../styles.scss'
import './styles.scss'
import CarrierFormContainer from './CarrierFormContainer'

const CarrierPage = () => {

  return (
    <main className="store-p">
      <CarrierFormContainer/>
    </main>
  )
}

export default CarrierPage
