/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { Button, Form, FormInstance, Tabs } from 'antd'
import { isEmpty, omit } from 'lodash'
import React, { useEffect, createRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { CreatStoreError, IForm } from '../../../models'
import { storeApi, storesApi } from '../../../services'
import { ShowMessage } from '../../../utils'
import { LANGUAGES } from '../../../utils/constants'
import Emitter, { EmitterEvents } from '../../../utils/eventEmitter'
import StoreForm from '../StoreForm'
import StoreUpload from '../StoreUpload'
import SpecialOfferList from '../SpecialOfferList'
import { ICategories } from '../../../models/ICategories'
import SVG from '../../../assets/images/SVG'
import type { RcFile, UploadProps } from 'antd/es/upload'
import { UploadChangeParam } from 'antd/lib/upload'
import type { UploadFile } from 'antd/es/upload/interface'
import getBase64 from '../../../utils/getBase64'

const { TabPane } = Tabs

const StoreFormContainer: React.FC = () => {
  const [form] = Form.useForm()
  const specialFormRef = createRef<FormInstance>()
  const { id } = useParams()
  const navigate = useNavigate()
  const { data: categoryList = [] } = storeApi.useGetCategoriesQuery('')
  const {
    data,
    isLoading: fetchStoreIsLoading,
    isError,
  } = storesApi.useFetchStoreQuery(id, { skip: !id })

  const [updateStore, { isSuccess: isSU, error: updateError, isLoading: updateStoreIsLoading }] =
    storesApi.useUpdateStoreMutation()
  const [createStore, { isSuccess: isSC, error: createdError, isLoading: createStoreIsLoading }] =
    storesApi.useCreateStoreMutation()
  const { data: affiliateList = [] } = storeApi.useGetAffiliateNetworksQuery('')
  const { data: bookmarks, refetch: refetchBookmarks } = storeApi.useGetBookmarksQuery('')
  const { data: attributeList } = storeApi.useGetAttributesQuery('')
  const [deleteStore, { isSuccess: isSuccessDeleteStore }] = storesApi.useDeleteStoreMutation()

  const [activeType, setActiveType] = useState<'en' | 'fr'>('en')

  const [selectedCategoryList, setSelectedCategoryList] = useState<ICategories[]>([])
  // @ts-ignore
  const logoPreview =
    activeType === 'en' ? data?.tr?.en?.logoImage?.url : data?.tr?.fr?.logoImage?.url
  const bgPreview =
    // @ts-ignore
    activeType === 'en' ? data?.tr?.en?.backgroundImage?.url : data?.tr?.fr?.backgroundImage?.url
  const bannerPreview =
    // @ts-ignore
    activeType === 'en' ? data?.tr?.en?.searchLogoImage?.url : data?.tr?.fr?.searchLogoImage?.url

  const [preview, setPreview] = useState({ en: '', fr: '' })
  const [images, setImages] = useState({
    en: {
      logoImage: {},
      bannerImage: {},
      backgroundImage: {},
    },
    fr: {
      logoImage: {},
      bannerImage: {},
      backgroundImage: {},
    },
  })
  useEffect(() => {
    refetchBookmarks()
  }, [])

  useEffect(() => {
    setImages({
      en: {
        logoImage: { url: logoPreview },
        bannerImage: { url: bannerPreview },
        backgroundImage: { url: bgPreview },
      },
      fr: {
        logoImage: { url: logoPreview },
        bannerImage: { url: bannerPreview },
        backgroundImage: { url: bgPreview },
      },
    })
  }, [data])

  useEffect(() => {
    isError && navigate('/stores')
  }, [isError])

  useEffect(() => {
    Emitter.on(EmitterEvents.STORE_REMOTE_SUBMIT, () => {
      form.submit()
    })

    return () => {
      Emitter.off(EmitterEvents.STORE_REMOTE_SUBMIT)
    }
  }, [form])

  const publishedObjFromBooleanToString = {
    true: 'Published',
    false: 'Unpublished',
  }

  useEffect(() => {
    if (isEmpty(data)) return
    const changedData = {
      ...data,
      /* eslint-disable-next-line */
      // @ts-ignore
      isPublished: publishedObjFromBooleanToString[String(data?.isPublished)],
      affiliateNetworkId: data?.affiliateNetwork?.title,
      attributes: data?.attributes.map((a) => ({ key: a.id, label: <span>{a.title}</span> })),
    }
    changedData.categories && setSelectedCategoryList(changedData.categories)
    form.setFieldsValue(changedData)
  }, [data, form])

  useEffect(() => {
    if (!isSC && !isSU) return
    setPreview({ en: '', fr: '' })
    ShowMessage('success', 'Success')
    navigate('/stores')
  }, [isSC, isSU, navigate])

  useEffect(() => {
    if (!isSuccessDeleteStore) return
    ShowMessage('success', 'Store deleted')
    navigate('/stores')
  }, [isSuccessDeleteStore])

  //
  /* eslint-disable-next-line */
  // @ts-ignore
  const errorMessage: string = createdError?.data?.message || updateError?.data?.message
  const advertiserIdError =
    errorMessage && ['advertiserId', 'already exists'].every((str) => errorMessage.includes(str))
  const titleEnError =
    errorMessage && ['en) already exists'].every((str) => errorMessage.includes(str))
  const titleFrError =
    errorMessage && ['fr) already exists'].every((str) => errorMessage.includes(str))

  const error: CreatStoreError = {
    advertiserId: advertiserIdError && 'This affiliate id is exists',
    titleEnError: titleEnError && 'This title english is exists',
    titleFrError: titleFrError && 'This title french is exists',
  }

  // >>>>>>>>>>>>>>>  Affiliate Id <<<<<<<<<<<<<<<<<<

  useEffect(() => {
    if (!error.advertiserId) return
    ShowMessage('error', error.advertiserId as string)

    error.advertiserId &&
      form.setFields([
        {
          name: 'advertiserId',
          errors: [error.advertiserId as string],
        },
      ])
  }, [error.advertiserId])

  // >>>>>>>>>>>>>>>  Affiliate Id <<<<<<<<<<<<<<<<<<

  // >>>>>>>>>>>>>>>  Title en  <<<<<<<<<<<<<<<<<<
  useEffect(() => {
    if (!error.titleEnError) return

    ShowMessage('error', error.titleEnError as string)

    error.titleEnError &&
      form.setFields([
        {
          name: ['tr', 'en', 'title'],
          errors: [error.titleEnError as string],
        },
      ])
  }, [error.titleEnError])

  // >>>>>>>>>>>>>>>  Title en <<<<<<<<<<<<<<<<<<

  // >>>>>>>>>>>>>>>  Title fr <<<<<<<<<<<<<<<<<<
  useEffect(() => {
    if (!error.titleFrError) return
    ShowMessage('error', error.titleFrError as string)
    error.titleFrError &&
      form.setFields([
        {
          name: ['tr', 'fr', 'title'],
          errors: [error.titleFrError as string],
        },
      ])
  }, [error.titleFrError])

  const handleChange: UploadProps['onChange'] = async (info: UploadChangeParam<UploadFile>) => {
    const image = await getBase64(info.file.originFileObj as RcFile)
    setPreview(activeType === 'en' ? { ...preview, en: image } : { ...preview, fr: image })
  }

  // >>>>>>>>>>>>>>>  Title fr <<<<<<<<<<<<<<<<<<

  const onFinish = (values: IForm) => {
    /* eslint-disable-next-line */
    // @ts-ignore
    let correctAffiliateNetwork: number
    if (typeof values.affiliateNetworkId == 'string') {
      correctAffiliateNetwork = affiliateList.find(
        (it) => it.title == values.affiliateNetworkId
      )!.id
    } else {
      correctAffiliateNetwork = values.affiliateNetworkId
    }

    const { tr } = values
    const { logoImage: lI, backgroundImage: bI, bannerImage: banI } = images.en
    const { logoImage: lIFr, backgroundImage: bIFr, bannerImage: banIFr } = images.fr
    const deleteValuesArr = [
      'tr',
      'logoImage',
      'backgroundImage',
      'affiliateNetworkId',
      'attributes',
      'isPublished',
      'bannerImage',
    ]
    correctAffiliateNetwork === 4 && deleteValuesArr.push('advertiserId')
    !values.favoritesPosition && deleteValuesArr.push('favoritesPosition')

    const body = {
      ...omit(values, deleteValuesArr),
      categoryIds: selectedCategoryList.map((cat) => cat.id),
      affiliateNetworkId: correctAffiliateNetwork,
      isPublished: values.isPublished == 'false' ? false : true,
      translations: Object.keys(tr).map((key) => ({
        ...tr[key],
        language: { code: key },
      })),
    }

    values.attributes && (body.attributeIds = values.attributes.map((a) => a.key!))

    const fData = new FormData()
    fData.append('store', JSON.stringify(body))

    !lI.url && fData.append('logoImageEn', lI.file.originFileObj!)
    !bI.url && fData.append('backgroundImageEn', bI.file.originFileObj!)
    !banI.url && fData.append('searchLogoEn', banI.file.originFileObj!)
    !lIFr.url && fData.append('logoImageFr', lIFr.file.originFileObj!)
    !bIFr.url && fData.append('backgroundImageFr', bIFr.file.originFileObj!)
    !banIFr.url && fData.append('searchLogoFr', banIFr.file.originFileObj!)
    id ? updateStore({ id, fData }) : createStore(fData)
  }
  const { en, fr } = LANGUAGES

  const storeFormProps = {
    fetchStoreIsLoading,
    createStoreIsLoading,
    updateStoreIsLoading,
    bannerPreview,
    categoryList,
    data,
    form,
    affiliateList,
    bookmarks,
    selectedCategoryList,
    setSelectedCategoryList,
    attributeList,
    handleChangeProps: handleChange,
    previewProps: activeType === 'en' ? preview.en : preview.fr,
    error,
  }

  const specialOfferListProps = {
    type: activeType,
    specialOffers: data?.specialOffers,
  }

  const onDelete = () => {
    id ? deleteStore(Number(id)) : navigate('/stores')
  }
  return (
    <div className='store-form'>
      <Form
        form={form}
        ref={specialFormRef}
        onFinish={onFinish}
        autoComplete='off'
        className='store-f'
      >
        <StoreUpload
          id={id}
          type='backgroundImage'
          previewImg={bgPreview}
          activeType={activeType}
          setImages={setImages}
          images={images}
          shouldUseOnchange
        />
        <div className='store-f_container store-details-form'>
          <StoreUpload
            id={id}
            type='logoImage'
            previewImg={logoPreview}
            activeType={activeType}
            setImages={setImages}
            images={images}
            shouldUseOnchange
          />
          <Tabs
            activeKey={activeType}
            onChange={(activeKey) => setActiveType(activeKey as 'en' | 'fr')}
            type='card'
          >
            <TabPane tab={en.title} key={en.code}>
              <StoreForm
                {...storeFormProps}
                type={en.code}
                setImages={setImages}
                images={images}
                activeType='bannerImage'
                previewProps={preview.en || bannerPreview}
              />
            </TabPane>
            <TabPane forceRender tab={fr.title} key={fr.code}>
              <StoreForm
                {...storeFormProps}
                type={fr.code}
                setImages={setImages}
                images={images}
                activeType='bannerImage'
                previewProps={preview.fr || bannerPreview}
              />
            </TabPane>
          </Tabs>
        </div>
      </Form>
      <SpecialOfferList {...specialOfferListProps} />
      <div className='delete-btn-container'>
        <Button icon={<SVG.BucketIcon />} danger onClick={onDelete}>
          Delete store
        </Button>
      </div>
    </div>
  )
}

export default StoreFormContainer
