import './styles.scss'

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Form } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { blogApi } from '../../services'
import { omit } from 'lodash'
import { StoreImage } from '../../models'
import { NOTIFICATION_TYPES, useNotification } from '../../hooks/useNotification'
import { ErrorNode } from '../../utils/ShowMessage'
import { FormArticle } from './FormArticle'
import { IArticle } from '../../models/IBlog'
import { ITranslations } from '../../models/ContentPage'

export const textEditorPlaginList = [
  // 'a11ychecker',
  'advlist',
  'advcode',
  'advtable',
  'autolink',
  'checklist',
  'export',
  'lists',
  'link',
  'image',
  'charmap',
  'preview',
  'anchor',
  'searchreplace',
  'visualblocks',
  'powerpaste',
  'fullscreen',
  'formatpainter',
  'insertdatetime',
  'media',
  'table',
  'help',
  'wordcount',
]

const FormArticleContainer = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const isEditPage = useMemo(() => !!id, [])

  const [form] = Form.useForm()
  const editorRef = useRef(null)

  const [editorValue, setEditorValue] = useState<ITranslations[]>([
    { language: 'en', content: '', id: 1 },
    { language: 'fr', content: '', id: 2 },
  ])

  const [language, setLanguage] = useState('en')
  const onChangeLanguage = () => {
    const currentLanguage = 'en' === language ? 'fr' : 'en'
    setLanguage(currentLanguage)
  }

  const [activeType, setActiveType] = useState<'1' | '2'>('1')

  const [createArticle, createArticleRes] = blogApi.useCreateArticleMutation()
  const [editArticle, editArticleRes] = blogApi.useEditArticleMutation()

  const [getArticleById, articleRes] = blogApi.useLazyGetArticleByIdQuery()

  const [uploadedImgId, setUploadedImgId] = useState<number[]>([])

  useEffect(() => {
    if (id) getArticleById(id)
  }, [id])

  useEffect(() => {
    if (articleRes.isFetching || !articleRes.data) return

    const { readTime, translations, slag } = articleRes.data as IArticle

    const articleData = {
      readTime,
      slag,
      en: { ...translations?.find(({ language = '' }) => language === 'en') },
      fr: { ...translations?.find(({ language = '' }) => language === 'fr') },
    }

    // eslint-disable-next-line
    setEditorValue((state: ITranslations[]): React.SetStateAction<any> => {
      return state.map(({ language = '' }) => {
        const translation = translations?.find((item) => item.language === language)
        return {
          language,
          content: translation?.content,
          id: translation?.id,
        }
      })
    })

    form.setFieldsValue(articleData)
    form.setFieldValue('logoImage', articleRes.data?.logo)
  }, [articleRes.isFetching])

  useEffect(() => {
    if (!createArticleRes.isSuccess) return
    navigate('/blog')
  }, [createArticleRes.isSuccess])

  useEffect(() => {
    if (!editArticleRes.isSuccess) return
    navigate('/blog')
  }, [editArticleRes.isSuccess])

  const initialValues = {
    logoImage: '' as string | StoreImage,
    title: '',
    description: '',
    readTime: '',
    slag: '',
  }

  useNotification(NOTIFICATION_TYPES.success, createArticleRes.isSuccess)
  useNotification(
    NOTIFICATION_TYPES.error,
    createArticleRes.isError,
    createArticleRes.error as ErrorNode
  )

  useNotification(NOTIFICATION_TYPES.success, editArticleRes.isSuccess)
  useNotification(
    NOTIFICATION_TYPES.error,
    editArticleRes.isError,
    editArticleRes.error as ErrorNode
  )

  const onFinish = (values: typeof initialValues) => {
    const article = {
      ...omit(values, ['logoImage', 'readTime', 'en', 'fr', 'slag']),
      translations: getTranslations(values),
      readTime: Number(values.readTime),
      blogPostImages: uploadedImgId,
      slag: values.slag,
    }

    const fData = new FormData()
    fData.append('post', JSON.stringify(article))
    if (typeof values.logoImage !== 'string') {
      fData.append('logo', (values.logoImage as StoreImage).file.originFileObj!)
    }

    if (!isEditPage) createArticle(fData)
    if (isEditPage) editArticle({ id: id as string, body: fData })
  }

  const handleSubmit = () => form.submit()
  const handleBack = () => navigate(-1)

  // eslint-disable-next-line
  function getTranslations(values: any) {
    return editorValue.map(({ language, ...rest }) => ({
      language,
      ...rest,
      ...values[language],
    }))
  }

  const Actions = () => {
    return (
      <div className='sub-header_buttons tabs-buttons'>
        <Button className='language-button' onClick={onChangeLanguage}>
          {language.toUpperCase()}
        </Button>
        <Button onClick={handleBack}>Cancel</Button>
        <Button onClick={handleSubmit}>{id ? 'Save' : 'Publish'}</Button>
      </div>
    )
  }

  const propsFormArticle = {
    form,
    onFinish,
    Actions,
    activeType,
    setActiveType,
    articleRes,
    editorRef,
    setUploadedImgId,
    language,
    setEditorValue,
  }

  return <FormArticle {...propsFormArticle} />
}

export default FormArticleContainer
