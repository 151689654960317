import { Form, Input, Select, Spin } from 'antd'
import React from 'react'
import { ICarrierFormProps } from '../../../models/ICarrier'
import { maxLengthValidator100, oneDecimalAllowed } from '../../../utils/validation'
import CarrierUpload from '../CarrierUpload'
import SVG from '../../../assets/images/SVG'
import { carrierApi, carriersApi } from '../../../services'
import { setCarrier } from '../../../redux/reducers'
import { useTypedDispatch } from '../../../hooks/hooks'
import CustomInput from './CustomInput'

const { TextArea } = Input
const { Option } = Select

const featuredCarrier: Array<number> = Array.from({ length: 100 }, (_, index) => index + 1)

const CarrierForm: React.FC<ICarrierFormProps> = ({
  type,
  bookmarks,
  bannerPreview,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleChangeProps = () => {},
  previewProps,
  fetchCarrierIsLoading,
  createCarrierIsLoading,
  updateCarrierIsLoading,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setImages = () => {},
  images,
  activeType = '',
}) => {
  const { data, isLoading } = carriersApi.useGetAllCarriersFromApiQuery()
  const { data: list } = carrierApi.useGetExistingCarrierTitlesQuery()
  const dispatch = useTypedDispatch()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = async (info: any) => {
    handleChangeProps(info)
    setImages({
      ...images,
      [`${type}`]: { ...images[`${type}`], [activeType]: info },
    })
  }
  const renderContent = () => (
    <>
      <Form.Item
        name='isPublished'
        className='item-half select-publish-status'
        rules={[{ required: true, message: 'Missing status' }]}
      >
        <Select suffixIcon={<SVG.ArrowIcon className='store-details-arrowIcon' />}>
          <Option value='true'>Published</Option>
          <Option value='false'>Unpublished</Option>
        </Select>
      </Form.Item>

      <div className='description-container'>
        <div className='description-wrapper'>
          <Form.Item
            label='Carrier Name'
            name='title'
            className='item-half input-big'
            rules={[{ required: true, message: 'Missing title' }]}
          >
            <Select
              suffixIcon={<SVG.ArrowIcon className='store-details-arrowIcon' />}
              onChange={(value) => {
                dispatch(setCarrier({ title: value }))
              }}
            >
              {!isLoading &&
                data &&
                data.map((item) => {
                  return (
                    <Option
                      disabled={list?.existingCarriers.some(
                        (t) => t.toLowerCase() === item.title.toLowerCase()
                      )}
                      key={item.productCode}
                      value={item.title}
                    >
                      {item.title}
                    </Option>
                  )
                })}
            </Select>
          </Form.Item>
        </div>
        <div className='banner-image-wrapper'>
          <CarrierUpload
            type='bannerImage'
            previewImg={previewProps || bannerPreview}
            handleChangeProps={onChange}
          />
          <div className='banner-image-title'>Image for search results</div>
        </div>
      </div>
      <Form.Item
        label='Carrier Description'
        name={type === 'en' ? 'termsEn' : 'termsFr'}
        rules={[{ required: true, message: 'Missing description' }]}
      >
        <CustomInput editorLanguage={type === 'en' ? 'en' : 'fr'} />
      </Form.Item>
      <div className='form-row row-dbl'>
        <Form.Item
          label='Base reward'
          name='baseReward'
          className='item-half'
          rules={[
            { required: true, message: 'Missing base reward' },
            { validator: maxLengthValidator100, message: 'Max reward 100' },
            { validator: oneDecimalAllowed, message: 'Only one decimal is allowed' },
          ]}
        >
          <Input
            type='number'
            min={0}
            max={100}
            onKeyPress={(e) => {
              if (e.code === 'NumpadSubtract' || e.code === 'Minus') {
                e.preventDefault()
              }
            }}
            onPaste={(e) => {
              const paste = e.clipboardData.getData('text')
              if (!/^(\d+(\.\d?)?)?$/.test(paste)) {
                e.preventDefault()
              }
            }}
          />
        </Form.Item>
        <Form.Item
          label='Special reward amount'
          name='specialReward'
          className='item-half'
          rules={[
            { required: true, message: 'Missing special reward amount' },
            { validator: maxLengthValidator100, message: 'Max reward 100' },
            { validator: oneDecimalAllowed, message: 'Only one decimal is allowed' },
          ]}
        >
          <Input
            type='number'
            min={0}
            max={100}
            onKeyPress={(e) => {
              if (e.code === 'NumpadSubtract' || e.code === 'Minus') {
                e.preventDefault()
              }
            }}
            onPaste={(e) => {
              const paste = e.clipboardData.getData('text')
              if (!/^(\d+(\.\d?)?)?$/.test(paste)) {
                e.preventDefault()
              }
            }}
          />
        </Form.Item>
      </div>
      <div className='form-row row-dbl'>
        <Form.Item
          label='Featured carrier'
          name='favoritesPosition'
          className='item-half'
          initialValue={''}
        >
          <Select>
            {featuredCarrier.map((option) => (
              <Option
                disabled={bookmarks?.existingBookmarks.some((it) => it == option)}
                key={option}
                value={option}
              >
                {option}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='Special reward minimum'
          name='specialRewardStart'
          className='item-half'
          rules={[
            { required: true, message: 'Missing special reward minimum' },
            {
              type: 'number',
              transform: (value) => parseFloat(value),
              min: 0,
              message: 'Min reward value is 0',
            },
            {
              type: 'number',
              transform: (value) => parseFloat(value),
              max: 999.99,
              message: 'Max reward value is 999.99',
            },
          ]}
        >
          <Input type='number' />
        </Form.Item>
      </div>

      <div className='form-row row-dbl'>
        <Form.Item
          label='Carrier Slag'
          name='slag'
          className='item-half'
          rules={[
            {
              required: true,
              message: 'Missing Carrier Name',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </div>

      <div className='form-row'>
        <Form.Item
          label='SEO Title'
          name={['tr', type, 'seoTitle']}
          rules={[{ required: true, message: 'Missing SEO Title' }]}
        >
          <Input />
        </Form.Item>
      </div>

      <div className='form-row'>
        <Form.Item
          label='SEO Description'
          name={['tr', type, 'seoDescription']}
          rules={[{ required: true, message: 'Missing SEO Description' }]}
        >
          <TextArea />
        </Form.Item>
      </div>

      <div className='form-row'>
        <Form.Item
          label='SEO Keywords'
          name={['tr', type, 'seoKeywords']}
          rules={[{ required: true, message: 'Missing SEO Keywords' }]}
        >
          <Input />
        </Form.Item>
      </div>
    </>
  )

  return (
    <>
      {fetchCarrierIsLoading || createCarrierIsLoading || updateCarrierIsLoading ? (
        <Spin>{renderContent()}</Spin>
      ) : (
        renderContent()
      )}
    </>
  )
}

export default CarrierForm
