import { Button } from 'antd'
import moment from 'moment'
import React from 'react'
import SVG from '../../assets/images/SVG'
import { IStoreCashback } from '../../models'
import { IUserCashBack } from '../../models/IUsers'

interface ICashBackInfo{
  className: string
   value: string|number|undefined
    category:string
}

export const CashBackInfo = ({ className = '', value = '', category = '' }:ICashBackInfo) => {
  
  return (
    <div className={`cash-back ${className}`}>
   {category=='Order ID'?
   <div data-order={category=='Order ID'&& value} className="cash-back-content">{value}</div>:
   <div className="cash-back-content">{value}</div>}
      <div className="cash-back-footer">{category}</div>
    </div>
  )
}
interface IProps<T>{
  cashBack:T
  firstElement:null|React.ReactNode  
}

type ICasback = IStoreCashback | IUserCashBack


const CashBackCard =  <T extends ICasback> ({ cashBack, firstElement = null }:IProps<T>) => {
  const cashBackTransactionDate =
    cashBack.transactionDate !== '----'
      ? moment(cashBack.transactionDate).format('YYYY-MM-DD h:mm')
      : '----'
  const cashBackWithdrawalAt =
    cashBack.transactionDate !== '----'
      ? moment(cashBack.withdrawalAt).format('YYYY-MM-DD h:mm')
      : '----'

  return (
    <div className={'cash-back-card'}>
     {firstElement&&firstElement}
      <CashBackInfo
        className="cash-back-info"
        value={cashBack.orderId}
        category="Order ID"
      />
      <CashBackInfo
        className="cash-back-info"
        value={cashBack.rewardRatio}
        category="Reward ratio"
      />
      <CashBackInfo
        className="cash-back-info"
        value={cashBackTransactionDate}
        category="Transaction date & time"
      />

      <CashBackInfo
        className="cash-back-info"
        value={cashBack.saleAmount}
        category="Sale"
      />
      <CashBackInfo
        className="cash-back-info"
        value={cashBackWithdrawalAt}
        category="Cash-back date & time"
      />
      <CashBackInfo
        className="cash-back-info"
        value={cashBack.clickId}
        category="Click ID"
      />
      <CashBackInfo
        className="cash-back-info"
        value={cashBack.cashback}
        category="Cash-back"
      />

      <div className="wrapper-btn-download">
        <Button
          disabled
          className="btn-download"
          icon={<SVG.DownloadIcon className="cash-back-download" disabled />}
        >
          PDF
        </Button>
        <Button
          disabled
          className="btn-download"
          icon={<SVG.DownloadIcon className="cash-back-download" disabled />}
        >
          Excel
        </Button>
      </div>
    </div>
  )
}

export default CashBackCard
