import React, {ReactElement, ReactNode, useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router'
import {Dropdown, Button} from 'antd'
import SVG from '../../assets/images/SVG'

import './styles.scss'
import {useTypedDispatch} from '../../hooks/hooks'
import {resetStateAction} from '../../redux/actions/resetState'
import {Menu} from '../Menu/Menu'
import useCapitalizeFirstLetter from '../../hooks/useCapitalizeFirstLetter'

interface IHeaderItem {
  key: string
  label: string
  icon: ReactElement
}

const data: IHeaderItem[] = [
  {key: '/top-up-pin', label: 'Top Up Pin', icon: <SVG.TopUpPinIcon/>},
  {key: '/stores', label: 'Stores', icon: <SVG.StoreIcon/>},
  {key: '/users', label: 'Users', icon: <SVG.UsersIcon/>},
  {key: '/cash-back', label: 'Cash-back', icon: <SVG.CashBackIcon/>},
  {key: '/withdrawals', label: 'Withdrawals', icon: <SVG.WithdrawalsIcon/>},
  {key: '/clicks', label: 'Clicks', icon: <SVG.ClicksIcon/>},
  {
    key: '/missing-transction',
    label: 'Missing Transaction',
    icon: <SVG.MissingTransctionIcon/>,
  },
  {
    key: '/blog',
    label: 'Blog',
    icon: <SVG.BlogIcon/>,
  },
  {
    key: '/content-page',
    label: 'Page Content',
    icon: <SVG.ContentPageIcon/>,
  },
]

export interface IMenuItem {
  startUrl?: string
  endUrl?: string
  secondUrl?: string
  key: string
  label: string
  icon: ReactNode
}

export interface IRenderItem {
  key?: string
  startUrl?: string
  endUrl?: string
  label: string
  icon: ReactNode
}

export interface IMenu {
  onClick: (key: string) => void
  selectedKey: string
  items: IMenuItem[]
  renderItem: (
    item: IRenderItem,
    getIsActive: (key: string) => string,
    onClickItem: (key: string) => void
  ) => JSX.Element
}

const Header = () => {
  const dispatch = useTypedDispatch()
  const {pathname} = useLocation()
  const [currentUrl, setCurrentUrl] = useState<string>(pathname)
  const navigate = useNavigate()
  useEffect(() => {
    const isAfterLogin = pathname == '/'
    if (isAfterLogin) {
      setDocumentTitle('/stores')
      setCurrentUrl('/stores')
    } else {
      setDocumentTitle(pathname)
    }
  }, [pathname])

  const handleLogout: React.MouseEventHandler<HTMLDivElement> = () => {
    document.title = 'TR - Login'
    dispatch(resetStateAction())
  }

  const setDocumentTitle = (url: string) =>
    (document.title = `TR - ${useCapitalizeFirstLetter(url.slice(1))}`)

  const onClick = (key: string) => {
    setDocumentTitle(key)
    navigate(key)
    setCurrentUrl(key)
  }

  const renderItem = (
    {key, icon, label}: IRenderItem,
    getIsActive: (key: string) => string,
    onClickItem: (key: string) => void
  ) => (
    <div
      key={key}
      className={getIsActive(key as string)}
      onClick={() => onClickItem(key as string)}
    >
      <div className='menu-item-icon'>{icon}</div>
      <div> {label}</div>
    </div>
  )

  const [isOpenDropdownLogout, setIsOpenDropdownLogout] = useState(false)

  return (
    <header className='dashboard-header'>
      <div className='dashboard-header_ct'>
        <div>
          <SVG.LogoIcon/>
        </div>
        <Menu onClick={onClick} selectedKey={currentUrl} items={data} renderItem={renderItem}/>
        <Dropdown
          className={`logout-btn ${isOpenDropdownLogout && 'active'}`}
          overlayClassName='dashboard-header_burger'
          placement='bottomRight'
          trigger={['click']}
          onOpenChange={(open: boolean) => setIsOpenDropdownLogout(open)}
          destroyPopupOnHide
          overlay={
            <div onClick={handleLogout}>
              <SVG.LogoutIcon/>
              <div>Logout</div>
            </div>
          }
        >
          <Button>
            <SVG.BurgerIcon/>
          </Button>
        </Dropdown>
      </div>
    </header>
  )
}

export default Header
