import { Carousel as ImageSlider, Input, Select, Tabs } from 'antd'
import { CarouselRef } from 'antd/lib/carousel'

import React, { useEffect, useRef, useState } from 'react'
import SVG from '../../../assets/images/SVG'
import StoreUpload from '../../Store/StoreUpload'
import { UploadChangeParam } from 'antd/lib/upload'
import type { UploadFile } from 'antd/es/upload/interface'

import './styles.scss'
import { storesApi } from '../../../services'
import { IBanner } from '../../../models/IBanners'
import { ErrorNode } from '../../../utils/ShowMessage'
import { NOTIFICATION_TYPES, useNotification } from '../../../hooks/useNotification'
import BannerDeleteModal from '../../../components/Modal/modals/BannerDeleteModal'
import TabPane from 'antd/lib/tabs/TabPane'
import { LANGUAGES } from '../../../utils/constants'

const { Option } = Select

interface IDefSlides {
  src: string
  url?: string
  position?: number
  fileEn?: string
  fileFr?: string
  file?: string | undefined
  id?: number
  languageCode?: string
}

const allBannerPositions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const Carousel = () => {
  const [activeType, setActiveType] = useState<'en' | 'fr'>('en')
  const [activeScreenType, setActiveScreenType] = useState<'desktop' | 'mobile'>('desktop')
  const bannersRes = storesApi.useGetBannerListQuery({
    languageCode: activeType,
    type: activeScreenType,
  })
  const bannersFreePositionsList = storesApi.useGetBannersFreePositionsListQuery({
    languageCode: activeType,
    type: activeScreenType,
  })
  const [updateBanner, updateBannerRes] = storesApi.useUpdateBannerMutation()
  useEffect(() => {
    if (bannersRes.isFetching) return

    const res = slides.map((_, idx) => {
      const items = bannersRes.data?.items.map((it) => ({
        ...it,
        src: activeType, // === 'en' ? it.fileEn : it.fileFr,
      }))

      if (items?.some((banner) => banner.position === idx + 1)) {
        const currentSlide = items.find((banner) => banner.position === idx + 1)
        return currentSlide as IBanner
      }
      // if current slide has not banner
      // if src = '' , I show uploader
      return { src: '' }
    })
    /* eslint-disable-next-line */
    // @ts-ignore
    setSlides(res)
  }, [bannersRes.isFetching, activeType, activeScreenType])

  const defSlides1: IDefSlides[] = []

  for (let i = 1; i < 11; i++) {
    defSlides1.push({ src: '' })
  }

  const [slides, setSlides] = useState<IBanner[] | IDefSlides[]>(defSlides1)
  const [link, setLink] = useState('')
  const { en, fr } = LANGUAGES
  const [currentSlidePosition, setCurrentSlidePosition] = useState(1)

  const [createBanner, createBannerRes] = storesApi.useCreateBannerMutation()
  const [deleteBanner, deleteBannerRes] = storesApi.useDeleteBannerMutation()

  useNotification(NOTIFICATION_TYPES.success, updateBannerRes.isSuccess)
  useNotification(
    NOTIFICATION_TYPES.error,
    updateBannerRes.isError,
    updateBannerRes.error as ErrorNode
  )

  useNotification(NOTIFICATION_TYPES.success, createBannerRes.isSuccess)
  useNotification(
    NOTIFICATION_TYPES.error,
    createBannerRes.isError,
    createBannerRes.error as ErrorNode
  )

  useNotification(NOTIFICATION_TYPES.success, deleteBannerRes.isSuccess)
  useNotification(
    NOTIFICATION_TYPES.error,
    deleteBannerRes.isError,
    deleteBannerRes.error as ErrorNode
  )

  useEffect(() => {
    if (currentSlidePosition) {
      const url = slides[currentSlidePosition - 1]?.url
      if (url) {
        setLink(url)
      } else {
        setLink('')
      }
    }
  }, [currentSlidePosition, slides])

  useEffect(() => {
    /* eslint-disable-next-line */
    // @ts-ignore
    const slide = slides?.find(({ position }) => position === currentSlidePosition)
    const isDifferentUrls = !!slide && slide?.url !== link

    if (link && isDifferentUrls) {
      updateBanner({
        id: currensSlideId as number,
        body: { url: link, languageCode: activeType },
      })
    }
  }, [link])

  const isShowPrevBtn = currentSlidePosition !== 1

  const onChange = (currentSlidePosition: number) => {
    setCurrentSlidePosition(currentSlidePosition + 1)
  }

  const carouselRef = useRef<CarouselRef | null>()

  const handleChangeUpload = async (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status !== 'uploading') {
      const fData = new FormData()
      fData.append('position', String(currentSlidePosition))
      fData.append('languageCode', activeType)
      link && fData.append('url', link)
      fData.append('file', info.file.originFileObj!)
      fData.append('type', activeScreenType)
      createBanner(fData)
    }
  }

  /* eslint-disable-next-line */
  // @ts-ignore
  const currensSlideId: number | undefined = slides[currentSlidePosition - 1]?.id

  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false)

  const onCloseModal = () => setIsShowDeleteModal(false)

  const onDeleteBanner = () => {
    deleteBanner(currensSlideId as number)
    onCloseModal()
  }

  const freeBannerPositionListWithCurrentPosition = allBannerPositions.filter(
    (position) =>
      position === currentSlidePosition ||
      bannersFreePositionsList.data?.positions.some((freePosition) => position === freePosition)
  )

  return (
    <>
      {isShowDeleteModal && (
        <>
          <BannerDeleteModal onClose={onCloseModal} onDelete={onDeleteBanner} />
        </>
      )}

      <div className='wrapper-carousel'>
        <div className='carousel'>
          {isShowPrevBtn && (
            <div
              className='carousel-btn prev-btn'
              onClick={() => (carouselRef.current as CarouselRef).prev()}
            >
              <SVG.ArrowIcon className='' />
            </div>
          )}
          <ImageSlider
            afterChange={onChange}
            ref={carouselRef as React.MutableRefObject<CarouselRef>}
          >
            {slides.map((slide) => {
              return (
                <Slide
                  key={slide.src}
                  slide={slide}
                  handleChangeUpload={handleChangeUpload}
                  activeType={activeType}
                />
              )
            })}
          </ImageSlider>
          <div
            className='carousel-btn next-btn'
            onClick={() => (carouselRef.current as CarouselRef).next()}
          >
            <SVG.ArrowIcon className='' />
          </div>
        </div>
        <div className='actions'>
          <div style={{ marginRight: '10px' }}>
            <Tabs
              activeKey={activeScreenType}
              onChange={(activeKey) => setActiveScreenType(activeKey as 'desktop' | 'mobile')}
              type='card'
            >
              <TabPane tab={'Desktop'} key={'desktop'}></TabPane>
              <TabPane forceRender tab={'Mobile'} key={'mobile'}></TabPane>
            </Tabs>
          </div>
          <div style={{ marginRight: '10px' }}>
            <Tabs
              activeKey={activeType}
              onChange={(activeKey) => setActiveType(activeKey as 'en' | 'fr')}
              type='card'
            >
              <TabPane tab={en.title} key={en.code}></TabPane>
              <TabPane forceRender tab={fr.title} key={fr.code}></TabPane>
            </Tabs>
          </div>
          <div className='btn-link' style={{ marginRight: !currensSlideId ? 250 : 8 }}>
            <SVG.ImageLinkIcon />
            <Input
              className='link-inp'
              value={link}
              onChange={(e) => setLink(e.target.value)}
              placeholder='Image Link'
            />
          </div>

          {currensSlideId && (
            <div className='wrapper-move-and-delete'>
              <div className='move-btn'>
                <Select
                  value='Move'
                  placeholder='Move'
                  onChange={(position) => {
                    updateBanner({
                      id: currensSlideId as number,
                      body: { position: Number(position), languageCode: activeType },
                    })
                  }}
                  className='select-positions'
                  popupClassName='list-options'
                  suffixIcon={
                    <div className='wrapper-arrow'>
                      <SVG.ArrowIcon className='' />
                    </div>
                  }
                >
                  {freeBannerPositionListWithCurrentPosition.map((option) => (
                    <Option disabled={option === currentSlidePosition} key={option} value={option}>
                      {option} banner
                    </Option>
                  ))}
                </Select>
                <div className='wrapper-change-position-icon'>
                  <SVG.ChangePositionIcon />
                </div>
              </div>
              <div className='delete-btn' onClick={() => setIsShowDeleteModal(true)}>
                <SVG.DeleteIcon />
                <div>Delete</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

interface ISlide {
  slide: IDefSlides | IBanner
  handleChangeUpload: (info: UploadChangeParam<UploadFile>) => Promise<void>
  activeType: 'en' | 'fr'
}

const Slide = ({ slide, handleChangeUpload }: ISlide) => {
  return (
    <>
      {slide.file ? (
        <div key={slide.id} className='slide'>
          <img src={slide.file}></img>
        </div>
      ) : (
        <div className='slider'>
          {!slide.file && (
            <label htmlFor='banner' className='wrapper-upload-text'>
              <div className='upload-text'>
                <SVG.DownloadIcon />
                <div className='text'>Upload Banner</div>
              </div>
            </label>
          )}
          <StoreUpload
            id='banner'
            type='logoImage'
            key={slide.id}
            handleChangeProps={handleChangeUpload}
            previewImg={slide.file}
          />
        </div>
      )}
    </>
  )
}
