import { Button } from 'antd'
import SkeletonImage from 'antd/lib/skeleton/Image'
import moment from 'moment'
import { useState } from 'react'
import SVG from '../../../assets/images/SVG'
import { ITicket } from '../../../models/ITickets'

const CashBackInfo = ({ className = '', value = '', category = '' }) => {
  return (
    <div className={`cash-back ${className}`}>
      <div className="cash-back-content">{value}</div>
      <div className="cash-back-footer">{category}</div>
    </div>
  )
}
interface IProps {
  ticket: ITicket;
}

const TicketCard = ({ ticket }: IProps) => {
  const ticketCreateDate =
    ticket.purchasedAt !== '----'
      ? moment(ticket.purchasedAt).format('YYYY-MM-DD')
      : '----'

  const [isLoadingImg, setIsLoadingImg] = useState(true)

  return (
    <div className={'cash-back-card'}>
      <CashBackInfo
        className="ticket-info info-color-green"
        value={ticket.storeTitle}
        category="Store"
      />
      <CashBackInfo
        className="ticket-info info-color-green"
        value={ticket.email}
        category="Email"
      />
      <CashBackInfo
        className="ticket-info info-color-green"
        value={ticket.phoneNumber}
        category="Phone number"
      />
      <CashBackInfo
        className="ticket-info"
        value={ticketCreateDate}
        category="Date of purchase"
      />
      <CashBackInfo
        className="ticket-info"
        value={ticket.purchaseType}
        category="Purchase type"
      />
      <CashBackInfo
        className="ticket-info"
        value={ticket.paymentMethod}
        category="Payment method"
      />

      <div className="ticket-wrapper">
        <CashBackInfo
          className="ticket-info-comment"
          value={ticket.comment}
          category="Comments"
        />
        <CashBackInfo
          className="ticket-info"
          value={ticket.amount}
          category="Order amount"
        />
      </div>

      {isLoadingImg && <SkeletonImage className="img-skeleton" active={true} />}

      <div className={`wrapper-ticket-img ${!isLoadingImg && 'show'}`}>
        <img
          src={ticket.receiptImage}
          alt="ticket"
          onLoad={() => setIsLoadingImg(false)}
        />
      </div>
      <div className="wrapper-btn-download big-card">
        <Button
          disabled
          onClick={() => console.log('sd')}
          className="btn-donwload"
          icon={<SVG.DownloadIcon className="cash-back-download" disabled />}
        >
          PDF
        </Button>
      </div>
    </div>
  )
}

export default TicketCard
