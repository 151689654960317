import { Editor } from '@tinymce/tinymce-react'
import { useRef } from 'react'
import { ITextEditor } from '../../../models/ContentPage'
import { useTypedSelector } from '../../../hooks/hooks'
import { textEditorPlaginList } from './PageWithEditor'
import type { Editor as TinyMCEEditor } from 'tinymce'

export const TextEditor = ({
  editorLanguage = 'en',
  setEditorValue,
  setUploadedImgId,
  initialValue,
  fetchURL = process.env.NODE_ENV === 'development'
    ? 'https://api.staging.telcorewards.ca/api/admin/info-page-images'
    : 'https://api.telcorewards.ca/api/admin/info-page-images',
  fieldName = 'infoPageImage',
  onChange,
}: ITextEditor) => {
  const { access_token } = useTypedSelector((state) => state.auth)

  const editorRef = useRef<TinyMCEEditor | null>(null)

  interface IImageUploadHandler {
    blob: () => string | Blob
  }

  const imageUploadHandler = async ({ blob }: IImageUploadHandler) => {
    const fData = new FormData()
    fData.append(fieldName, blob())

    const imageRes = await fetch(fetchURL, {
      method: 'POST',
      body: fData,
      headers: {
        authorization: `Bearer ${access_token}`,
      },
    })

    const resJson = await imageRes.json()
    setUploadedImgId((pre) => [...pre, resJson.id])

    return new Promise((resolve) => resolve(resJson.url)) as Promise<string>
  }

  const handleBlur = () => {
    if (editorRef.current) {
      if (onChange) {
        const content = editorRef.current.getContent()
        onChange(content)
      }
    }
  }

  return (
    <Editor
      apiKey='yhn9o0aeb725ac9kr8gugxmhcjph95w0b33vhg8gm20xws74'
      onInit={(_evt, editor) => ((editorRef.current as unknown) = editor)}
      onEditorChange={(a, editor) => {
        if (setEditorValue) {
          setEditorValue((pre) =>
            pre.map((obj) =>
              obj.language === editorLanguage ? { ...obj, content: editor.getContent() } : obj
            )
          )
        }
      }}
      onBlur={handleBlur}
      initialValue={initialValue}
      init={{
        images_upload_handler: imageUploadHandler,
        file_picker_callback: function (callback, value, meta) {
          if (meta.filetype == 'image') {
            callback('myimage.jpg', { alt: 'My alt text', src: 'q12321' })
          }
        },
        height: 600,
        menubar: false,
        plugins: textEditorPlaginList,
        toolbar:
          'undo redo | casechange blocks |fontfamily | bold italic backcolor | ' +
          'alignleft aligncenter alignright alignjustify | ' +
          'bullist numlist checklist outdent indent | removeformat | a11ycheck code insert/edit link insert/edit image insert/edit media embed iframe table',
        // toolbar: 'removeformat | a11ycheck code table help',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
      }}
    />
  )
}
