import { createApi } from '@reduxjs/toolkit/query/react'
import { ITicketsRedux, TicketsPageConfig } from '../models/ITickets'
import { baseQueryWithReauth } from '../utils/baseQueryWithReauth'

export const TICKETS_API_REDUCER_KEY = 'ticketApi'
export const ticketApi = createApi({
  reducerPath: TICKETS_API_REDUCER_KEY,
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Tickets'],
  endpoints: (builder) => ({
    getTicketList: builder.query<ITicketsRedux, TicketsPageConfig>({
      query: (tableConfig) => ({
        url: '/admin/tickets',
        params: {
          ...tableConfig,
        },
      }),
      providesTags: () => ['Tickets'],
    }),
  }),
})
