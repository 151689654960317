import { ColumnsType } from 'antd/lib/table'
import moment from 'moment'
import { IUserClicks } from '../../models/IUsers'

export const columns: ColumnsType<IUserClicks> = [
  {
    title: 'Click ID',
    dataIndex: 'id',
    defaultSortOrder: 'descend',
    sortDirections: ['descend', 'ascend'],
    sorter: {},
  },
  {
    title: 'Store',
    dataIndex: 'storeTitle',
    defaultSortOrder: 'descend',
    sortDirections: ['descend', 'ascend'],
    sorter: {},
    render: (text) => (
      <div className="wrapper-link">
        <div className="user-clicks-table-storetitle">{text}</div>
      </div>
    ),
  },
  {
    title: 'Date & Time',
    dataIndex: 'createdAt',
    defaultSortOrder: 'descend',
    sortDirections: ['descend', 'ascend'],
    sorter: {},
    render: (text) => <>{moment(text).format('YYYY-MM-DD h:mm')}</>,
  },
]
