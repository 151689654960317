import { ITextEditor } from '../../../models/ContentPage'
import React, { useState } from 'react'
import { TextEditor } from '../../ContentPage/Components/TextEditor'

interface ICustomInputProps extends Pick<ITextEditor, 'editorLanguage' | 'onChange'> {
  value?: string
}

const CustomInput = ({ value, onChange, editorLanguage }: ICustomInputProps) => {
  const [uploadedImgId, setUploadedImgId] = useState<number[]>([])
  console.log(uploadedImgId)

  return (
    <TextEditor
      editorLanguage={editorLanguage}
      onChange={onChange}
      initialValue={value || ''}
      setUploadedImgId={setUploadedImgId}
    />
  )
}

export default CustomInput
