import { ColumnsType } from 'antd/lib/table'
import moment from 'moment'
import { IStoreClicks } from '../../models'

const columns: ColumnsType<IStoreClicks> = [
  {
    title: 'Click ID',
    dataIndex: 'id',
    defaultSortOrder: 'descend',
    sortDirections: ['descend', 'ascend'],
    sorter: {},
  },
  {
    title: 'User',
    dataIndex: 'phoneNumber',
    defaultSortOrder: 'descend',
    sortDirections: ['descend', 'ascend'],
    sorter: {},
    render: (phoneNumber) => (
      <div className="wrapper-link">
        <div className="user-clicks-table-storetitle">{phoneNumber}</div>
      </div>
    ),
  },
  {
    title: 'Date & Time',
    dataIndex: 'createdAt',
    defaultSortOrder: 'descend',
    sortDirections: ['descend', 'ascend'],
    sorter: {},
    render: (text) => {
      return <>{moment(text).format('YYYY-MM-DD h:mm')}</>
    },
  },
]
export default columns
