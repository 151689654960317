import { Skeleton } from 'antd'

export const SkeletonArticle = () => {
  const size = 'large'
  const active = true

  return (
    <div className='wrapper-form-preview-article'>
      <div>
        <Skeleton.Image active={active} className='skeleton-logo' />
      </div>
      <div className='content'>
        <Skeleton.Input active={active} size={size} className='skeleton-inp' />
        <Skeleton.Input active={active} size={size} className='skeleton-inp' />
      </div>
      <div className='wrapper-description'>
        <Skeleton.Input active={active} size={size} className='skeleton-textarea' />
      </div>
    </div>
  )
}
