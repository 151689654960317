/* eslint-disable */
import React, { useContext } from 'react'
import { Modal as AntModal, Form } from 'antd'
import lodashFilter from 'lodash/flow'

import Emitter, { EmitterEvents } from '../../utils/eventEmitter'
import StoreFilter from './modals/StoresFilter/index'
import { ModalContext } from './modalProvider'
import { dataPickerKeyArr, MODALS } from '../../utils/constants'
import './styles.scss'
import UsersFilter from './modals/UsersFilter'
import UserFilterWithdrawals from './modals/UserFilterWithdrawals'
import moment from 'moment'
import FilterClicks from './modals/FilterClicks'
import FilterCashback from './modals/FilterCashback'
import FilterCashbackPage from './modals/FilterCashbackPage'
import FilterWithdrawalsPage from './modals/FilterWithdrawalsPage'
import FilterClicksPage from './modals/FilterClicksPage'
import AddNewCashback from './modals/AddNewCashback'
import FilterMissingTransactionsPage from './modals/FilterMissingTransactionsPage'
import CarrierFilter from './modals/CarriersFilter'

const Modal = () => {
  const { selectedModal, onClose } = useContext(ModalContext)

  const isVisible = selectedModal !== null
  const [form] = Form.useForm()

  const handleClick = () => {
    selectedModal === MODALS.addNewCashback ? createNewCashback() : onSubmit()
  }

  const onSubmit = () => {
    const filteredValue = lodashFilter([
      Object.entries,
      (arr) =>
        arr.filter(
          ([key, value]) => value !== '' && value !== 'No min' && value !== 'No max' // delete unnecessary or empty filters
        ),
      Object.fromEntries,
    ])(form.getFieldsValue())

    const getNewFilterValue = (filterObj) => {
      const newValidObj = {}
      for (let key in filterObj) {
        let isValidValueByKey = !!filterObj[key] ?? false
        if (isValidValueByKey && dataPickerKeyArr.some((dataPickerKey) => dataPickerKey == key)) {
          let date = moment(filterObj[key]._d)
          key.includes('min') && date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          key.includes('max') && date.set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
          newValidObj[key] = date.format('YYYY-MM-DDTHH:mm:ssZ')
        } else if (isValidValueByKey) {
          newValidObj[key] = filterObj[key]
        }
      }
      return newValidObj
    }

    let newFilteredValue = getNewFilterValue(filteredValue)
    //@ts-ignore
    Emitter.emit(EmitterEvents.MODAL_FILTER_SUBMIT, newFilteredValue)

    onClose()
  }

  const createNewCashback = async () => {
    form.submit()
    const values = form.getFieldsValue()

    try {
      await form.validateFields()
      const parseFormValues = {
        ...values,
        transactionAt: moment(values.transactionAt._d).format('YYYY-MM-DDTHH:mm:ssZ'),
        withdrawalAt: moment(values.withdrawalAt._d).format('YYYY-MM-DDTHH:mm:ssZ'),
        userId: +values.userId,
        storeId: +values.storeId,
      }
      Emitter.emit(EmitterEvents.MODAL_ADD_NEW_CASHBACK_SUBMIT, parseFormValues)
      onClose()
    } catch (error) {}
  }

  const onClear = () => {
    form.resetFields()
    Emitter.emit(EmitterEvents.MODAL_FILTER_CLEAR)
  }

  return (
    <AntModal
      title={selectedModal === MODALS.addNewCashback ? 'New Cash-back' : 'Filters'}
      visible={isVisible}
      onCancel={onClose}
      footer={[
        <div key={1} className='button-container'>
          <div className='clear-button' onClick={onClear}>
            Clear
          </div>

          <div className='submit-button' onClick={handleClick}>
            {selectedModal === MODALS.addNewCashback ? 'Create' : 'Filter'}
          </div>
        </div>,
      ]}
    >
      <Form form={form}>
        {selectedModal === MODALS.storeFilter && <StoreFilter />}
        {selectedModal === MODALS.carrierFilter && <CarrierFilter />}
        {selectedModal === MODALS.addNewCashback && <AddNewCashback />}
        {selectedModal === MODALS.usersFilter && <UsersFilter />}
        {selectedModal === MODALS.userFilterWithdrawals && <UserFilterWithdrawals />}
        {selectedModal === MODALS.userFilterClicks && (
          <FilterClicks labelFirstInput='Store' nameFirstINput='searchStore' />
        )}
        {selectedModal === MODALS.userFilterCashback && (
          <FilterCashback labelFirstInput='Store' nameFirstINput='searchStore' />
        )}
        {selectedModal === MODALS.storeFilterCashback && (
          <FilterCashback
            labelFirstInput='User'
            nameFirstINput='searchPhoneNumber'
            typeFirstInput='number'
          />
        )}
        {selectedModal === MODALS.storeFilterClicks && (
          <FilterClicks
            labelFirstInput='User'
            nameFirstINput='searchPhoneNumber'
            typeFirstInput='number'
          />
        )}
        {selectedModal === MODALS.carrierFilterCashback && (
          <FilterCashback
            labelFirstInput='User phone'
            nameFirstINput='searchPhoneNumber'
            typeFirstInput='number'
          />
        )}
        {selectedModal === MODALS.carrierFilterClicks && (
          <FilterClicks
            labelFirstInput='User phone'
            nameFirstINput='searchPhoneNumber'
            typeFirstInput='number'
          />
        )}
        {selectedModal === MODALS.cashbackFilter && <FilterCashbackPage />}
        {selectedModal === MODALS.withdrawalsFilter && <FilterWithdrawalsPage />}
        {selectedModal === MODALS.clicksFilter && <FilterClicksPage />}
        {selectedModal === MODALS.missingTransaction && <FilterMissingTransactionsPage />}
      </Form>
    </AntModal>
  )
}

export default Modal
