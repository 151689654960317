import { Navigate, Route, Routes } from 'react-router'

import { useTypedSelector } from '../../hooks/hooks'
import SignInPage from '../../pages/SignIn/SignInPage'
import StorePage from '../../pages/Store/StorePage'
import StoresPage from '../../pages/Stores/StoresPage'
import UsersPage from '../../pages/Users/UsersPage'
import UserDetails from '../../pages/UserDetails'

import { RootState } from '../../redux'
import DashboardLayout from './DashboardLayout/DashboardLayout'
import DetailLayout from './DetailLayout/DetailLayout'
import UserClicks from '../../pages/UserClicks'
import UserCashBack from '../../pages/UserCashBack'
import UserWithdrawals from '../../pages/UserWithdrawals'
import StoreCashBack from '../../pages/StoreCashBack'
import StoreClicks from '../../pages/StoreClicks'
import CashbackPage from '../../pages/Cashback'
import WithdrawalsPage from '../../pages/Withdrawals'
import ClicksPage from '../../pages/Clicks'
import MissingTransctionPage from '../../pages/MissingTransction'
import Blog from '../../pages/Blog'
import FormArticle from '../../pages/FormArticle'
import { ArticleView } from '../../pages/ArticleView'
import { ContentPage } from '../../pages/ContentPage/ContentPage'
import TopUpPinPage from '../../pages/TopUpPin/TopUpPinPage'
import CarrierPage from '../../pages/Carrier/CarrierPage'
import { ELayoutVariants } from '../../utils/enums'
import CarrierCashBack from '../../pages/CarrierCashBack'
import CarrierClicks from "../../pages/CarrierClicks";

const App: React.FC = () => {
  const { access_token } = useTypedSelector((state: RootState) => state.auth)

  return access_token ? (
    <Routes>
      <Route path='/' element={<DashboardLayout />}>
        <Route path='top-up-pin' element={<TopUpPinPage />} />
        <Route path='stores' element={<StoresPage />} />
        <Route path='users' element={<UsersPage />} />
        <Route path='cash-back' element={<CashbackPage />} />
        <Route path='withdrawals' element={<WithdrawalsPage />} />
        <Route path='clicks' element={<ClicksPage />} />
        <Route path='missing-transction' element={<MissingTransctionPage />} />
        <Route path='blog' element={<Blog />} />
        <Route path='content-page' element={<ContentPage />} />

        <Route path='/' element={<Navigate to='/stores' replace />} />
      </Route>

      <Route path='blog/new-article1' element={<FormArticle />} />
      <Route path='blog/article/:id/:mode' element={<FormArticle />} />
      <Route path='blog/article/:id' element={<ArticleView />} />

      <Route path='/' element={<DetailLayout variant={ELayoutVariants.USER} />}>
        <Route path='user/:id' element={<UserDetails />} />
        <Route path='user/:id/cash-back' element={<UserCashBack />} />
        <Route path='user/:id/withdrawals' element={<UserWithdrawals />} />
        <Route path='user/:id/clicks' element={<UserClicks />} />
      </Route>

      <Route path='/' element={<DetailLayout variant={ELayoutVariants.STORE} />}>
        <Route path='stores/new' element={<StorePage />} />
        <Route path='stores/:id' element={<StorePage />} />
        <Route path='stores/:id/cash-back' element={<StoreCashBack />} />
        <Route path='stores/:id/clicks' element={<StoreClicks />} />
      </Route>

      <Route path='/' element={<DetailLayout variant={ELayoutVariants.TOP_UP_PIN} />}>
        <Route path='top-up-pin/new' element={<CarrierPage />} />
        <Route path='top-up-pin/:id' element={<CarrierPage />} />
        <Route path='top-up-pin/:id/cash-back' element={<CarrierCashBack />} />
        <Route path='top-up-pin/:id/clicks' element={<CarrierClicks />} />
      </Route>
      <Route path='*' element={<Navigate to='/' replace />} />
    </Routes>
  ) : (
    <Routes>
      <Route path='/' element={<SignInPage />} />
      <Route path='*' element={<Navigate to='/' replace />} />
    </Routes>
  )
}

export default App
