import { baseQueryWithReauth } from '../utils/baseQueryWithReauth'
import { createApi } from '@reduxjs/toolkit/query/react'
import { IFilterCarriers } from '../models/IFilter'

export const FILTER_API_REDUCER_KEY = 'filterApi'
export const filterApi = createApi({
  reducerPath: FILTER_API_REDUCER_KEY,
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Filter'],
  endpoints: (builder) => ({
    getFilterCarriers: builder.query<IFilterCarriers[], string | undefined>({
      query: () => ({
        url: '/carriers',
      }),
      providesTags: () => ['Filter'],
    }),
  }),
})
