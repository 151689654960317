import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ColumnsType } from 'antd/lib/table'
import { ICarrier } from '../../models/ICarrier'
import { Status } from './TopUpPinPage'
import SVG from '../../assets/images/SVG'
import { carriersApi } from '../../services'
import { ShowMessage } from '../../utils'
import BucketActionCarrier from '../../components/BucketActionCarrier'

interface IProps {
  onChangeCurrentPage: () => void
}

const useColumn = ({ onChangeCurrentPage }: IProps) => {
  const [updateCarrier, { isSuccess }] = carriersApi.useUpdateCarrierMutation()

  useEffect(() => {
    if (!isSuccess) return
    ShowMessage('success', 'Carrier deleted from favorite position')
  }, [isSuccess])

  const deleteFavoritePositionCarrier = (id: number) => {
    const fData = new FormData()
    fData.append('carrier', JSON.stringify({ favoritesPosition: null }))
    updateCarrier({ id, fData })
  }

  const columns: ColumnsType<ICarrier> = [
    {
      title: 'Carrier',
      dataIndex: 'carrierTitle',
      defaultSortOrder: 'descend',
      width: '30%',
      sorter: {},
      fixed: 'left',
      sortDirections: ['descend', 'ascend'],
      render: (name: string, { id, carrierTitle, favoritesPosition }: ICarrier) => (
        <>
          <Link to={`/top-up-pin/${id}`}>{carrierTitle}</Link>
          {favoritesPosition && (
            <div className='store-wrapper-star' onClick={() => deleteFavoritePositionCarrier(id)}>
              <SVG.StarIcon />
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Paid cash-back',
      dataIndex: 'paidCashback',
      defaultSortOrder: 'descend',
      width: 200,
      sorter: {},
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Status',
      dataIndex: 'isPublished',
      defaultSortOrder: 'descend',
      width: 'auto',
      sorter: {},
      fixed: 'right',
      sortDirections: ['descend', 'ascend'],
      render: (status: ICarrier) => (
        <>
          {status}
          <div>{status ? Status.published : Status.unpublished}</div>
        </>
      ),
    },
    {
      title: 'Carrier id',
      dataIndex: 'id',
      defaultSortOrder: 'descend',
      width: 'auto',
      sorter: {},
      sortDirections: ['descend', 'ascend'],
      fixed: 'right',
      render: (status, { id }: ICarrier) => (
        <React.Fragment>
          <div>{+id}</div>
          <BucketActionCarrier id={id} onChangeCurrentPage={onChangeCurrentPage} />
        </React.Fragment>
      ),
    },
  ]
  return { columns }
}
export default useColumn
