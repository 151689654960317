import Fashion from './Emoji/Fashion.svg'
import Auto from './Emoji/Auto.svg'
import BeautyAndHealth from './Emoji/Beauty & Health.svg'
import BabyKidsToys from './Emoji/Baby, Kids & Toys.svg'
import Books from './Emoji/Books.svg'
import Electronics from './Emoji/Electronics.svg'
import Entertainment from './Emoji/Entetainment.svg'
import eSims from './Emoji/eSim.svg'

import FoodAndGrocery from './Emoji/Food & Grocery.svg'
import HardwareAndTools from './Emoji/Hardware & Tools.svg'
import HomeAndGarden from './Emoji/Home & Garden.svg'
import PetSupplies from './Emoji/Pet Supplies.svg'
import SportsAndOutdoor from './Emoji/Sports & Outdoor.svg'

const Emoji = {
  Fashion,
  Auto,
  'Baby, Kids & Toys': BabyKidsToys,
  'Beauty & Health': BeautyAndHealth,
  Books,
  Electronics,
  Entertainment,
  eSims,
  'Food & Grocery': FoodAndGrocery,
  'Hardware & Tools': HardwareAndTools,
  'Home & Garden': HomeAndGarden,
  'Pet Supplies': PetSupplies,
  'Sports & Outdoor': SportsAndOutdoor,
}

export default Emoji
