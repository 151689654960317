import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Table, Button } from 'antd'
import FilterBlock from '../../components/FilterBlock'
import { IStore } from '../../models/IStore'
import { storesApi } from '../../services'

import '../styles.scss'
import './styles.scss'
import { MODALS, TableOrder, TableOrderType } from '../../utils/constants'
import SVG from '../../assets/images/SVG'
import { useTableSearchSortPagination } from '../../hooks/useTableSearchSortPagination'
import { ModalContext } from '../../components/Modal/modalProvider'
import useEmitterFilterSubmit from '../../hooks/useEmitterFilterSubmit'
import useEmitterClearForFilter from '../../hooks/useEmitterClearForFilter'
import useColumn from './columns'
import { Carousel } from './Carousel/Carousel'

export enum Status {
  published = 'Published',
  unpublished = 'Unpublished',
}

export interface TableConfig {
  page?: number | undefined
  limit?: number
  order?: TableOrderType
  search?: string
  isClear?: boolean
}

const StoresPage = () => {
  const [tableConfig, setTableConfig] = useState<TableConfig>({
    page: 1,
    limit: 10,
    order: TableOrder.descend,
    search: '',
  })

  const { handleSearchChange, onChangeTable } = useTableSearchSortPagination<IStore>({
    setTableConfig,
  })
  const { data, isLoading } = storesApi.useFetchAllStoresQuery(tableConfig)

  useEmitterFilterSubmit(setTableConfig)
  useEmitterClearForFilter(setTableConfig)

  const navigate = useNavigate()

  const handlerClick: React.MouseEventHandler<HTMLElement> = () => {
    navigate('/stores/new')
  }

  const { onOpen } = useContext(ModalContext)
  const onOpenModal = () => onOpen(MODALS.storeFilter)

  const onShowSizeChange = (current: number, size: number) => {
    setTableConfig((pre) => ({ ...pre, page: current, limit: size }))
  }

  const onChangeCurrentPage = () => {
    if (!data) return
    const amountPages = Math.floor(data?.count / tableConfig.limit!)
    const onlyOneElemetnOnPage = amountPages * tableConfig.limit! + 1
    const isRedirectToOtherPage = onlyOneElemetnOnPage == data?.count

    isRedirectToOtherPage &&
      setTableConfig((pre) => ({ ...pre, page: pre.page == 1 ? pre.page : pre.page! - 1 }))
  }

  const { columns } = useColumn({ onChangeCurrentPage })

  return (
    <main className='stores-p stores'>
      <Carousel />
      <FilterBlock
        onChange={handleSearchChange}
        onOpenModal={onOpenModal}
        placeholder='Search stores'
      >
        <Button onClick={handlerClick} icon={<SVG.AddIcon />}>
          Add new Store
        </Button>
      </FilterBlock>

      <Table
        className='stores-table'
        loading={isLoading}
        columns={columns}
        dataSource={data?.items.map((el) => ({ ...el, key: el.id }))}
        onChange={onChangeTable}
        pagination={{
          position: ['bottomCenter'],
          total: data?.count,
          current: tableConfig.page,
          onShowSizeChange,
          pageSize: tableConfig.limit,
        }}
      />
    </main>
  )
}

export default StoresPage
