/* eslint-disable */
import React, { useState } from 'react'

interface ImodalProps {
  children: React.ReactNode;
}

interface ImodalContext {
  selectedModal:string|null,
  props:{filters?:any}
  onOpen:(modal: string, newModalProps?: any)=>any,
  onClose:()=>any
}

export const ModalContext  = React.createContext<ImodalContext>({
  selectedModal: null,
  props: {
    filters: {},
  },
  onOpen: ()=>{},
  onClose: ()=>{},
})

const ModalContextProvider: React.FC<ImodalProps> = ({ children }) => {
  const [selectedModal, setModal] = useState<null|string>(null)
  const [props, setProps] = useState({})

  const onOpen = (modal: string, newModalProps: any) => {
    setModal(modal)
    if (newModalProps !== undefined) {
      setProps({ ...props, ...newModalProps })
    }
  }

  const onClose = () => {
    setModal(null)
    setProps({})
  }

  const contextValue: ImodalContext = {
    selectedModal,
    props,
    onOpen,
    onClose,
  }

  return (
    <ModalContext.Provider value={contextValue }>
      {children}
    </ModalContext.Provider>
  )
}

export default ModalContextProvider
