import React from 'react'
import { IMenu } from '../Header/index'
import './styles.scss'
import { useLocation } from 'react-router-dom'

export const Menu = ({ onClick, items, renderItem }: IMenu) => {
  const location = useLocation()

  const getIsActive = (key: string) => {
    return location.pathname == key ? 'menu-item menu-active-item' : 'menu-item'
  }

  const onClickItem = (key: string) => {
    onClick(key)
  }

  return (
    <div className="menu">
      {items.map((item) => renderItem(item, getIsActive, onClickItem))}
    </div>
  )
}
