interface ICardInfoProps {
  className?: string;
  email?:string|number
  phoneNumber?:string
}

const StoreCashBackInfo: React.FC<ICardInfoProps> = ({
  className = '',
  email = '',
  phoneNumber = '',
}) => {
  return (
    <div className={`store-cash-back-info ${className}`}>
      <div className="wrapper-email">
      <div className="cash-back-content">{email}</div>
      <div className="cash-back-footer">Email</div>
      </div>
      
      <div className="wrapper-phone-number">
      <div className="cash-back-content">{phoneNumber}</div>
      <div className="cash-back-footer">Phone number</div>
        </div>      
    </div>
  )
}
export default StoreCashBackInfo