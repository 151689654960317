import SignInForm from './SignInForm'
import SignInHeader from './SignInHeader'

const SignInPage = () => {
  return (
    <main>
      <SignInHeader />
      <SignInForm />
    </main>
  )
}

export default SignInPage
