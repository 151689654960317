import SVG from '../../../assets/images/SVG'
import './styles.scss'

interface IChipProps{
    logo:React.ReactNode
    content?:string
    classname?:string     
    onDelete?: null | (() => void  )
}
const Chip:React.FC<IChipProps> = ({ logo, content, classname = '', onDelete = null }) => {
  return (
    <div className={'chip' + ' ' + classname}>
      <div className="chip-logo">{logo}</div>
      <div className="chip-content">{content}</div>
      {onDelete && (
        <div className="chip-delete-icon" onClick={onDelete}>
          <SVG.CloseIcon fill={'#33CC55'} />
        </div>
      )}
    </div>
  )
}

export default Chip
