import FilterBlock from '../../components/FilterBlock'
import { useParams } from 'react-router'
import { Table } from 'antd'
import { userApi } from '../../services'
import { IUserWithdrawals, IUserWithdrawalsRedux } from '../../models/IUsers'
import { ColumnsType } from 'antd/lib/table'
import { MODALS, TableOrder } from '../../utils/constants'
import React, { useContext, useState } from 'react'
import { TableConfig } from '../Stores/StoresPage'
import { useTableSearchSortPagination } from '../../hooks/useTableSearchSortPagination'
import moment from 'moment'
import useEmitterFilterSubmit from '../../hooks/useEmitterFilterSubmit'
import { ModalContext } from '../../components/Modal/modalProvider'
import useEmitterClearForFilter from '../../hooks/useEmitterClearForFilter'
import './styles.scss'

const UserWithdrawals: React.FC = () => {
  const [tableConfig, setTableConfig] = useState<TableConfig>({
    page: 1,
    limit: 10,
    order: TableOrder.descend,       
  })  

  const { id } = useParams()

  const {handleSearchChange,onChangeTable}=useTableSearchSortPagination<IUserWithdrawals>({setTableConfig,nameSearchQuery:'searchWithdrawal'})

  const { data, isLoading } = userApi.useGetUserWithdrawalsQuery({id,tableConfig})  
  
  const columns: ColumnsType<IUserWithdrawals> = [
    {
      title: 'Transaction ID',
      dataIndex: 'id',
      defaultSortOrder: 'descend',  
      sortDirections: ['descend', 'ascend'],
      fixed:'left'    
    },
    {
      title: 'Last withdraw',
      dataIndex: 'amount',
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend'],
      sorter: {},
    },
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend'],
      sorter: {},
      render: (text) => <>{ moment(text).format('YYYY-MM-DD h:mm')}</>
    },
    {
      title: ' Total withdrawals',
      dataIndex: 'totalAmount',
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend'],
      sorter: {},
      fixed:'right'    
    },
  ]

  useEmitterFilterSubmit(setTableConfig)
  useEmitterClearForFilter(setTableConfig)
    
  const { onOpen } = useContext(ModalContext)
  const onOpenModal = () =>onOpen(MODALS.userFilterWithdrawals)

  const onShowSizeChange=(current:number, size:number)=>{    
    setTableConfig((pre)=>({...pre,
    page:current,
    limit:size
    }))
  }

  return (
    <main className="user-withdrawals">
    <FilterBlock onChange={handleSearchChange} onOpenModal={onOpenModal} placeholder="Search wallets"/>
      <Table
      className='withdrawals-table'
        loading={isLoading}        
        columns={columns}        
        dataSource={(data as IUserWithdrawalsRedux)?.items}      
        onChange={onChangeTable}        
        pagination={{ 
          position: ['bottomCenter'],
          total: data?.count,
          current:tableConfig.page,
          onShowSizeChange,
       }}
      />
    </main>
  )
}
export default UserWithdrawals
