import React from 'react'
import './styles.scss'
interface IProps{
    body:string
    footer:string
}

export const CountCard:React.FC<IProps> = ({ body='', footer='' }) => {
  return (
    <div className="count-card">
      <div className="count-card-body">{body}</div>
      <div className="count-card-footer">{footer}</div>
    </div>
  )
}
