import { Button } from 'antd'
import moment from 'moment'
import React, {useEffect, useMemo} from 'react'
import { useNavigate } from 'react-router-dom'
import SVG from '../../../assets/images/SVG'
import { NOTIFICATION_TYPES, useNotification } from '../../../hooks/useNotification'
import {IArticle, IBlogTranslations} from '../../../models/IBlog'
import { blogApi } from '../../../services'
import { ErrorNode } from '../../../utils/ShowMessage'
import './styles.scss'

interface IProps {
  article: IArticle
}

export const ArticleCard: React.FC<IProps> = ({ article }) => {
  const createAt = moment(article.createdAt).format('MMM d, YYYY')

  const localeArticle = useMemo(() => {
    return article?.translations?.find(({ language }) => language === 'en');
  }, [article]) as IBlogTranslations

  const readTime = `${article.readTime} min read`
  const navigate = useNavigate()

  const [deleteArticle, deleteArticleRes] = blogApi.useDeleteArticleByIdMutation()
  const deleteArticleById = (id: number) => () => deleteArticle(id)

  useEffect(() => {
    if (!deleteArticleRes.isSuccess) return
    navigate('/blog')
  }, [deleteArticleRes.isSuccess])

  useNotification(NOTIFICATION_TYPES.success, deleteArticleRes.isSuccess)
  useNotification(
    NOTIFICATION_TYPES.error,
    deleteArticleRes.isError,
    deleteArticleRes.error as ErrorNode
  )

  const editArticleById = () => navigate(`article/${article.id}/edit`)
  const viewArticleById = () => navigate(`article/${article.id}`)

  return (
    <div className='article-card'>
      <div className='logo'>
        <img src={article.logo} alt='article-logo' />
      </div>
      <div className='content'>
        <div>
          <div className='created'>{createAt}</div>
          <div className='title'>{localeArticle?.title}</div>
          <div className='description'>{localeArticle?.description}</div>
        </div>

        <div className='readTime'>
          <div className='wrapper-time-icon'>
            <SVG.TimeIcon />
          </div>
          {readTime}
        </div>
      </div>

      <div className='actions'>
        <div className='wrapper-edit-icon'>
          <Button className='article-btn' icon={<SVG.EditIcon />} onClick={editArticleById}>
            Edit
          </Button>
        </div>
        <div className='wrapper-view-icon'>
          <Button className='article-btn' icon={<SVG.ViewIcon />} onClick={viewArticleById}>
            View
          </Button>
        </div>
        <div className='wrapper-delete-icon'>
          <Button
            className='article-btn'
            icon={<SVG.DeleteIcon />}
            onClick={deleteArticleById(article.id)}
          >
            Delete
          </Button>
        </div>
      </div>
    </div>
  )
}
