import { createApi } from '@reduxjs/toolkit/query/react'
import { TableConfig } from '../models/ITableConfig'
import * as I from '../models/IUsers'
import { baseQueryWithReauth } from '../utils/baseQueryWithReauth'
import { IPageConf } from '../utils/constants'

export const USERS_API_REDUCER_KEY = 'usersApi'
export const usersApi = createApi({
  reducerPath: USERS_API_REDUCER_KEY,
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Users'],
  endpoints: (builder) => ({
    fetchAllUsers: builder.query<I.IUsersRedux, TableConfig>({      
      query: (tableConfig) => ({
        url: '/admin/users',
        params: {
          ...tableConfig
        },
      }),
      providesTags: () => ['Users'],
    }),
  }),
})


interface IConf{
  id:string | undefined
  pageConf:IPageConf
}

export const USER_API_REDUCER_KEY = 'userApi'
export const userApi = createApi({
  reducerPath: USER_API_REDUCER_KEY,
  baseQuery: baseQueryWithReauth,
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getUserDetails: builder.query<I.IUser, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/admin/users/${id}`,
      }),
      providesTags: () => ['User'],
    }),
    getUserCashBack: builder.query<I.IUserCashbackRedux,IConf >({    
      query: ({id, pageConf})=> (              
        { url: `/admin/users/${id}/cashback`,
        params: {
          ...pageConf,
        }}               
    ),
      providesTags: () => ['User'],
    }),
    getUserWithdrawals: builder.query<
      I.IUserWithdrawalsRedux,
      {tableConfig:TableConfig ,id:string | undefined}
    >({      
      query: ({id,tableConfig}) => {        
        return{
        url: `/admin/users/${id}/withdrawals`,
        params: {
          ...tableConfig,
        },
      }},
      providesTags: () => ['User'],
    }),
    getUserClicks: builder.query<I.IUserClicksRedux,{tableConfig:TableConfig, id: string | undefined}>({      
      query: ({id,tableConfig}) => ({
        url: `/admin/users/${id}/clicks`,
        params: {
          ...tableConfig,
        },
      }),
      providesTags: () => ['User'],
    }),
    deleteUser: builder.mutation<I.IDeleteUserClicksRedux, number>({
      query: (id) => ({
        url: `/admin/users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['User'],
    }),
  }),
})
