import { notification } from 'antd'

/**
 *
 * @param type (success || info || warning || error)
 * @param message
 * @param description
 * @constructor
 */

export type ErrorNode = {
  data: {
    message: string
    statusCode: number
  }
  status: number
}

type NotificationType = 'success' | 'info' | 'warning' | 'error'

const ShowMessage = (type: NotificationType = 'success', message = '', description = '') => {
  notification.open({
    type,
    message: message || 'undef message',
    description,
  })
}

export const handleErrorPassword = (err: ErrorNode) => {
  const isInvalidPassword = err.data.message.includes('reset your password')

  isInvalidPassword
    ? ShowMessage(
        'error',
        `We're not able to validate the email and password provided. Please try again.`
      )
    : ShowMessage('error', err.data.message)
}

export const handleError = (err: ErrorNode) => ShowMessage('error', err.data.message)
export const handleErrorText = (err: string) => ShowMessage('error', err)
export const handleInfo = (info: string) => ShowMessage('info', info)
export const handleSuccess = (success: string) => ShowMessage('success', success)

export default ShowMessage
