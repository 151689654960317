import { Form, FormInstance, Input, DatePicker } from 'antd'
import React, { createRef } from 'react'
import SVG from '../../../../assets/images/SVG'

import './styles.scss'

interface IProps {
  labelFirstInput: string;
  nameFirstINput: string;
  typeFirstInput: string;
}

const FilterCashback: React.FC<IProps> = ({
  labelFirstInput = '',
  nameFirstINput,
  typeFirstInput = 'text',
}) => {
  const form = Form.useFormInstance()
  const formRef = createRef<FormInstance>()

  return (
    <div className="filter-container">
      <Form
        form={form}
        ref={formRef}
        autoComplete="off"
        className="store-f"
        initialValues={{
          [nameFirstINput]: '',
          minRewardRatio: '',
          maxRewardRatio: '',
          minSaleAmount: '',
          maxSaleAmount: '',
          minTransactionDate: '',
          maxTransactionDate: '',
          minWithdrawalDate: '',
          maxWithdrawalDate: '',
        }}
      >
        <div className="form-row filter-row">
          <Form.Item
            name={nameFirstINput}
            label={labelFirstInput}
            className="item-half"
          >
            <Input type={typeFirstInput} className="form-filter-input" />
          </Form.Item>
        </div>

        <div className="form-row filter-row">
          <Form.Item
            name="minRewardRatio"
            label="Reward ratio"
            className="item-half"
          >
            <Input
              type="number"
              placeholder="No min"
              className="form-filter-input"
            />
          </Form.Item>
          <Form.Item name="maxRewardRatio" className="item-half">
            <Input
              type="number"
              placeholder="No max"
              className="form-filter-input"
            />
          </Form.Item>
        </div>

        <div className="form-row filter-row">
          <Form.Item name="minSaleAmount" label="Sale" className="item-half">
            <Input
              type="number"
              placeholder="No min"
              className="form-filter-input"
            />
          </Form.Item>
          <Form.Item name="maxSaleAmount" className="item-half">
            <Input
              type="number"
              placeholder="No max"
              className="form-filter-input"
            />
          </Form.Item>
        </div>

        <div className="form-row filter-row">
          <Form.Item
            name="minTransactionDate"
            label="Transaction date & time"
            className="item-half"
          >
            <DatePicker
              placeholder="From"
              suffixIcon={<SVG.CalendarIcon />}
              className="form-filter-datapicker"
              format="YYYY-MM-DD"
            />
          </Form.Item>
          <Form.Item name="maxTransactionDate" className="item-half">
            <DatePicker
              placeholder="To"
              suffixIcon={<SVG.CalendarIcon />}
              className="form-filter-datapicker"
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </div>

        <div className="form-row filter-row">
          <Form.Item
            name="minWithdrawalDate"
            label="Cash-back date & time"
            className="item-half"
          >
            <DatePicker
              placeholder="From"
              suffixIcon={<SVG.CalendarIcon />}
              className="form-filter-datapicker"
              format="YYYY-MM-DD"
            />
          </Form.Item>
          <Form.Item name="maxWithdrawalDate" className="item-half">
            <DatePicker
              placeholder="To"
              suffixIcon={<SVG.CalendarIcon />}
              className="form-filter-datapicker"
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default FilterCashback
