import { createApi } from '@reduxjs/toolkit/query/react'
import { ICategories } from '../models/ICategories'
import { ISpecialOffers } from '../models/ISpecialOffers'
import {
  IAttributes,
  IBoolmarks,
  IStore,
  IStoreById,
  IStoreCashbackRedux,
  IStoreClicksRedux,
  IStoreRedux,
} from '../models/IStore'
import { IPageConf } from '../utils/constants'
import { TableConfig } from '../pages/Stores/StoresPage'
import { baseQueryWithReauth } from '../utils/baseQueryWithReauth'
import { IAffiliateNetwork } from '../models/AffiliateNetworks'
import { IBannersFreePositionsList, IBannersRedux, IUpdateBanner } from '../models/IBanners'

export const STORES_API_REDUCER_KEY = 'storesApi'
export const storesApi = createApi({
  reducerPath: STORES_API_REDUCER_KEY,
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Stores', 'Store', 'Banners'],
  endpoints: (builder) => ({
    fetchAllStores: builder.query<IStoreRedux, TableConfig>({
      query: (config) => ({
        url: '/admin/stores',
        params: {
          ...config,
        },
      }),
      providesTags: () => ['Stores'],
    }),
    fetchStore: builder.query<IStoreById, string | undefined>({
      query: (id) => ({
        url: `/admin/stores/${id}`,
      }),
      transformResponse: (response: IStoreById): IStoreById => {
        const tr = response.translations.reduce((acc, res) => {
          const { code } = res.language
          return {
            ...acc,
            [code]: res,
          }
        }, {})
        return { ...response, tr }
      },
      providesTags: () => ['Store'],
    }),
    createStore: builder.mutation<IStore, FormData>({
      query: (body: FormData) => ({
        url: '/admin/stores',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['Stores'],
    }),
    createSpecialOffer: builder.mutation<ISpecialOffers, { id: number; offer: FormData }>({
      query: ({ id, offer }) => ({
        url: `/admin/stores/${id}/specialOffers`,
        method: 'POST',
        body: offer,
      }),
      invalidatesTags: () => ['Store'],
    }),
    updateSpecialOffer: builder.mutation<
      IStore,
      { id: number; offer: FormData; offerId: number | undefined }
    >({
      query: ({ id, offerId, offer }) => ({
        url: `/admin/stores/${id}/specialOffers/${offerId}`,
        method: 'PATCH',
        body: offer,
      }),
      invalidatesTags: () => ['Stores', 'Store'],
    }),
    deleteSpecialOffer: builder.mutation<IStore, { id: number | undefined; offerId: number }>({
      query: ({ id, offerId }) => ({
        url: `/admin/stores/${id}/specialOffers/${offerId}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Stores', 'Store'],
    }),
    updateStore: builder.mutation<IStore, { id: number | string | undefined; fData: FormData }>({
      query: ({ id, fData }) => {
        return {
          url: `/admin/stores/${id}`,
          method: 'PATCH',
          body: fData,
        }
      },
      invalidatesTags: () => ['Stores', 'Store'],
    }),
    deleteStore: builder.mutation<IStore, number>({
      query: (id: number) => ({
        url: `/admin/stores/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Stores'],
    }),

    createBanner: builder.mutation<IStore, FormData>({
      query: (body: FormData) => ({
        url: '/admin/banner-images',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['Banners'],
    }),

    getBannerList: builder.query<IBannersRedux, { languageCode: string; type: string }>({
      query: ({ languageCode = 'en', type = 'desktop' }) => ({
        url: `/banner-images?languageCode=${languageCode}&type=${type}`,
      }),
      providesTags: () => ['Banners'],
    }),

    deleteBanner: builder.mutation<undefined, number>({
      query: (id) => ({
        url: `/admin/banner-images/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Banners'],
    }),

    getBannersFreePositionsList: builder.query<
      IBannersFreePositionsList,
      { languageCode: string; type: string }
    >({
      query: ({ languageCode = 'en', type = 'desktop' }) => ({
        url: `/admin/banner-images/positions?languageCode=${languageCode}&type=${type}`,
      }),
      providesTags: () => ['Banners'],
    }),

    updateBanner: builder.mutation<IUpdateBanner, { id: number; body: IUpdateBanner }>({
      query: ({ id, body }) => ({
        url: `/admin/banner-images/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => ['Banners'],
    }),
  }),
})

export const STORE_API_REDUCER_KEY = 'storeApi'
export const storeApi = createApi({
  reducerPath: STORE_API_REDUCER_KEY,
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Store'],
  endpoints: (builder) => ({
    getStoreCashBack: builder.query<
      IStoreCashbackRedux,
      {
        id: string | undefined
        pageConf: IPageConf
      }
    >({
      query: ({ id, pageConf }) => ({
        url: `/admin/stores/${id}/cashback`,
        params: {
          ...pageConf,
        },
      }),
      providesTags: () => ['Store'],
    }),
    getStoreClicks: builder.query<
      IStoreClicksRedux,
      { tableConfig: TableConfig; id: string | undefined }
    >({
      query: ({ id, tableConfig }) => ({
        url: `/admin/stores/${id}/clicks`,
        params: {
          ...tableConfig,
        },
      }),
      providesTags: () => ['Store'],
    }),
    getCategories: builder.query<ICategories[], string | undefined>({
      query: () => ({
        url: '/categories',
      }),
    }),
    getAffiliateNetworks: builder.query<IAffiliateNetwork[], string | undefined>({
      query: () => ({
        url: '/admin/affiliateNetworks',
      }),
    }),
    getBookmarks: builder.query<IBoolmarks, string | undefined>({
      query: () => ({
        url: '/admin/stores/bookmarks',
      }),
    }),
    getAttributes: builder.query<IAttributes[], string | undefined>({
      query: () => ({
        url: '/attributes',
      }),
    }),
  }),
})
