import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query'
import { resetStateAction } from '../redux/actions/resetState'
import { authSlice } from '../redux/reducers'
import { IRefreshTokenResponse } from '../models'
import { RootState } from '../redux'
import { apiHost } from './configEnv'
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'

// interface

enum Headers {
  Authorization = 'authorization',
  Accept = 'Accept',
}

enum Tokens {
  access_token = 'access_token',
  refresh_token = 'refresh_token',
}

const selectToken = (state: RootState, token: Tokens): string | null => state.auth[token]

const baseQuery = fetchBaseQuery({
  baseUrl: apiHost,
  prepareHeaders: (headers, { getState }) => {
    const token = selectToken(getState() as RootState, Tokens.access_token)
    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set(Headers.Authorization, `Bearer ${token}`)
    }
    headers.set(Headers.Accept, 'application/json')
    return headers
  },
  mode: 'cors',
})
export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const { getState } = api
  const token = selectToken(getState() as RootState, Tokens.refresh_token)

  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    // try to get a new token
    const refreshResult = (await baseQuery(
      {
        url: '/auth/refresh',
        method: 'PATCH',
        body: {
          refreshToken: token,
        },
      },
      api,
      extraOptions
    )) as QueryReturnValue<IRefreshTokenResponse, FetchBaseQueryError, FetchBaseQueryMeta>
    if (refreshResult.data) {
      // store the new token
      api.dispatch(authSlice.actions.updateTokens(refreshResult.data.tokens))
      // retry the initial query
      result = await baseQuery(args, api, extraOptions)
    } else {
      api.dispatch(resetStateAction())
    }
  }
  return result
}
