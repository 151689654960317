import { Select, Form, FormInstance } from 'antd'
import React, { createRef } from 'react'
import { filterApi } from '../../../../services'

import './styles.scss'

const { Option } = Select

const UsersFilter: React.FC = () => {
  const form = Form.useFormInstance()
  const formRef = createRef<FormInstance>()
  const { data: carrierList, isSuccess } =
    filterApi.useGetFilterCarriersQuery('')

  return (
    <div className="filter-container">
      <Form
        form={form}
        ref={formRef}
        // onFinish={onFinish}
        autoComplete="off"
        className="store-f"
        initialValues={{
          phonePlan: '',
          carrierId: '',
        }}
      >
        <div className="form-row">
          <Form.Item
            name="carrierId"
            label="Carrier"
            className="input-big item-half"
          >
            <Select>
              {isSuccess
                ? carrierList?.map(({ title, id }) => (
                    <Option key={id} value={id}>
                      {title}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </div>
        <div className="form-row">
          <Form.Item
            name="phonePlan"
            label="Phone plan"
            className="input-big item-half"
          >
            <Select>
              <Option value="prepaid">prepaid</Option>
              <Option value="monthly">monthly</Option>
            </Select>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default UsersFilter
