import { ColumnsType } from 'antd/lib/table'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { IWithdrawal } from '../../models/IWithdrawals'

const columns: ColumnsType<IWithdrawal> = [
  {
    title: 'Transaction ID',
    dataIndex: 'id',
    defaultSortOrder: 'descend',
    sortDirections: ['descend', 'ascend'],
    sorter: {},
    fixed: 'left',
  },
  {
    title: 'User',
    dataIndex: 'phoneNumber',
    defaultSortOrder: 'descend',
    sortDirections: ['descend', 'ascend'],
    sorter: {},
    render: (text: string, { userId }) => (
      <div className="wrapper-link">
        <Link
          className="table-green-color link-unset-style"
          to={`/user/${userId}`}
        >
          {text}
        </Link>
      </div>
    ),
  },
  {
    title: 'Last withdraw',
    dataIndex: 'amount',
    defaultSortOrder: 'descend',
    sortDirections: ['descend', 'ascend'],
    sorter: {},
    render: (amount: string) => <>$ {amount}</>,
  },
  {
    title: 'Date & Time',
    dataIndex: 'createdAt',
    defaultSortOrder: 'descend',
    sortDirections: ['descend', 'ascend'],
    sorter: {},
    render: (text) => <>{moment(text).format('YYYY-MM-DD h:mm')}</>,
  },

  {
    title: 'Total withdrawals',
    dataIndex: 'totalAmount',
    defaultSortOrder: 'descend',
    sortDirections: ['descend', 'ascend'],
    sorter: {},
    render: (totalAmount: string) => <>$ {totalAmount}</>,
    fixed: 'right',
  },
]
export default columns
