import { debounce } from 'lodash'
import { useCallback } from 'react'
import { TableConfig } from '../models/ITableConfig'
import { IPageConf, SetState } from '../utils/constants'

type Conf = TableConfig | IPageConf

const useSearch = <T extends Conf>(
  setTableConfig: SetState<T>,
  nameSearchQuery = 'searchStore'
) => {
  const debounceSearch = useCallback(
    debounce((value) => {
      setTableConfig((prev) => ({
        ...prev,
        page: 1,
        [nameSearchQuery]: value,
      }))
    }, 300),
    []
  )

  const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event?.target?.value?.trim().length) {
      debounceSearch(event?.target?.value)
    } else {
      debounceSearch('')
    }
  }

  return { handleSearchChange }
}

export default useSearch
