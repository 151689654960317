import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Table } from 'antd'
import FilterBlock from '../../components/FilterBlock'
import { ICarrier } from '../../models/ICarrier'
import { carriersApi } from '../../services'
import '../styles.scss'
import './styles.scss'
import { MODALS, TableOrder, TableOrderType } from '../../utils/constants'
import SVG from '../../assets/images/SVG'
import { useTableSearchSortPagination } from '../../hooks/useTableSearchSortPagination'
import { ModalContext } from '../../components/Modal/modalProvider'
import useEmitterFilterSubmit from '../../hooks/useEmitterFilterSubmit'
import useEmitterClearForFilter from '../../hooks/useEmitterClearForFilter'
import { Carousel } from '../Stores/Carousel/Carousel'
import useColumn from './columns'

export enum Status {
  published = 'Published',
  unpublished = 'Unpublished',
}

export interface TableConfig {
  page?: number | undefined
  limit?: number
  order?: TableOrderType
  search?: string
  isClear?: boolean
}

const TopUpPinPage = () => {
  const [tableConfig, setTableConfig] = useState<TableConfig>({
    page: 1,
    limit: 10,
    order: TableOrder.descend,
    search: '',
  })

  const { handleSearchChange, onChangeTable } = useTableSearchSortPagination<ICarrier>({
    setTableConfig,
    nameSearchQuery: 'searchCarrier',
  })
  const { data, isLoading } = carriersApi.useFetchAllCarriersQuery(tableConfig)

  useEmitterFilterSubmit(setTableConfig)
  useEmitterClearForFilter(setTableConfig)

  const navigate = useNavigate()

  const handlerClick: React.MouseEventHandler<HTMLElement> = () => {
    navigate('/top-up-pin/new')
  }

  const { onOpen } = useContext(ModalContext)
  const onOpenModal = () => onOpen(MODALS.carrierFilter)

  const onShowSizeChange = (current: number, size: number) => {
    setTableConfig((pre) => ({ ...pre, page: current, limit: size }))
  }

  const onChangeCurrentPage = () => {
    if (!data) return
    const amountPages = Math.floor(data?.count / tableConfig.limit!)
    const onlyOneElementOnPage = amountPages * tableConfig.limit! + 1
    const isRedirectToOtherPage = onlyOneElementOnPage == data?.count

    isRedirectToOtherPage &&
      setTableConfig((pre) => ({ ...pre, page: pre.page == 1 ? pre.page : pre.page! - 1 }))
  }

  const { columns } = useColumn({ onChangeCurrentPage })

  return (
    <main className='top-up-pin-p top-up-pin'>
      <Carousel />
      <FilterBlock
        onChange={handleSearchChange}
        onOpenModal={onOpenModal}
        placeholder='Search top-up pins'
      >
        <Button onClick={handlerClick} icon={<SVG.AddIcon />}>
          Add new Carrier
        </Button>
      </FilterBlock>
      <Table
        className='top-up-pin-table'
        loading={isLoading}
        columns={columns}
        dataSource={data?.items.map((el) => ({ ...el, key: el.id }))}
        // scroll={{ x: 'scroll' }}
        onChange={onChangeTable}
        pagination={{
          position: ['bottomCenter'],
          total: data?.count,
          current: tableConfig.page,
          onShowSizeChange,
          pageSize: tableConfig.limit,
        }}
      />
    </main>
  )
}

export default TopUpPinPage
