import FilterBlock from '../../components/FilterBlock'
import { useParams } from 'react-router'
import './styles.scss'
import { storeApi } from '../../services'
import { Empty, Pagination, Spin } from 'antd'
import { useContext, useEffect, useState } from 'react'
import useSearch from '../../hooks/useSearch'
import { ModalContext } from '../../components/Modal/modalProvider'
import { IPageConf, MODALS } from '../../utils/constants'
import useEmitterFilterSubmit from '../../hooks/useEmitterFilterSubmit'
import Emitter, { EmitterEvents } from '../../utils/eventEmitter'
import CashBackCard from '../../components/CashBackCard/CashBackCard'
import StoreCashBackInfo from './StoreCashBackInfo'

const StoreCashBack: React.FC = () => {
  const { id } = useParams()

  const [pageConf, setPageConf] = useState<IPageConf>({
    page: 1,
    limit: 10,
    searchOrder: '',
  })
  const { data: сashBackList, isLoading } = storeApi.useGetStoreCashBackQuery({
    id,
    pageConf,
  })
  const { handleSearchChange } = useSearch(setPageConf, 'searchOrder')

  const onPaginationChange = (page: number) => {
    setPageConf((pre) => ({ ...pre, page }))
  }

  useEmitterFilterSubmit(setPageConf)

  useEffect(() => {
    Emitter.on(EmitterEvents.MODAL_FILTER_CLEAR, () => {
      setPageConf((pre) => ({
        page: pre.page,
        limit: pre.limit,
        searchOrder: pre.searchOrder,
      }))
    })
    return () => {
      Emitter.off(EmitterEvents.MODAL_FILTER_CLEAR)
    }
  }, [])

  const { onOpen } = useContext(ModalContext)
  const onOpenModal = () => onOpen(MODALS.storeFilterCashback)

  const onShowSizeChange = (current: number, size: number) => {
    setPageConf((pre) => ({ ...pre, page: current, limit: size }))
  }

  const renderContent = () => (
    <div className="cash-back-list">
      {сashBackList?.items.map((cashBack, idx) => {
        return (
          <CashBackCard
            firstElement={
              <StoreCashBackInfo
                email={cashBack?.email}
                phoneNumber={cashBack?.phoneNumber}
              />
            }
            key={idx}
            cashBack={cashBack}
          />
        )
      })}

      {сashBackList?.count === 0 && (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}

      {сashBackList?.count !== 0 && !isLoading && (
        <div className="wrapper-pagination">
          <Pagination
            current={pageConf.page}
            onChange={onPaginationChange}
            pageSize={pageConf.limit}
            total={сashBackList?.count}
            onShowSizeChange={onShowSizeChange}
          />
        </div>
      )}
    </div>
  )

  return (
    <main className="user-cash-back">
      <FilterBlock
        onChange={handleSearchChange}
        onOpenModal={onOpenModal}
        placeholder="Search transactions"
      />
      {isLoading ? <Spin>{renderContent()}</Spin> : renderContent()}
    </main>
  )
}
export default StoreCashBack
