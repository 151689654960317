import { useEffect } from 'react'
import { TableConfig } from '../models/ITableConfig'
import Emitter, { EmitterEvents } from '../utils/eventEmitter'

type Props = React.Dispatch<React.SetStateAction<TableConfig>>

const useEmitterClearForFilter = (setConfig: Props) => {
  useEffect(() => {
    Emitter.on(EmitterEvents.MODAL_FILTER_CLEAR, () => {
      setConfig((pre) => ({
        page: pre.page,
        limit: pre.limit,
        order: pre.order,
      }))
    })
    return () => {
      Emitter.off(EmitterEvents.MODAL_FILTER_CLEAR)
    }
  }, [])
}
export default useEmitterClearForFilter
