import './styles.scss'
import { Button, Empty, Pagination, Spin } from 'antd'
import { useContext, useState } from 'react'
import TicketCard from './TicketCard'
import { ticketApi } from '../../services'
import { ITicket, TicketsPageConfig } from '../../models/ITickets'
import useEmitterFilterSubmit from '../../hooks/useEmitterFilterSubmit'
import useEmitterClearForFilter from '../../hooks/useEmitterClearForFilter'

import FilterBlock from '../../components/FilterBlock'
import useSearch from '../../hooks/useSearch'
import { MODALS } from '../../utils/constants'
import { ModalContext } from '../../components/Modal/modalProvider'
import SVG from '../../assets/images/SVG'
import getValidateObj from '../../utils/getValidateObj'

const MissingTransctionPage: React.FC = () => {
  const [pageConf, setPageConf] = useState<TicketsPageConfig>({
    page: 1,
    limit: 10,
  })

  useEmitterFilterSubmit(setPageConf)
  useEmitterClearForFilter(setPageConf)
  

  const { data: ticketList, isLoading } =
    ticketApi.useGetTicketListQuery(pageConf)

  const validTicketList = ticketList?.items.map((ticket) =>
    getValidateObj(ticket)
  )
  
  const onPaginationChange = (page: number) => {
    setPageConf((pre) => ({ ...pre, page }))
  }

  const onShowSizeChange = (current: number, size: number) => {
    setPageConf((pre) => ({ ...pre, page: current, limit: size }))
  }

  const renderContent = () => (
    <div className="cash-back-list">
      {(validTicketList as ITicket[] )?.map((ticket, idx) => {
        return <TicketCard key={idx} ticket={ticket} />
      })}

      {ticketList?.count === 0 && (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}

      {ticketList?.count !== 0 && !isLoading && (
        <div className="wrapper-pagination">
          <Pagination
            current={pageConf.page}
            onChange={onPaginationChange}
            pageSize={pageConf.limit}
            total={ticketList?.count}
            onShowSizeChange={onShowSizeChange}
          />
        </div>
      )}
    </div>
  )


  const { handleSearchChange } = useSearch(setPageConf, 'searchPhoneNumber')
  const { onOpen } = useContext(ModalContext)
  const onOpenModal = () => onOpen(MODALS.missingTransaction)

  return (
    <main className="missing-transction">
      <FilterBlock
        onChange={handleSearchChange}
        onOpenModal={onOpenModal}
        placeholder="Search tickets by phone number"
      >
        <Button disabled icon={<SVG.DownloadIcon className="cash-back-download" disabled />}>
          Download all
        </Button>
      </FilterBlock>
      {isLoading ? <Spin>{renderContent()}</Spin> : renderContent()}
    </main>
  )
}
export default MissingTransctionPage
