import { ColumnsType } from 'antd/lib/table'
import { Link } from 'react-router-dom'
import { ICashback } from '../../models/ICashback'

export const columns: ColumnsType<ICashback> = [
  {
    title: 'Transaction ID',
    dataIndex: 'id',
    defaultSortOrder: 'descend',
    sorter: {},
    fixed: 'left',
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'User',
    dataIndex: 'phoneNumber',
    defaultSortOrder: 'descend',
    sorter: {},
    sortDirections: ['descend', 'ascend'],
    render: (text: string, { userId }) => (
      <div className="wrapper-link">
        <Link
          className="table-green-color link-unset-style"
          to={`/user/${userId}`}
        >
          {text}
        </Link>
      </div>
    ),
  },
  {
    title: 'Store',
    dataIndex: 'storeTitle',
    defaultSortOrder: 'descend',
    sortDirections: ['descend', 'ascend'],
    sorter: {},
    render: (text: string, { storeId }) => (
      <div className="wrapper-link">
        <Link
          className="table-green-color link-unset-style"
          to={`/stores/${storeId}`}
        >
          {text}
        </Link>
      </div>
    ),
  },
  {
    title: 'Sale amount',
    dataIndex: 'saleAmount',
    defaultSortOrder: 'descend',
    sortDirections: ['descend', 'ascend'],
    sorter: {},
    render: (saleAmount: string) => <>$ {saleAmount}</>,
  },
  {
    title: 'Cash-back',
    dataIndex: 'cashback',
    defaultSortOrder: 'descend',
    sortDirections: ['descend', 'ascend'],
    sorter: {},
    render: (cashback: string) => <>$ {cashback}</>,
    fixed: 'right',
  },
]
