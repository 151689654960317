import SVG from '../../../assets/images/SVG'
import { ISpecialOfferListHeaderProps } from '../../../models/ISpecialOffers'
import { Button } from 'antd'

export const SpecialOfferListHeader = ({
  onAddNewOffer,
}: ISpecialOfferListHeaderProps) => {
  return (
    <div className="special-offers-header">
      <div className="special-offers-title">Special Offers</div>
      <div className="special-offers-add-offer">
        <Button onClick={onAddNewOffer} icon={<SVG.AddIcon />}>
          Create special offer
        </Button>
      </div>
    </div>
  )
}
